import { TabGroup, TabList } from '@headlessui/react';
import cn from 'classnames';
import { LIST_DATA } from 'constants/list';
import { useUserList } from 'context/user.list.context';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { MODAL_MODES } from '../constants';

const MobileTabs = ({ setModalMode }) => {
  const { activeListType, handleActivationListType } = useUserList();
  const handleListActivation = (id) => {
    setModalMode(MODAL_MODES.NO_SELECTED);
    handleActivationListType(id);
  };
  return (
    <TabGroup>
      <TabList
        className={`border-b-2 lg:border-b-0 border-gray-150 lg:border-r
      flex-none flex lg:flex-col lg:w-[245px]
      px-5 lg:px-0 lg:pt-6
      overflow-y-hidden`}
      >
        {Object.values(LIST_DATA)
          .reverse()
          .map((list) => ({ id: list.id, label: list.labelMobile }))
          .map(({ id, label }) => {
            const selected = activeListType === id;
            return (
              <Fragment key={`tab-${id}`}>
                <div className="relative w-full h-fit">
                  <button
                    onClick={() => {
                      handleListActivation(id);
                    }}
                    className={cn(
                      'pl-1 lg:pl-6 lg:pr-16 py-4 lg:py-3 text-center hover:text-green w-[calc(100%+1px)]',
                      {
                        ['text-green border-b-2 pr-1 lg:border-b-0 lg:border-r-2 border-green lg:bg-green-100 lg:mb-0']:
                          selected,
                        ['text-black']: !selected,
                      },
                    )}
                  >
                    {label}
                  </button>
                </div>
              </Fragment>
            );
          })}
      </TabList>
    </TabGroup>
  );
};

MobileTabs.propTypes = {
  tabs: PropTypes.any,
  setModalMode: PropTypes.func,
};

export default MobileTabs;
