import Header from 'components/Header';
import LoadingSpin from 'components/LoadingSpin';
import { useAuth } from 'context/auth.context';
import { useFrontAuthentication } from 'context/front.authentication.context';
import React from 'react';
import { useDynamicTitle } from '../hooks/useDynamicTitle';
import AuthenticatedApp from './authenticated-app';
import FrontUnauthenticatedApp from './front-unauthenticated-app';
import UnauthenticatedApp from './unauthenticated-app';

import('ag-grid-enterprise').then(({ LicenseManager }) => {
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE);
});

function App() {
  const { user, isLoading } = useAuth();
  const { isAuthenticated } = useFrontAuthentication();
  useDynamicTitle();

  if (isLoading) {
    return <LoadingSpin />;
  }

  const Fallback = () => {
    return (
      <>
        {user && <Header />}
        <LoadingSpin />
      </>
    );
  };

  return (
    <React.Suspense fallback={<Fallback />}>
      {window.location.hostname !== 'go.wecheck.ru' && !isAuthenticated ? (
        <FrontUnauthenticatedApp />
      ) : (
        <>{!user ? <UnauthenticatedApp /> : <AuthenticatedApp />}</>
      )}
    </React.Suspense>
  );
}

export default App;
