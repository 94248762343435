import { useNavigate } from 'react-router';

import AddSmthButton from 'components/AddSmthButton';
import DropdownWithCheckboxes from 'components/DropdownWithCheckboxes';
import { PAGE_API_KEYS } from 'constants';
import { F_INNER } from 'constants/filter';
import { FETCH_INNER } from 'constants/inner';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useFetchInnerData } from 'hooks/useFetchInnerData';
import { useEffect } from 'react';
// import { useEffect } from 'react';

const API_KEY_SELECTED_ALL = 'API_KEY_SELECTED_ALL';

const Footer = () => {
  const navigate = useNavigate();

  const handleClick = () => navigate(PAGE_API_KEYS);

  return <AddSmthButton label="Добавить ключ" onClick={handleClick} />;
};

export const ApiKeySelect = () => {
  const { data: apiKeys = [] } = useFetchInnerData({
    type: FETCH_INNER.API_KEYS_WITH_TYPE,
    filter: { apiKeysType: 1 },
  });
  const apiKeysCount = apiKeys.length || 0;

  const { filter, toggleApiKey, setApiKeys } = useFilterReducer();
  const apiKeysChecked = filter?.[F_INNER.API_KEYS];
  const apiKeysCheckedCount = filter?.[F_INNER.API_KEYS]?.length;

  const checked = !apiKeysCheckedCount ? [API_KEY_SELECTED_ALL] : apiKeysChecked;
  const label = !apiKeysCheckedCount ? 'Все ключи' : `Ключи ${apiKeysCheckedCount}/${apiKeysCount}`;
  const isAllValid = checked.every((el) => {
    return (
      el === API_KEY_SELECTED_ALL ||
      (apiKeys.find((inner) => el === inner.id) &&
        apiKeys.find((inner) => el === inner.id).status === 1)
    );
  });
  const length = apiKeys.length;
  const options = [
    {
      value: API_KEY_SELECTED_ALL,
      label: 'Все ключи',
    },
    ...apiKeys
      .filter((el) => el.status === 1)
      .map((api) => ({
        value: api?.id,
        label: api?.name,
      })),
  ];

  useEffect(() => {
    if (!isAllValid && length) {
      setApiKeys && setApiKeys([]);
    }
  }, [setApiKeys, isAllValid, length]);

  // useEffect(() => {
  //   setApiKeys && setApiKeys([]);
  // }, [setApiKeys]);

  const handleChange = (e) => {
    const id = e.target.name;

    // если надо выбрать все ключи
    if (
      id === API_KEY_SELECTED_ALL ||
      (e.target.checked && apiKeysCheckedCount === apiKeysCount - 1)
    ) {
      setApiKeys([]);

      // если надо выбрать один ключ
      // когда все ключи были выбраны
    } else if (e.target.checked && apiKeysCheckedCount === apiKeysCount) {
      setApiKeys(id);
    } else {
      toggleApiKey(id);
    }
  };

  if (!apiKeysCount) {
    return null;
  }

  return (
    <DropdownWithCheckboxes
      label={label}
      checkboxSelection={true}
      options={options}
      checked={checked}
      onChange={handleChange}
      footer={<Footer />}
    />
  );
};
