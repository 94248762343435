import { useParams } from 'react-router';

import Table from 'components/Table';
import { TABLE_TYPE } from 'constants/table';
import { useProduct } from 'context/product.context';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';

const SalesByDay = () => {
  const { id } = useParams();
  const { isOzonPlatform, isLoading: productLoading } = useProduct();

  const { data, isLoading, isFetching } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_SALES,
    id,
    enabled: !!id,
  });

  return (
    <Table
      autoSize={false}
      data={data?.items || []}
      type={TABLE_TYPE.PRODUCT_SALES}
      hideSoldColumn={isOzonPlatform}
      isLoading={isLoading || isFetching || productLoading}
      noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
    />
  );
};

export default SalesByDay;
