import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import PaginationOnAggrid from 'components/PaginationOnAggrid';
import TableExportButton from 'components/TableExportButton';
import AddNestedCheckbox from 'components/TableFooter/AddNestedCheckbox';
import { TABLE_INNER_TYPE, TABLE_TYPE } from 'constants/table';
import { useTable } from 'context/table.context';
import { useUI } from 'context/ui.context';

const Button = ({ label, onClick }) => (
  <button
    onClick={onClick}
    className="border border-gray-250 px-2 py-1 text-gray-700 flex items-center leading-none cursor-pointer hover:text-green focus:border-green rounded"
  >
    {label}
  </button>
);
Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const TableFooter = ({
  type,
  nestedCheckbox,
  extra,
  isLoading,
  paginationOnBack,
  hidePagination,
  totalPages: outerTotal,
  paginationData,
}) => {
  const { isMobile } = useUI();
  const { totalPages: ctxTotal } = useTable();
  const totalPages = outerTotal || ctxTotal;
  const ref = useRef();

  return (
    <div
      id={'table-footer'}
      className={cn('flex justify-center wrapper-wide divide-gray-200', {
        ['flex-col space-y-3 py-4 divide-y']: isMobile,
        ['divide-x items-stretch py-5']: !isMobile,
      })}
      ref={ref}
    >
      {extra && (
        <div
          className={cn('flex items-center', {
            ['px-8']: !isMobile,
            ['justify-center']: isMobile,
          })}
        >
          {extra}
        </div>
      )}

      {totalPages > 1 && !hidePagination && (
        <PaginationOnAggrid
          totalPages={totalPages}
          paginationOnBack={paginationOnBack}
          paginationData={paginationData}
          type={type}
          isLoading={isLoading}
          className={cn('', {
            ['px-8']: !isMobile,
            ['pt-3 justify-center']: isMobile,
          })}
        />
      )}

      <div
        className={cn('flex justify-center items-center space-x-4', {
          ['px-8']: !isMobile,
          ['pt-3']: isMobile,
        })}
      >
        <div className="mr-2">Скачать таблицу:</div>
        <TableExportButton type={type} format={'xlsx'} />
        <TableExportButton type={type} format={'csv'} />
      </div>

      {nestedCheckbox && <AddNestedCheckbox footerRef={ref} />}
    </div>
  );
};

TableFooter.defaultProps = {
  nestedCheckbox: false,
};
TableFooter.propTypes = {
  totalPagesFromBack: PropTypes.number,
  type: PropTypes.oneOf([...Object.values(TABLE_TYPE), ...Object.values(TABLE_INNER_TYPE)])
    .isRequired,
  nestedCheckbox: PropTypes.bool,
  isLoading: PropTypes.bool,
  paginationOnBack: PropTypes.bool,
  tableRef: PropTypes.object,
  extra: PropTypes.element,
  hidePagination: PropTypes.bool,
  totalPages: PropTypes.number,
  paginationData: PropTypes.any,
};

export default TableFooter;
