import TableIsPromotionalFilter from 'components/TableIsPromotionalFilter';
import { isCategoryPage } from 'utils/pages';

const TableExtraFilters = () => {
  let filters = [];

  if (isCategoryPage()) {
    filters.push(<TableIsPromotionalFilter key={'category'} />);
  }

  if (filters.length === 0) {
    return null;
  }
  return <div className="mx-4 py-4 border-t border-gray-200">{filters}</div>;
};

export default TableExtraFilters;
