import { ArrowDownIcon } from 'assets/icons/ArrowDownIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import InputWithLabelAndCopy from './InputWithLabelAndCopy';

const SeoProductAdvancedFields = ({ value: fields = [], initValue: initFields = {}, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const fieldsLength = fields.length;
  if (fieldsLength === 0) {
    return null;
  }
  if (!isOpen) {
    return (
      <center>
        <button
          onClick={() => {
            setIsOpen(true);
          }}
          className="text-gray-300 flex items-center space-x-1"
        >
          <span>Развернуть еще {fieldsLength} полей</span> <ArrowDownIcon />
        </button>
      </center>
    );
  } else {
    return Object.keys(fields).map((key) => {
      const value = fields[key];

      const handleChange = (value) => {
        onChange({ ...fields, [key]: value });
      };
      return (
        <InputWithLabelAndCopy
          key={key}
          label={key}
          value={value || ''}
          onChange={handleChange}
          initValue={initFields[key] ?? ''}
        />
      );
    });
  }
};

SeoProductAdvancedFields.propTypes = {
  value: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string).isRequired).isRequired,
  initValue: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string).isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SeoProductAdvancedFields;
