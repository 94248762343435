import cn from 'classnames';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router';

import Panel from 'components/Panel';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import useStickyObserver from 'hooks/useStickyObserver';

const TableLayout = ({ children, isPeriodControlsShown, isDateControlsShown }) => {
  const [ref, isSticky] = useStickyObserver({ disableOnMobile: false });

  const { error } = useFilterReducer();

  if (error) {
    return (
      <Navigate
        to={{
          pathname: '/error-access',
          state: { error: error },
        }}
      />
    );
  }

  return (
    <>
      <div
        ref={ref}
        className={cn('overflow-x-auto lg:overflow-visible', {
          ['shadow-sm bg-white']: isSticky,
        })}
      >
        <Panel
          isPeriodControlsShown={isPeriodControlsShown}
          isDateControlsShown={isDateControlsShown}
        />
      </div>

      <div className="overflow-x-hidden">{children}</div>
    </>
  );
};

TableLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  isPeriodControlsShown: PropTypes.bool,
  isDateControlsShown: PropTypes.bool,
};

export default TableLayout;
