import TrashIcon from 'assets/icons/TrashIcon';
import Block from 'components/Block';
import ButtonPrimary from 'components/ButtonPrimary';
import Textarea from 'components/Textarea';
import { useSeoProductContext } from 'context/seo.product.context';
import PropTypes from 'prop-types';

const PHRASE_LIMIT = 5000;

const SeoProductSearchPhrases = ({ onChange, value }) => {
  const { doSearchReview, clearPhrases, state } = useSeoProductContext();
  const handleChange = (value) => {
    if (value == '\n') {
      return false;
    }
    const lines = value.split('\n');
    const linesTrimmed = lines.map((line) => line.trim());
    if (!linesTrimmed.join('\n').includes('\n\n')) {
      const isLastLineEmpty = lines[lines.length - 1] == ' ';
      if (!(lines.length > 1 && isLastLineEmpty)) {
        if (lines.length <= PHRASE_LIMIT) {
          onChange(value);
        }
      }
    }
  };

  const linesUsed = value && value !== '' ? String(value).split('\n').length : 0;
  const emptyInputs = !isInfoFilledIn(state);
  const disabled = linesUsed === 0 || emptyInputs;

  return (
    <Block
      label={'Запросы для проверки'}
      extra={
        <button
          className="text-green flex space-x-1 text-base space-between items-center"
          onClick={clearPhrases}
        >
          <TrashIcon width={16} height={16} /> <span className="leading-5">Очистить все</span>
        </button>
      }
    >
      <p>
        Каждый запрос должен быть с новой строки. Всего можно проверить до {PHRASE_LIMIT} запросов
      </p>
      <div className={'mt-4'}>
        <div className="flex justify-between mb-1">
          <div>Запросы</div>
          <div>
            <button className="text-gray-300 underline decoration-dotted cursor-pointer underline-offset-2">
              Примеры
            </button>
          </div>
        </div>
        <Textarea
          className="p-3"
          rows="10"
          placeholder="..."
          value={value}
          onChange={handleChange}
        />
        <div className={'text-gray-300 text-xs mb-4'}>
          Запросов {linesUsed}/{PHRASE_LIMIT}
        </div>
        <ButtonPrimary label={'Проверить'} onClick={doSearchReview} disabled={disabled} />
      </div>
    </Block>
  );
};

SeoProductSearchPhrases.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default SeoProductSearchPhrases;

const isInfoFilledIn = (state) => {
  const { name, brand, color, description, characteristics = [] } = state || {};
  return name || brand || color || description || characteristics.length > 0;
};
