import { lazy, useCallback, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router';

import HelpBlock from 'components/HelpBlock';
import Table from 'components/Table';
import {
  BLACK,
  BOUQUET,
  BROWN,
  CEMENT,
  DESERT,
  GREEN,
  MALIBU,
  MOJO,
  MOON,
  PINE,
  ROSE,
  SALMON,
  VIOLET,
} from 'constants/colors';
import { COLUMNS, TABLE_INNER_TYPE, TABLE_TYPE } from 'constants/table';
import { useAnalyticType } from 'context/analytic.type.context';
import { useProduct } from 'context/product.context';
import { useTable } from 'context/table.context';
import { useUserTariffs } from 'context/user.tariffs.context';
import { deleteStateKey } from 'helpers/utils';
import { useFetchFilteredInnerDataPaginated } from 'hooks/useFetchFilteredInnerData';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';
import PropTypes from 'prop-types';
import { dateComparatorOuter } from '../../../helpers/generateGridColumnsHelpers';
import ProductLisingLegend from '../Listing/Legend';
const ProductChart = lazy(() => import('components/ProductChart'));

const SELECTION_MAX = 10;

const getKey = (id) => `_${id}`;

const COLORS_SHOWN_LABELS = [
  BOUQUET,
  GREEN,
  MOJO,
  MOON,
  BROWN,
  DESERT,
  SALMON,
  VIOLET,
  CEMENT,
  PINE,
];

const Search = ({ fetchEnabled }) => {
  const [searchData, setSearchData] = useState([]);

  const [dynamicLabels, setDynamicLabels] = useState({});
  const [shownDynamicLabels, setShownDynamicLabels] = useState({});
  const [selectionDisabled, setSelectionDisabled] = useState(false);
  const [dynamicColors, setDynamicColors] = useState({});

  const { id } = useParams();
  const { isInner } = useAnalyticType();
  const { isProductSearchAvailable } = useUserTariffs();
  const { gridOptions } = useTable();
  const { visibility, avgPosition, isLoading: productLoading } = useProduct();

  const { data, error, isLoading, isFetching } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_SEARCH,
    id,
    isInner,
    enabled: isProductSearchAvailable && fetchEnabled,
  });

  const { data: ratingsData } = useFetchFilteredInnerDataPaginated({
    type: TABLE_INNER_TYPE.RATINGS,
    data: { id },
    enabled: fetchEnabled && isInner,
  });

  const getDynamicColor = () => {
    const keys = Object.keys(dynamicColors);
    const lastKey = keys[keys.length - 1];
    const lastKeyColor = dynamicColors[lastKey];
    const lastKeyIndex = COLORS_SHOWN_LABELS.indexOf(lastKeyColor);

    return COLORS_SHOWN_LABELS[lastKeyIndex + 1] || COLORS_SHOWN_LABELS[0];
  };

  const addShownDynamicLabel = (key) => {
    const color = getDynamicColor();
    setDynamicColors((prev) => ({ ...prev, [key]: color }));
    setShownDynamicLabels((prev) => ({ ...prev, [key]: dynamicLabels[key] }));
  };

  const removeShownDynamicLabel = (key) => {
    setDynamicColors((prev) => deleteStateKey(prev, key));
    setShownDynamicLabels((prev) => deleteStateKey(prev, key));
  };

  const onRowSelected = (node) => {
    const data = node?.data || [];
    const id = data?.id;

    if (!id) return;

    const key = getKey(id);

    const isSelected = node.isSelected();
    const isActiveSelected = shownDynamicLabels[key];

    if (isSelected && !isActiveSelected) {
      addShownDynamicLabel(key);
      const color = getDynamicColor();
      node.setData({ ...data, style: { color } });
    } else if (!isSelected && isActiveSelected) {
      removeShownDynamicLabel(key);
      node.setData({ ...data, style: { color: BLACK } });
    }
  };

  const handleOutsideRemoveShownLabel = (key) => {
    if (!gridOptions) return;

    const removedKey = +key.slice(1);

    gridOptions.api.forEachNode((rowNode) => {
      const { data, selected } = rowNode;
      const id = data?.id;

      if (id && selected && id === removedKey) {
        rowNode.setSelected(false, false);
      }
    });

    removeShownDynamicLabel(key);
  };

  const getSearchData = useCallback(() => {
    const searchData = [];
    const searchLabels = {};
    data?.table_data?.forEach((item) => {
      searchData[getKey(item.id)] = item.listing;
      searchLabels[getKey(item.id)] = item?.[COLUMNS.SEARCH_PHRASE];
    });

    return {
      searchData,
      searchLabels,
    };
  }, [data]);

  const isDataLoading =
    (data?.table_data?.length && !Object.keys(dynamicLabels).length) ||
    isLoading ||
    isFetching ||
    productLoading;

  useEffect(() => {
    const searchDataLabels = getSearchData();

    setSearchData(searchDataLabels.searchData);
    setDynamicLabels(searchDataLabels.searchLabels);
  }, [getSearchData]);

  useEffect(() => {
    const count = Object.keys(shownDynamicLabels).length;

    if (count < SELECTION_MAX && selectionDisabled) {
      setSelectionDisabled(false);
    } else if (count >= SELECTION_MAX && !selectionDisabled) {
      setSelectionDisabled(true);
    }
  }, [selectionDisabled, shownDynamicLabels]);

  if (!isProductSearchAvailable) {
    return <Navigate to={`/product/${id}`} />;
  }
  const extra = {
    [COLUMNS.VISIBILITY]: data?.[COLUMNS.VISIBILITY] || [],
    [COLUMNS.AVG_POSITION_BY_DAY]: data?.[COLUMNS.AVG_POSITION_BY_DAY] || [],
  };

  if (searchData) {
    for (let key in searchData) {
      if (Array.isArray(searchData[key])) {
        extra[key] = searchData[key].map((positionData) => {
          const { sort_position } = positionData || {};
          return sort_position || null;
        });
      } else {
        extra[key] = null;
      }
    }
  }

  const buildInnerExtra = () => {
    const data = ratingsData?.pages?.[0] || [];
    return data
      .sort((a, b) => dateComparatorOuter(a.date, b.date))
      .reduce((prev, el) => {
        const orders_rub = prev[COLUMNS.ORDERS_RUB_];
        const orders_qty = prev[COLUMNS.ORDERS_QTY];
        const price = prev[COLUMNS.PRICE];
        const stock_qty = prev[COLUMNS.STOCK_QTY];
        return {
          [COLUMNS.ORDERS_RUB_]: !Array.isArray(orders_rub)
            ? [el[COLUMNS.SUM_ORDERS_INNER]]
            : [...orders_rub, el[COLUMNS.SUM_ORDERS_INNER]],
          [COLUMNS.ORDERS_QTY]: !Array.isArray(orders_qty)
            ? [el[COLUMNS.COUNT_ORDERS_INNER]]
            : [...orders_qty, el[COLUMNS.COUNT_ORDERS_INNER]],
          [COLUMNS.PRICE]: !Array.isArray(price)
            ? [el[COLUMNS.PRICE]]
            : [...price, el[COLUMNS.PRICE]],
          [COLUMNS.STOCK_QTY]: !Array.isArray(stock_qty)
            ? [el[COLUMNS.QUANTITY]]
            : [...stock_qty, el[COLUMNS.QUANTITY]],
        };
      }, {});
  };
  return (
    <>
      {!isInner && <HelpBlock type="product_search" />}
      <div className="bg-gray-100 py-4">
        <div className="wrapper-wide">
          <ProductChart
            fetchEnabled={fetchEnabled}
            labels={{
              [COLUMNS.VISIBILITY]: `Фраз в топ ${visibility}`,
              [COLUMNS.AVG_POSITION_BY_DAY]: `Ср.позиция в топ ${avgPosition}`,
            }}
            colors={[ROSE, MALIBU]}
            before={true}
            extra={isInner ? { ...buildInnerExtra(), ...extra } : extra}
            loading={isDataLoading}
            error={error}
            dynamicLabels={dynamicLabels}
            shownDynamicLabels={shownDynamicLabels}
            removeShownLabel={handleOutsideRemoveShownLabel}
            dynamicColors={dynamicColors}
            columns={8}
            reversed={[COLUMNS.AVG_POSITION_BY_DAY]}
            initialLabels={[COLUMNS.VISIBILITY, COLUMNS.AVG_POSITION_BY_DAY, COLUMNS.ORDERS_RUB_]}
            showPostfixes={false}
            chartKey={TABLE_TYPE.PRODUCT_SEARCH}
          />
        </div>
      </div>
      <div className={`${selectionDisabled ? 'aggrid-checkbox-selection-disabled' : ''}`}>
        {fetchEnabled && (
          <Table
            data={data || []}
            type={TABLE_TYPE.PRODUCT_SEARCH}
            autoSizeColumns={[COLUMNS.VERTICAL_HEADER]}
            onRowSelected={onRowSelected}
            isLoading={isDataLoading}
            noRowsText={`У товара<br/>нет поисковых фраз`}
            getRowHeight={(params) => {
              return Object.values(params.data).some((value) => value?.promotion_type?.id)
                ? 80
                : null;
            }}
            footerDescription={<ProductLisingLegend />}
          />
        )}
      </div>
    </>
  );
};

Search.propTypes = {
  fetchEnabled: PropTypes.bool,
};
export default Search;
