import { useState } from 'react';
import { Navigate } from 'react-router';

import { PositionsContent, PositionsHeader } from 'components/Positions';
import { PAGE_MAIN } from 'constants';
import { FilterModalProvider } from 'context/filter.modal.context';
import { useUserTariffs } from 'context/user.tariffs.context';
import { PositionsContext } from './context';

const Positions = () => {
  const { isPositionsAvailable } = useUserTariffs();

  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSeller, setSelectedSeller] = useState();
  const [selectedBrand, setSelectedBrand] = useState();
  const [searchedSeller, setSearchedSeller] = useState('');
  const [searchedBrand, setSearchedBrand] = useState('');

  if (!isPositionsAvailable) {
    return <Navigate to={PAGE_MAIN} />;
  }

  return (
    <FilterModalProvider>
      <PositionsContext.Provider
        value={{ searchedSeller, setSearchedSeller, searchedBrand, setSearchedBrand }}
      >
        <div className="flex flex-col grow">
          <PositionsHeader
            selectedBrand={selectedBrand}
            selectedCategory={selectedCategory}
            selectedSeller={selectedSeller}
            setSelectedBrand={setSelectedBrand}
            setSelectedCategory={setSelectedCategory}
            setSelectedSeller={setSelectedSeller}
          />
          <PositionsContent
            selectedBrand={selectedBrand}
            selectedSeller={selectedSeller}
            selectedCategory={selectedCategory}
          />
        </div>
      </PositionsContext.Provider>
    </FilterModalProvider>
  );
};

export default Positions;
