import { STALE_TIME } from 'constants/query';
import { queryClient } from 'context';
import { useAnalyticType } from 'context/analytic.type.context';
import { useModalFilter } from 'context/filter.modal.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useDebounce } from 'hooks';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import apiClient from 'services/baseClient';
import apiInnerClient from 'services/baseInnerClient';

const SEARCH_LIMIT = 3;

const defaultQueryOptions = {
  retry: 3,
  retryDelay: 3000,
};

const useSearchBrands = (props) => {
  //const enabled = props?.enabled ?? true;
  const initialValue = props?.initialValue ?? '';

  const [searchTerm, setSearchTerm] = useState(initialValue);
  const handleChange = (e) => setSearchTerm(`${e.target.value}` || '');
  //const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const {
    state: { localFilter },
  } = useModalFilter();

  const {
    filter: { warehouse, platform, date },
  } = useFilterReducer();

  const modalFilter = { ...{ ...localFilter, brand: [] }, warehouse, platform: [platform], date };

  //const isEnabled = debouncedSearchTerm.length >= SEARCH_LIMIT && enabled;

  const query = useQuery({
    queryKey: ['searchBrands', searchTerm, modalFilter],
    queryFn: () => {
      return apiClient.getBrandsSearch(searchTerm, modalFilter);
    },
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: true,
    staleTime: STALE_TIME,
    ...defaultQueryOptions,
  });

  return { query, value: searchTerm, onChange: handleChange };
};

const useSearchSellers = (props) => {
  //const enabled = props?.enabled ?? true;
  const initialValue = props?.initialValue ?? '';

  const [searchTerm, setSearchTerm] = useState(initialValue);
  const handleChange = (e) => setSearchTerm(`${e.target.value}` || '');
  //const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const {
    state: { localFilter },
  } = useModalFilter();

  const {
    filter: { warehouse, platform, date },
  } = useFilterReducer();

  const modalFilter = { ...{ ...localFilter, seller: [] }, warehouse, platform: [platform], date };
  //const isEnabled = debouncedSearchTerm.length >= SEARCH_LIMIT && enabled;
  const query = useQuery({
    queryKey: ['searchSellers', searchTerm, modalFilter],
    queryFn: () => apiClient.getSellersSearch(searchTerm, modalFilter),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: true,
    staleTime: STALE_TIME,
    ...defaultQueryOptions,
  });

  return { query, value: searchTerm, onChange: handleChange };
};

const useSearchSubjects = (props) => {
  const initialValue = props?.initialValue ?? '';

  const [searchTerm, setSearchTerm] = useState(initialValue);
  const handleChange = (e) => setSearchTerm(`${e.target.value}` || '');

  const {
    state: { localFilter },
  } = useModalFilter();

  const {
    filter: { warehouse, platform, date },
  } = useFilterReducer();

  const modalFilter = { ...{ ...localFilter, subject: [] }, warehouse, platform: [platform], date };

  const query = useQuery({
    queryKey: ['searchSubjects', searchTerm, modalFilter],
    queryFn: () => apiClient.getSubjectsSearch(searchTerm, modalFilter),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: true,
    staleTime: STALE_TIME,
    ...defaultQueryOptions,
  });

  return { query, value: searchTerm, onChange: handleChange };
};

const getTreeCategoryOptions = (filter, enabled) => ({
  queryKey: ['categoryTree', filter],
  queryFn: () => apiClient.getCategoriesTree(filter),
  staleTime: STALE_TIME,
  refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  enabled,
  ...defaultQueryOptions,
});

const useSearchCategories = (enabled = true) => {
  const {
    filter: { platform, date },
  } = useFilterReducer();
  return useQuery(getTreeCategoryOptions({ platform: platform + '', date }, enabled));
};

const prefetchTreeCategory = async (filter) => {
  await queryClient.prefetchQuery(
    getTreeCategoryOptions({ platform: filter.platform, date: filter.date }),
  );
};

const useCategoriesParents = (id) => {
  const {
    filter: { platform, date },
  } = useFilterReducer();

  return useQuery({
    queryKey: ['categoryTree', { id, platform, date }],
    queryFn: () => apiClient.getCategoryParents({ id, platform: [platform], date }),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: true,
    enabled: !!id,
    ...defaultQueryOptions,
  });
};

const useSearchProduct = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const {
    filter: { platform },
    isLoaded,
  } = useFilterReducer();
  const handleChange = (e) => setSearchTerm(typeof e === 'string' ? e : e?.target?.value || '');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const isEnabled = debouncedSearchTerm.length >= SEARCH_LIMIT && isLoaded;

  const query = useQuery({
    queryKey: ['searchProducts', debouncedSearchTerm, { platform }],
    queryFn: () => apiClient.getProductSearch(debouncedSearchTerm, platform),
    enabled: isEnabled,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: true,
    ...defaultQueryOptions,
  });

  return { query, value: searchTerm, onChange: handleChange };
};

const getOuterMethod = (criteria) => {
  return criteria === 'products'
    ? 'getProductSearch'
    : criteria === 'sellers'
    ? 'getSellersSearch'
    : 'getBrandsSearch';
};

const useMultiSearch = () => {
  const { isInner } = useAnalyticType();

  const [searchTerm, setSearchTerm] = useState('');
  const [criteria, setCriteria] = useState(isInner ? 'external_id' : 'products');
  const [invalidMessage, setInvalidMessage] = useState();

  useEffect(() => {
    setCriteria(isInner ? 'external_id' : 'products');
  }, [isInner]);

  const {
    filter: { platform },
    isLoaded,
  } = useFilterReducer();
  const handleChange = (e) => setSearchTerm(typeof e === 'string' ? e : e?.target?.value || '');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  useEffect(() => {
    if (criteria === 'sellers' && debouncedSearchTerm.trim() === 'ООО') {
      setInvalidMessage({
        title: 'Уточните запрос',
        content:
          'Найдено более тысячи продавцов с названием ООО. | Пожалуйста, уточните ваш запрос, чтобы мы могли найти нужного продавца.',
      });
    } else {
      setInvalidMessage(null);
    }
  }, [debouncedSearchTerm, criteria]);

  const isEnabled =
    debouncedSearchTerm.length >= SEARCH_LIMIT &&
    isLoaded &&
    !(criteria === 'sellers' && debouncedSearchTerm.trim() === 'ООО');
  const callbackFunc = isInner
    ? () => apiInnerClient.searchInner({ criteria, value: debouncedSearchTerm })
    : () =>
        apiClient[getOuterMethod(criteria)](
          debouncedSearchTerm,
          criteria === 'products' ? platform : { platform: [platform] },
        );

  const query = useQuery({
    queryKey: ['search', debouncedSearchTerm, { platform }, criteria],
    queryFn: callbackFunc,
    enabled: isEnabled && searchTerm !== '',
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: true,
    ...defaultQueryOptions,
  });

  return {
    query,
    value: searchTerm,
    criteria,
    setCriteria,
    onChange: handleChange,
    setValue: setSearchTerm,
    invalidMessage,
  };
};

export {
  prefetchTreeCategory,
  useCategoriesParents,
  useMultiSearch,
  useSearchBrands,
  useSearchCategories,
  useSearchProduct,
  useSearchSellers,
  useSearchSubjects,
};
