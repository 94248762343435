import TableExtraFilters from 'components/TableExtraFilters';
import TableNav from 'components/TableNav';
import TableHeader from './TableHeader';

export const TableHeaderMain = () => (
  <>
    <TableHeader>
      <TableNav />
    </TableHeader>
    <TableExtraFilters />
  </>
);
