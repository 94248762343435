import { useQuerySeo } from 'hooks/useFetchSeo';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { PAGE_SEO_MONITORING, SEO_MONITORING_PRODUCTS } from '../../constants';
import { useMonitoringGroups } from '../../context/monitoring.groups.context';
import { MODES } from './constants';
import MonitoringInsert from './MonitoringInsert';
import MonitoringResult from './MonitoringResult';

const getInit = (url) => {
  if (url) {
    const splitted = url.split(',');
    for (let i = splitted.length; i < 5; i++) {
      splitted.push('');
    }
    return splitted;
  }
  return ['', '', '', '', ''];
};

const getArrayFromPreset = (arr) => {
  const result = [...arr];
  if (arr.length < 5) {
    for (let i = result.length; i < 5; i++) {
      result.push('');
    }
  } else {
    result.push('');
  }
  return result;
};

const MonitoringContent = ({ mode, setMode }) => {
  const { editGroup } = useMonitoringGroups();
  const searchParams = new URLSearchParams(document.location.search);
  const productsURL = searchParams.get('products');
  const phrasesURL = searchParams.get('phrases');
  const isEnteringURL = searchParams.get('isEntering');
  const filter = searchParams.get('filter');
  const [products, setProducts] = useState(getInit(productsURL));
  const [phrases, setPhrases] = useState(getInit(phrasesURL));
  const [isEntering, setIsEntering] = useState(
    isEnteringURL === null ? false : isEnteringURL === 'false',
  );
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data = [],
    refetch,
    isLoading,
    isFetching,
  } = useQuerySeo({
    type: SEO_MONITORING_PRODUCTS,
    filter: {
      platform: '2',
      product: products.filter((el) => el),
    },
  });

  useEffect(() => {
    if (productsURL) {
      refetch();
    }
  }, [productsURL, refetch]);

  useEffect(() => {
    if (editGroup) {
      const { preset } = editGroup;
      const { products, phrases, isEntering } = preset;
      setProducts(getArrayFromPreset(products));
      setPhrases(getArrayFromPreset(phrases));
      setIsEntering(isEntering);
    }
  }, [editGroup]);

  const handleMonitoring = () => {
    const params = new URLSearchParams({
      ['products']: products.filter((el) => el).join(','),
      ['phrases']: phrases.filter((el) => el).join(','),
      ['isEntering']: isEntering,
      filter,
    });
    navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });
    setMode(MODES.RESULT);
    refetch();
  };
  const handleNewMonitoring = () => {
    // setProducts(getInit());
    // setPhrases(getInit());
    navigate(PAGE_SEO_MONITORING);
    setMode(MODES.INSERT);
  };
  if (mode === MODES.INSERT) {
    return (
      <MonitoringInsert
        products={products}
        phrases={phrases}
        setProducts={setProducts}
        setPhrases={setPhrases}
        handleMonitoring={handleMonitoring}
        isEntering={isEntering}
        setIsEntering={setIsEntering}
      />
    );
  }
  return (
    <MonitoringResult
      data={data}
      handleNewMonitoring={handleNewMonitoring}
      isLoading={isLoading || isFetching}
      phrases={phrases}
      products={products}
      isEntering={isEntering}
    />
  );
};

MonitoringContent.propTypes = {
  mode: PropTypes.any,
  setMode: PropTypes.func,
};

export default MonitoringContent;
