import DownIcon from 'assets/icons/DownIcon';
import TrashIcon from 'assets/icons/TrashIcon';
import Block from 'components/Block';
import BlockWithToggler from 'components/Block/BlockWithToggler';
import ButtonPrimary from 'components/ButtonPrimary';
import Info from 'components/Info';
import InfoSimple from 'components/Info/Simple';
import { useSeoProductContext } from 'context/seo.product.context';
import { replaceJpgWithWebp } from 'utils/images';
import InputWithLabelAndCopy from './components/InputWithLabelAndCopy';
import SeoProductAdvancedFields from './components/SeoProductAdvancedFields';
import SeoProductSearchPhraseOverview from './components/SeoProductSearchPhraseOverview';
import SeoProductSearchPhrases from './components/SeoProductSearchPhrases';
import SeoProductSearchTable from './components/SeoProductSearchTable';

const ProductCard = () => {
  const {
    basicInfo,
    initState,
    state,
    changeState,
    resetState,
    clearSearch,
    resultsInited,
    scrollRef,
  } = useSeoProductContext();

  return (
    <div className="bg-gray-100 grow">
      <div className="bg-white wrapper-wide pb-4 pt-3">
        <h1 className="text-xl pb-3 font-bold">Проверка вхождения фраз в описание товара</h1>
        <ButtonPrimary
          className="text-base leading-6"
          label={'Новая проверка'}
          onClick={clearSearch}
        />
      </div>

      <div className="wrapper-wide flex space-x-4 my-4">
        <div className="grow">
          <Block
            label={'Карточка товара'}
            extra={
              <button
                className="text-green flex space-x-1 text-base space-between items-center"
                onClick={resetState}
              >
                <TrashIcon width={16} height={16} /> <span className="leading-5">Очистить все</span>
              </button>
            }
          >
            <div className="flex space-x-12">
              <div className="space-y-4 w-80">
                <div className="bg-gray-300 h-60 w-80 rounded">
                  <img
                    src={replaceJpgWithWebp(basicInfo.image)}
                    className="h-60 w-80 rounded object-cover"
                  />
                </div>
                <h2 className="text-2xl font-normal leading-7 my-4">{basicInfo?.name}</h2>
                <div className="space-y-1 text-base ">
                  <div>
                    <a
                      className="text-green flex items-center"
                      href={'/product/' + basicInfo?.id}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Посмотреть товар на WeCheck.ru{' '}
                      <DownIcon height={16} width={16} className="rotate-[-90deg] ml-1" />
                    </a>
                  </div>
                  <div>
                    <a
                      className="text-green flex items-center"
                      href={
                        'https://www.wildberries.ru/catalog/' +
                        basicInfo?.external_id +
                        '/detail.aspx'
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      Посмотреть товар на <span className="text-purple ml-1">WB</span>{' '}
                      <DownIcon height={16} width={16} className="rotate-[-90deg] ml-1 mb-1" />
                    </a>
                  </div>
                </div>
                <div className="space-y-2 text-base">
                  <Info label="Артикул" value={basicInfo?.external_id} isCopyable />
                  <InfoSimple
                    label="Продавец"
                    value={
                      <a
                        href={'/main/products?filter=seller%3D' + basicInfo?.seller?.id}
                        className="text-green  flex items-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {basicInfo?.seller?.name}{' '}
                        <DownIcon height={16} width={16} className="rotate-[-90deg]" />
                      </a>
                    }
                  />
                  <InfoSimple
                    label="Бренд"
                    value={
                      <a
                        href={'/main/products?filter=brand%3D' + basicInfo?.brand?.id}
                        className="text-green flex items-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {basicInfo?.brand?.name}{' '}
                        <DownIcon height={16} width={16} className="rotate-[-90deg]" />
                      </a>
                    }
                  />
                </div>
              </div>

              <div className="grow space-y-6">
                <InputWithLabelAndCopy
                  label={'Название товара'}
                  value={state?.name || ''}
                  onChange={(value) => {
                    changeState('name', value);
                  }}
                  initValue={initState?.name || ''}
                />
                <InputWithLabelAndCopy
                  label={'Бренд'}
                  value={state?.brand || ''}
                  onChange={(value) => {
                    changeState(['brand'], value);
                  }}
                  initValue={initState?.brand || ''}
                />
                <InputWithLabelAndCopy
                  label={'Цвет'}
                  value={state?.color || ''}
                  onChange={(value) => {
                    changeState('color', value);
                  }}
                  initValue={initState?.color || ''}
                />
                <InputWithLabelAndCopy
                  label={'Описание'}
                  value={state?.description || ''}
                  type={'textarea'}
                  onChange={(value) => {
                    changeState('description', value);
                  }}
                  initValue={initState?.description || ''}
                />

                {state?.characteristics && (
                  <SeoProductAdvancedFields
                    value={state.characteristics}
                    onChange={(value) => {
                      changeState('characteristics', value);
                    }}
                    initValue={initState?.characteristics || {}}
                  />
                )}
              </div>
            </div>
          </Block>
        </div>
        <div className="flex-none w-4/12">
          <SeoProductSearchPhrases
            onChange={(value) => {
              changeState('phrases', value);
            }}
            value={state.phrases}
          />
        </div>
      </div>

      <div ref={scrollRef} className={!resultsInited && 'hidden'}>
        <BlockWithToggler label={'Результаты проверки'}>
          <SeoProductSearchPhraseOverview />
        </BlockWithToggler>

        <SeoProductSearchTable />
      </div>
    </div>
  );
};

export default ProductCard;
