const DonutIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8404 14.2667V18.9793C15.4179 18.5514 19.0005 14.6989 19.0005 10.0095C19.0005 7.87061 18.2551 5.90577 17.01 4.36061L13.6681 7.70243C14.0884 8.37078 14.3314 9.16177 14.3314 10.0095C14.3314 12.116 12.8309 13.8719 10.8404 14.2667ZM12.4903 6.4606C11.7838 5.96212 10.9217 5.66933 9.99122 5.66933C7.8851 5.66933 6.12936 7.16945 5.73421 9.1595L1.02149 9.1595C1.44983 4.58244 5.30219 1.00024 9.99122 1.00024C12.2118 1.00024 14.2448 1.80364 15.8153 3.13563L12.4903 6.4606ZM1.02148 10.8595C1.42357 15.1565 4.84351 18.5767 9.14041 18.9792L9.14041 14.2664C7.42558 13.9256 6.07468 12.5744 5.73419 10.8595L1.02148 10.8595Z"
        fill="black"
      />
    </svg>
  );
};

export default DonutIcon;
