import { PAGE_INNER_PRODUCT } from 'constants/pages';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const TableInnerProductName = (props) => {
  const { value, data, from } = props;
  const { external_id, barcode } = data;
  return (
    <Link
      to={`${PAGE_INNER_PRODUCT}/${external_id}/${barcode}${from ? `?from=${from}` : ''}`}
      target="_blank"
    >
      {value}
    </Link>
  );
};

TableInnerProductName.propTypes = {
  value: PropTypes.string,
  data: PropTypes.shape({
    external_id: PropTypes.any,
    barcode: PropTypes.string,
  }),
  from: PropTypes.string,
};

export default TableInnerProductName;
