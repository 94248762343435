import CodeVerification from 'components/CodeVerification';
import ResendTimer from 'components/ResendTimer';
import { useAuth } from 'context/auth.context';
import { useCallback, useState } from 'react';

const CheckPhoneCode = () => {
  const { checkPhoneNumber, registerPhoneNumber, phoneNumber, setTmpToken, setStep } = useAuth();
  const [error, setError] = useState(null);
  const onChange = useCallback(
    (code) => {
      registerPhoneNumber({
        phone_number: phoneNumber,
        code: code,
      })
        .then((data) => {
          const { token } = data || {};
          setTmpToken(token);
          setError(null);
          setStep(2);
        })
        .catch((error) => {
          const response = error.response || {};
          const data = response.data || {};
          const message = data.message ?? 'Ошибка';
          setError(message);
        });
    },
    [phoneNumber, registerPhoneNumber, setStep, setTmpToken],
  );

  const resend = () => {
    checkPhoneNumber({
      phone_number: phoneNumber,
    });
  };
  return (
    <>
      <h1>Подтверждение номера</h1>

      <div className={'mb-5'}>
        <div className="relative md:flex md:-mx-36 md:px-36 mt-5">
          <label
            className="block mb-2 text-black flex-auto pr-3 md:absolute md:left-0 md:w-36 md:h-full md:flex md:items-center md:justify-end md:mb-0"
            htmlFor="phone"
          >
            Введите SMS-код
          </label>

          <CodeVerification onChange={onChange} />
        </div>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </div>
      <ResendTimer onResend={resend} />
      <button className="text-green my-2" onClick={() => setStep(0)}>
        Ввести другой номер
      </button>
    </>
  );
};

export default CheckPhoneCode;
