import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { ProductFilterSticky } from 'components/Layout/ProductLayout';
import LoadingSpin from 'components/LoadingSpin';
import { default as ProductContentContainer } from 'components/Product';
import ProductHeader from 'components/ProductHeader';
import ProductTabWrapper from 'components/ProductWrapper';
import {
  PAGE_PRODUCT_END_LISTING,
  PAGE_PRODUCT_END_ORDERS_SALES,
  PAGE_PRODUCT_END_SEARCH,
  PAGE_PRODUCT_END_SIMILAR,
  PAGE_PRODUCT_END_SIZES,
  PAGE_PRODUCT_END_STOCKS,
} from 'constants';
import { PAGE_INNER_PRODUCT } from 'constants/pages';
import { TABLE_INNER_TYPE } from 'constants/table';
import { useProduct } from 'context/product.context';
import { useFetchFilteredInnerData } from 'hooks/useFetchFilteredInnerData';
import {
  InnerProductOrdersSales,
  InnerWarehouses,
  ProductListing,
  ProductSearch,
  ProductSimilar,
} from '../../index';
import InnerProductSizes from '../Sizes';
import InnerProductCardList from './InnerProductCardList';
import InnerProductCharts from './InnerProductCharts';

const InnerWarehouseWrapper = (props) => {
  return <InnerWarehouses isProduct {...props} />;
};
const TABS = [
  '',
  PAGE_PRODUCT_END_SIZES,
  PAGE_PRODUCT_END_ORDERS_SALES,
  PAGE_PRODUCT_END_SIMILAR,
  PAGE_PRODUCT_END_LISTING,
  PAGE_PRODUCT_END_SEARCH,
  PAGE_PRODUCT_END_STOCKS,
];
const getTabBarcode = () => {
  const splitted = window.location.pathname.split('/');
  const last = splitted[5] || '';
  if (TABS.includes(last)) {
    return { barcode: splitted[4], tab: splitted[5] || '' };
  }
  return { barcode: `${splitted[4]}/${splitted[5]}`, tab: splitted[6] || '' };
};

const Product = () => {
  const { updateProductData } = useProduct();
  const params = useParams();
  const { id } = params;
  const { data, isLoading, isFetching } = useFetchFilteredInnerData({
    type: TABLE_INNER_TYPE.PRODUCT,
    data: { id },
  });
  const { tab, barcode } = getTabBarcode();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(tab || '');
  const goToPage = ({ link }) => {
    const tab = link.replace(`${PAGE_INNER_PRODUCT}/${id}/${barcode}`, '').replace('/', '');
    setCurrentTab(tab);

    navigate(
      {
        pathname: link,
      },
      { replace: true },
    );
  };

  useEffect(() => {
    if (data) {
      const {
        image,
        url,
        external_id,
        last_change_date,
        retail_price_withdisc_rub,
        rating,
        ...other
      } = data;
      updateProductData({
        ...other,
        images: [image],
        link: [url],
        articul: external_id,
        inner_product: external_id,
        last_update: last_change_date,
        price: retail_price_withdisc_rub,
        reviews_count: rating,
        isInner: true,
      });
    }
  }, [updateProductData, data]);

  useEffect(() => {
    if (data) {
      let { name } = data;
      if (name.length > 40) {
        name = name.split('').slice(0, 38).join('') + '...';
      }
      document.title = `${name} - WeCheck`;
    } else {
      document.title = 'WeCheck - аналитика маркетплейсов';
    }
  }, [data]);

  if (isLoading) {
    return (
      <div>
        <LoadingSpin />
      </div>
    );
  }

  return (
    <div>
      <>
        <ProductFilterSticky />

        <ProductHeader isInner barcode={barcode} />

        <InnerProductCardList data={data} isLoading={isLoading || isFetching} barcode={barcode} />

        <ProductContentContainer onTabChange={goToPage} isInner barcode={barcode}>
          <ProductTabWrapper currentTab={currentTab} tab={''} component={InnerProductCharts} />
          <ProductTabWrapper
            currentTab={currentTab}
            tab={PAGE_PRODUCT_END_ORDERS_SALES}
            component={InnerProductOrdersSales}
          />
          <ProductTabWrapper
            currentTab={currentTab}
            tab={PAGE_PRODUCT_END_SIMILAR}
            component={ProductSimilar}
          />
          <ProductTabWrapper
            currentTab={currentTab}
            tab={PAGE_PRODUCT_END_LISTING}
            component={ProductListing}
          />

          <ProductTabWrapper
            currentTab={currentTab}
            tab={PAGE_PRODUCT_END_SEARCH}
            component={ProductSearch}
          />
          <ProductTabWrapper
            currentTab={currentTab}
            tab={PAGE_PRODUCT_END_STOCKS}
            component={InnerWarehouseWrapper}
          />
          <ProductTabWrapper
            currentTab={currentTab}
            tab={PAGE_PRODUCT_END_SIZES}
            component={InnerProductSizes}
          />
        </ProductContentContainer>
      </>
    </div>
  );
};

export default Product;
