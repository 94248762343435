import MagicIcon from 'assets/icons/MagicIcon';
import TrashIcon from 'assets/icons/TrashIcon';
import { CrossIcon } from 'assets/images';
import { useQuerySeo } from 'hooks/useFetchSeo';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import CopyIcon from '../../assets/icons/CopyIcon';
import { SEO_SMART_DESCRIPTION_WORDS } from '../../constants';
import ButtonPrimary from '../ButtonPrimary';
import LoadingSpin from '../LoadingSpin';
import { MODES } from '../SeoMonitoring/constants';

const SmartDescriptionContent = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const phrasesURL = searchParams.get('phrases');
  const [mode, setMode] = useState(phrasesURL ? MODES.RESULT : MODES.INSERT);
  return (
    <div className="mt-20 flex flex-col items-center">
      <span className="font-bold block text-7xl leading-[79px] text-[#03261D]">
        Умное описание товаров
      </span>
      <span className="font-bold block text-5xl leading-[53px] text-[rgba(13,_48,_39,_0.4)]">
        с помощью искусственного интеллекта
      </span>
      <span className="mt-10 block text-[#365C5A] text-2xl text-center">
        WeCheck проанализирует товары и фразы, и с помощью нейросети создаст описание товара.
        <br />
        Это описание поможет артикулу подняться в топ выдачи и увеличить продажи
      </span>
      <div className="relative mt-[60px] min-h-[445px] mb-5">
        <div
          className="w-[470px] h-[445px] absolute -z-10"
          style={{
            background: 'linear-gradient(90.05deg, #00B8C4 0.85%, #E17878 50.4%, #FFC121 99.96%)',
            borderRadius: 30,
            filter: 'blur(140px)',
          }}
        />
        <SmartDescriptionWords setMode={setMode} mode={mode} phrasesURL={phrasesURL} />
      </div>
    </div>
  );
};

const SmartDescriptionWords = ({ mode, setMode, phrasesURL }) => {
  const [phrases, setPhrases] = useState(
    phrasesURL
      ? [...phrasesURL.split(',').map((el) => ({ value: el })), { value: '' }]
      : [{ value: '' }],
  );
  const [resultData, setResultData] = useState('');
  // const [focusIndex, setFocusIndex] = useState(0);
  const [copyText, setCopyText] = useState('Скопировать');
  const location = useLocation();
  const navigate = useNavigate();
  const textAreaRef = useRef(null);
  const resizeTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resizeTextArea, [resultData, textAreaRef.current]);
  const {
    data = {},
    refetch,
    isLoading,
    isFetching,
  } = useQuerySeo({
    type: SEO_SMART_DESCRIPTION_WORDS,
    filter: {
      phrases: phrases.map((el) => el.value).filter((el) => !!el),
    },
  });

  useEffect(() => {
    setResultData(data?.text || '');
  }, [data?.text]);
  useEffect(() => {
    if (phrasesURL) {
      refetch();
    }
  }, [phrasesURL, refetch]);

  const handleSubmit = () => {
    const params = new URLSearchParams({
      ['phrases']: phrases
        .map((el) => el.value)
        .filter((el) => !!el)
        .join(','),
    });
    navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });
    setMode(MODES.RESULT);
    refetch();
  };

  const handleChange = (value, index) => {
    const newPhrases = [...phrases];
    newPhrases[index] = { value: value };
    if (!newPhrases.find((el) => el.value === '')) {
      newPhrases.push({ value: '' });
    }
    setPhrases(newPhrases);
  };
  return (
    <div className="flex space-x-10">
      <div className="w-[468px] p-6 bg-white flex rounded-3xl z-10 space-x-4 h-fit">
        <div>
          <div className="space-y-4 mb-5">
            {phrases.map((phrase, index) => {
              return (
                <div className="flex items-center relative" key={phrase + '_' + index}>
                  <input
                    id={`input_${index}`}
                    value={phrase.value || ''}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        const nextInput = document.getElementById(`input_${index + 1}`);
                        if (nextInput) {
                          nextInput.focus();
                        }
                      }
                    }}
                    type="text"
                    placeholder={'Введите слово или фразу ...'}
                    className="block p-4 bg-[#F1F1F1] grow rounded-lg w-[420px] placeholder:italic placeholder:text-gray-300 focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-offset-0 focus:ring-green z-10"
                  />
                  {phrase && (
                    <button
                      className="absolute right-4 z-20 !text-green p-2"
                      onClick={() => {
                        const resultPhrases = [...phrases];
                        if (resultPhrases.length !== 1) {
                          resultPhrases.splice(index, 1);
                          setPhrases(resultPhrases);
                        } else {
                          resultPhrases.splice(index, 1, { value: '' });
                          setPhrases(resultPhrases);
                        }
                      }}
                    >
                      <CrossIcon fillProps="#219653" />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
          <div className="flex space-x-3 pb-4 border-b border-[rgba(0, 0, 0, 0.06)]">
            <button
              onClick={() => setPhrases([{ value: '' }])}
              className="h-[57px] flex items-center border border-[#FFE8E8] justify-center bg-[#FFE8E8] text-[#EB392D] w-[57px] text-base rounded-lg hover:!bg-white"
            >
              <TrashIcon height={18} width={18} />
            </button>
            <ButtonPrimary
              onClick={handleSubmit}
              label={
                <div className="flex space-x-3 items-center">
                  {isLoading || isFetching ? (
                    <LoadingSpin
                      classNames="w-[22px] h-[22px]"
                      iconClassNames="!text-gray-250 group-hover:!text-green"
                    />
                  ) : (
                    <>
                      <span className="block">Создать описание</span>
                      <MagicIcon width={22} height={20} className="relative top-[1px]" />
                    </>
                  )}
                </div>
              }
              className="text-base rounded-lg h-[57px] grow flex justify-center group"
            />
          </div>
          <div className="mt-4 text-[#858585] text-center">
            {`Символов ${phrases.reduce((prev, value) => prev + value.value.length, 0)}`}
          </div>
        </div>
      </div>
      {mode === MODES.RESULT && (
        <div
          className="w-[683px] h-fit p-6 bg-white rounded-3xl z-10"
          style={{ boxShadow: '0px 4px 40px rgba(136, 186, 172, 0.4)' }}
        >
          <div>
            <span className="block text-[#333D37] font-bold text-2xl mb-6">Описание товара</span>
            <textarea
              ref={textAreaRef}
              value={resultData || ''}
              onChange={(e) => setResultData(e.target.value)}
              className="block p-4 bg-[#F1F1F1] grow rounded-lg w-full focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-offset-0 focus:ring-green z-10"
            />
            <div
              className="mt-4 text-[#858585] flex items-center justify-center"
              onClick={() => {
                setCopyText('Описание скопировано');
                navigator.clipboard.writeText(resultData);
                setTimeout(() => setCopyText('Скопировать'), 2000);
              }}
            >
              <span className="block pr-4 border-r border-[1px solid rgba(0, 0, 0, 0.06)]">{`Символов ${resultData.length}`}</span>
              <div className="flex ml-4 cursor-pointer text-[#6E6E6E] hover:!text-green items-center justify-center">
                <CopyIcon width={18} height={18} />
                <span className="block ml-2">{copyText}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SmartDescriptionWords.propTypes = {
  mode: PropTypes.string,
  setMode: PropTypes.func,
  phrasesURL: PropTypes.string,
};

export default SmartDescriptionContent;
