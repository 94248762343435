import AuthorizationForm from 'components/AuthorizationForm';
import { IS_PRODUCTION } from 'constants/environment';
import { useAuth } from 'context/auth.context';
import gtag from 'ga-gtag';
import { useState } from 'react';
import { useSearchParams } from 'react-router';
import ym from 'react-yandex-metrika';

const initialValues = {
  password: '',
  rePassword: '',
  name: '',
  email: '',
  agree: false,
};

const SignUpFinish = () => {
  const { register, tmpToken, setStep } = useAuth();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const isDemo = searchParams.get('demo-message');

  const handleSubmit = (values) => {
    if (IS_PRODUCTION) {
      gtag('event', 'click_button', {
        button: 'registration',
      });
      ym('reachGoal', 'click_registration');
    }
    return register(
      {
        email: values.email,
        name: values.name,
        password: values.password,
      },
      tmpToken,
    )
      .then((data) => {
        if (data.error) {
          const firstErrorKey = data.error?.[Object.keys(data.error)?.[0]]?.[0];
          setError(firstErrorKey || data?.message || 'Что пошло не так. Попробуйте позже ещё раз.');
        } else {
          if (IS_PRODUCTION) {
            gtag('event', 'user_registration', {
              registration: 'succes',
            });
            ym('reachGoal', 'registration_succes');
          }
          data.success = true;
          setError(null);
          setStep(3);
        }
        return data;
      })
      .catch((e) => {
        const { error, message } = e?.response?.data || {};
        const firstErrorKey = error?.[Object.keys(error)?.[0]]?.[0];
        setError(firstErrorKey || message || 'Что пошло не так. Попробуйте позже ещё раз.');
      });
  };

  return (
    <AuthorizationForm
      showNavigation={false}
      title="Последний шаг"
      fields={[
        {
          name: 'name',
          label: 'Представьтесь',
          type: 'text',
        },
        {
          name: 'email',
          label: 'E-mail',
          type: 'text',
        },
        {
          name: 'password',
          label: 'Пароль',
          type: 'password',
          checkStrength: true,
        },
        {
          name: 'rePassword',
          label: 'Повторить',
          type: 'password',
        },
        {
          name: 'agree',
          label: (
            <a
              href="https://www.wecheck.ru/agreement"
              target="_blank"
              rel="noreferrer"
              className="text-green underline"
            >
              Принимаю условия публичной оферты
            </a>
          ),
          type: 'checkbox',
        },
      ]}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      submitLabel="Регистрация"
      messageText={
        isDemo ? (
          <div className="-mt-4 mb-8 text-gray-500 text-left md:flex md:-mx-36">
            Для доступа к демоданным, вам необходимо зарегистрироваться
          </div>
        ) : undefined
      }
      // successMessage="Для завершения регистрации, пожалуйста, перейдите по ссылке, которую мы отправили вам на e-mail."
      superError={error}
    />
  );
};

export default SignUpFinish;
