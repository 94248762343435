import Arrow from 'assets/icons/Arrow';
import PopoverArrowIcon from 'assets/icons/PopoverArrowIcon';
import cn from 'classnames';
import { TABLE_INNER_TYPE } from 'constants';
import { useAnalyticType } from 'context/analytic.type.context';
import { useUI } from 'context/ui.context';
import useClickOutside from 'hooks/useClickOutside';
import {
  useFetchFilteredInnerData,
  useFetchFilteredInnerDataPaginated,
} from 'hooks/useFetchFilteredInnerData';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useParams } from 'react-router';
import { PAGE_INNER_PRODUCT } from '../../../constants';

const ProductSizeLabel = ({ barcode: productBarcode }) => {
  const { id } = useParams();
  const { isInner } = useAnalyticType();
  const [showSizeList, setShowSizeList] = useState(false);
  const { data: productInnerSalesData } = useFetchFilteredInnerData({
    type: TABLE_INNER_TYPE.PRODUCT,
    data: { id },
    enabled: !!id && isInner,
  });
  const { isMobile } = useUI();
  const ref = useRef(null);
  const { data: sizesData = {} } = useFetchFilteredInnerDataPaginated({
    type: TABLE_INNER_TYPE.PRODUCT_SIZES,
    data: {
      id,
    },
    enabled: isInner,
  });
  useClickOutside(ref, () => setShowSizeList(false));
  const size = productInnerSalesData?.size;
  if (!isInner || !size || size === '0') {
    return null;
  }
  const { pages } = sizesData;
  const sizes = pages?.[0] || [];
  const hasSizes = sizes.length !== 0;

  const handleOpenProduct = (barcode) => () => {
    window.location.pathname = `${PAGE_INNER_PRODUCT}/${id}/${barcode}`;
    setShowSizeList(false);
  };

  return (
    <div className="relative" ref={ref}>
      <button
        onClick={() => setShowSizeList(!showSizeList)}
        className={cn(
          'block transition-colors border border-gray leading-none py-2 px-3 font-normal text-sm rounded-full flex items-center min-w-max relative group',
        )}
      >
        {size && (
          <>
            {id !== productBarcode && <span className="mr-2">Размер</span>}
            <span className={cn('transition-colors text-black')}>
              {id === productBarcode
                ? 'Все размеры'
                : sizes.find((el) => el.barcode === productBarcode)?.size}
            </span>
            {hasSizes && (
              <Arrow
                strokeWidth={1}
                className={cn('ml-2 transition-all', {
                  ['rotate-[270deg]']: showSizeList,
                  ['rotate-90']: !showSizeList,
                })}
              />
            )}
            {showSizeList && (
              <>
                {isMobile && (
                  <div className="fixed w-full h-full z-38 bg-black opacity-25 left-0" />
                )}
                <div
                  className={cn(
                    'bottom-0 fixed w-full left-0 bg-white z-40 lg:bottom-unset lg:absolute lg:top-10 lg:right-0 lg:pb-0 lg:rounded lg:left-auto lg:bottom-auto lg:w-[350px]',
                  )}
                  style={{
                    filter:
                      'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1))',
                    left: -100,
                  }}
                >
                  {!isMobile && (
                    <PopoverArrowIcon
                      className="absolute inset-x-2/4 -top-2.5"
                      style={{ transform: 'translate(-50%, -50%)', top: '-3px' }}
                    />
                  )}
                  <div className="pt-4 px-4">
                    <div className="flex gap-2 border-b pb-4">
                      <div className="flex flex-1 gap-2 items-center lg:max-w-40">Размер</div>
                      <div className="flex flex-1 max-w-24">Остатки</div>
                      <div className="">Заказы</div>
                    </div>
                  </div>
                  {[
                    {
                      size: 'Сумма всех размеров',
                      barcode: id,
                      ...sizes.reduce(
                        (prev, el) => {
                          return {
                            size_orders_rub: prev.size_orders_rub + +el.size_orders_rub,
                            size_stock: prev.size_stock + +el.size_stock,
                          };
                        },
                        { size_orders_rub: 0, size_stock: 0 },
                      ),
                    },
                    ...sizes
                      .sort((a, b) => Number(b.size_orders_rub) - Number(a.size_orders_rub))
                      .sort((a) => (a.barcode === productBarcode ? -1 : 1)),
                  ].map(({ barcode, size_orders_rub, size_stock, size }) => {
                    return (
                      <div
                        key={barcode}
                        className="flex gap-2 py-2 px-4 hover:text-green hover:bg-green-200 cursor-pointer"
                        onClick={handleOpenProduct(barcode)}
                      >
                        <div
                          className={cn(' flex items-center flex-1 lg:flex-none lg:w-[150px] ', {
                            'font-bold': barcode === productBarcode,
                          })}
                        >
                          <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                            {size}
                          </div>
                          {isMobile && <Arrow className="ml-2" strokeWidth={1} />}
                        </div>
                        <div className="text-right w-20">
                          {' '}
                          {size_stock ? `${Number(size_stock).toLocaleString('ru')} шт` : '-'}
                        </div>
                        <div className="text-right w-32">
                          {' '}
                          {typeof size_orders_rub === 'number'
                            ? `${size_orders_rub.toLocaleString('ru')} ₽`
                            : '-'}
                        </div>
                      </div>
                    );
                  })}
                  {isMobile && <button className="button-primary w-full">Закрыть</button>}
                </div>
              </>
            )}
          </>
        )}
      </button>
    </div>
  );
};

ProductSizeLabel.propTypes = {
  barcode: PropTypes.string,
};

export { ProductSizeLabel };
