import { useQuery } from 'react-query';

import { QUERY_TYPE, STALE_TIME } from 'constants/query';
import apiClient from 'services/baseClient';

const action = {
  [QUERY_TYPE.SEARCH_SELLERS]: (props) => apiClient.getSellersNames(props),
  [QUERY_TYPE.SEARCH_BRANDS]: (props) => apiClient.getBrandsNames(props),
  [QUERY_TYPE.SEARCH_SUBJECTS]: (props) => apiClient.getSubjectsNames(props),
};

export const useFetchNamesData = ({ type, ids }) => {
  const queryKey = [type, ids];

  return useQuery({
    queryKey,
    queryFn: () =>
      action[type]({
        filter: { ids },
      }),
    refetchOnmount: false,
    enabled: ids?.length > 0,
    staleTime: STALE_TIME,
  });
};
