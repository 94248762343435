import PropTypes from 'prop-types';

import { TABLE_CELL__CUSTOM_DATA_FIELDS } from 'constants/table';
import { useChartDoughnut } from 'context/chart.doughnut.context';

const TableCell = ({ data }) => {
  const { setActiveIndex, removeActiveIndex, getColorByIndex } = useChartDoughnut();

  const index = data[TABLE_CELL__CUSTOM_DATA_FIELDS.INDEX];
  const bgColor = getColorByIndex(index);

  const eventHandlers = {
    onMouseEnter: () => setActiveIndex(index),
    onMouseLeave: () => removeActiveIndex,
  };

  return (
    <span {...eventHandlers} className="flex justify-center w-full h-full items-center gap-3">
      <div className="relative">
        <div className="w-2 h-2 rounded-full flex-none" style={{ background: bgColor }} />
        <div
          className="w-2 h-2 rounded-full flex-none absolute top-0"
          style={{ border: `1px solid rgba(0, 0, 0, 0.1)` }}
        />
      </div>
    </span>
  );
};
TableCell.propTypes = {
  data: PropTypes.object,
  link: PropTypes.string,
  value: PropTypes.string,
};

export const TableCellProductCircleVariation = ({ data, value }) => (
  <TableCell data={data} value={value} />
);

TableCellProductCircleVariation.propTypes = {
  data: PropTypes.object,
  value: PropTypes.string,
};
