import { ReactComponent as LogoImage } from 'assets/images/logoWithoutText.svg';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router';

const SuccessPaymentLayout = ({ title, linkText, link }) => (
  <div
    className="flex w-full h-full items-center justify-center"
    style={{ height: 'calc(100vh - 80px)' }}
  >
    <div className="flex flex-col items-center justify-center">
      <LogoImage />
      <h1 className="text-center text-md mt-7 text-base font-normal">{title}</h1>
      <div className="text-gray-400 text-sm mt-3 text-center font-light mb-7">
        Обычно это происходит в течение нескольких часов, <br /> но иногда может занять до одного
        рабочего дня.
      </div>
      <button className="button-primary">
        <NavLink to={link} className="py-2 px-4">
          {linkText}
        </NavLink>
      </button>
    </div>
  </div>
);

SuccessPaymentLayout.propTypes = {
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  title: PropTypes.element.isRequired,
};

export default SuccessPaymentLayout;
