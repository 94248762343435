import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Alert from '../../../components/Alert';
import ButtonPrimary from '../../../components/ButtonPrimary';
import InputList from '../../../components/InputList';
import Popover from '../../../components/Popover';
import { useAuth } from '../../../context/auth.context';
import { changeStateScopeValueByIndex, changeStateValueByIndex } from '../../../helpers/utils';
import { MODES } from './utils';

const initialValues = (length) => {
  const values = [];

  for (let i = 0; i < length; i++) {
    values.push('');
  }

  return values;
};

const PHRASES_LIMIT = 4;
const PHRASES_MAX_LIMIT = 100;

const ClustersByGroupInput = ({ setMode }) => {
  const [phrases, setPhrases] = useState(initialValues(PHRASES_LIMIT));
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { noTariff } = useAuth();
  const tooltipNoTariffRef = useRef();

  const modifyPaste = (e, index, limit, word) => {
    e.preventDefault();

    const text = e.clipboardData.getData('text');
    const values = text.split('\n');

    if (index + values.length > limit) {
      setError(`Можно добавлять не более ${limit} ${word}`);
    }

    return values;
  };
  const handleChangePhrases = (i) => (e) => {
    setPhrases((prev) => changeStateValueByIndex(prev, i, e.target.value));
  };

  const handlePastePhrases = (i) => (e) => {
    const values = modifyPaste(e, i, PHRASES_MAX_LIMIT, 'фраз');

    setPhrases((prev) => changeStateScopeValueByIndex(prev, i, values, PHRASES_MAX_LIMIT));
  };

  const handleClear = async () => {
    setPhrases(initialValues(PHRASES_LIMIT));
  };

  const getValues = () => {
    return phrases.filter((value) => value);
  };

  let values = getValues();

  const handleSubmit = () => {
    let params = new URLSearchParams(values.map((s) => ['search[]', s]));

    navigate({
      pathname: location.pathname,
      search: `?${params.toString()}`,
    });
    setMode(MODES.RESULT);
  };

  const getError = () => {
    if (!error) return [];

    return [
      {
        key: 'products-limit',
        message: error,
        type: 'error',
      },
    ];
  };

  const removeError = () => setError(null);

  return (
    <div className=" max-w-[800px]">
      {error && <Alert hide={removeError} items={getError()} placement="top" />}
      <div className="bg-gray-100 border border-gray-200 text-gray-500 mb-4 p-4 rounded-lg">
        Введите поисковые запросы и WeCheck покажет вам все поисковые фразы, принадлежащие кластерам
        этих поисковых запросов
      </div>
      <InputList
        min={PHRASES_LIMIT}
        placeholder="Введите поисковой запрос"
        values={phrases}
        onChange={handleChangePhrases}
        onPaste={handlePastePhrases}
        max={PHRASES_MAX_LIMIT}
      />
      <div className="mt-4 flex space-x-2">
        <div className="relative w-fit">
          <div ref={tooltipNoTariffRef}>
            <ButtonPrimary
              onClick={handleSubmit}
              label="Получить отчёт"
              className="text-base"
              disabled={!values.length}
            />
            {noTariff && (
              <Popover
                tooltipRef={tooltipNoTariffRef}
                className="whitespace-nowrap"
                tooltip="Чтобы воспользоваться сервисами SEO, необходимо оплатить тарифный план"
              />
            )}
          </div>
        </div>
        <button
          className={cn(
            'text-base bg-gray-light border border-gray-250 text-red-bright rounded cursor-pointer py-1 px-4 leading-5 whitespace-nowrap transition-all hover:bg-pink-light hover:border-pink focus:bg-pink-light focus:border-pink disabled:opacity-30 disabled:bg-gray-light disabled:cursor-not-allowed',
          )}
          onClick={handleClear}
          disabled={!values.length}
        >
          Очистить все
        </button>
      </div>
    </div>
  );
};

ClustersByGroupInput.propTypes = {
  setMode: PropTypes.func,
};

export { ClustersByGroupInput };
