import CrossIcon from 'assets/icons/CrossIcon';
import LoopIcon from 'assets/icons/LoopIcon';
import cn from 'classnames';
import LoadingSpin from 'components/LoadingSpin';
import { useAnalyticType } from 'context/analytic.type.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUI } from 'context/ui.context';
import useClickOutside from 'hooks/useClickOutside';
import { useMultiSearch } from 'hooks/useSearch';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { PLATFORMS_COLOR } from './constants';
import SearchCriteria from './SearchCriteria';
import { SearchedBrandSeller, SearchedInnerProduct, SearchedProduct } from './SearchedRow';
import { SearchInputPlatform } from './SearchInputPlatform';

const getResultComponent = (criteria, isInner) => {
  if (isInner) {
    return SearchedInnerProduct;
  }
  switch (criteria) {
    case 'sellers':
    case 'brands': {
      return (props) => SearchedBrandSeller({ ...props, isBrand: criteria === 'brands' });
    }
    default:
      return SearchedProduct;
  }
};

const SearchInput = ({ isExpanded, setIsExpanded }) => {
  const { filter } = useFilterReducer();
  const {
    onChange,
    value,
    criteria,
    setCriteria,
    setValue,
    invalidMessage,
    query: { data = {}, isLoading, isFetched },
  } = useMultiSearch();
  const { isInner } = useAnalyticType();
  const ref = useRef();
  const inputRef = useRef();
  const containerRef = useRef();
  const [showSearch, setShowSearch] = useState(true);
  const { isMobile } = useUI();
  const { all_data = [] } = data;
  const resultData = Array.isArray(data) ? data : all_data;
  const selectedPlatform = filter?.platform;
  const platformColor = PLATFORMS_COLOR[selectedPlatform] ?? null;
  useClickOutside(ref, () => setShowSearch(false));
  useClickOutside(containerRef, () => {
    setIsExpanded(false);
    setShowSearch(false);
    setValue('');
  });

  useEffect(() => {
    setShowSearch(true);
  }, [criteria, value, selectedPlatform]);

  useEffect(() => {
    if (isExpanded) {
      inputRef.current.focus();
    }
  }, [isExpanded]);

  const handleExpand = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };
  const getPlaceholder = () => {
    if (isMobile) return 'Введите значение';
    switch (criteria) {
      case 'products': {
        return 'Введите артикул товара или ссылку';
      }
      case 'sellers': {
        return 'Введите название продавца или ссылку';
      }
      case 'brands': {
        return 'Введите название бренда или ссылку';
      }
      case 'external_id': {
        return 'Введите артикул';
      }
      case 'barcode': {
        return 'Введите баркод';
      }
      default:
        return '';
    }
  };

  const loadingCloseBlock = (
    <div className="flex">
      {isLoading && (
        <div className="w-fit">
          <LoadingSpin iconClassNames="!w-4 !h-4" />
        </div>
      )}
      <button
        className="ml-3 text-gray-250 p-1.5"
        onClick={() => {
          setValue('');
          setIsExpanded(false);
        }}
      >
        <CrossIcon width={16} height={16} />
      </button>
    </div>
  );
  const searchParams = !isInner ? (
    <>
      <SearchInputPlatform setValue={setValue} />
      <span className="text-gray-500 relative   px-1 block">по</span>
      <SearchCriteria criteria={criteria} setCriteria={setCriteria} setValue={setValue} />
      <span className="text-gray-500 relative   px-1 block">:</span>
    </>
  ) : (
    <>
      <span className="text-gray-500 relative  px-1 block">Поиск по</span>
      <SearchCriteria criteria={criteria} setCriteria={setCriteria} setValue={setValue} />
      <span className="text-gray-500 relative   px-1 block">:</span>
    </>
  );
  const loopBlock = (
    <>
      <LoopIcon width={15} height={15} className={`text-${platformColor} mb-0.5`} />
      <span className="block pl-2 text-gray-500 relative   pr-1">
        {isExpanded ? (isInner ? '' : 'Поиск на') : 'Поиск ...'}
      </span>
    </>
  );
  const searchInput = (
    <input
      ref={inputRef}
      placeholder={getPlaceholder()}
      className={cn(
        'grow pt-0.5 text-base text-black focus:outline-none pl-1  placeholder:text-gray-300 font-normal',
        {
          ['placeholder:font-light text-base placeholder:text-gray-250 bg-gray-50']: isMobile,
        },
      )}
      value={value}
      onChange={onChange}
      onFocus={() => setShowSearch(true)}
    />
  );
  const results = (
    <div>
      {resultData.length === 0 || invalidMessage ? (
        <div className="py-4">
          <span className="block font-bold text-sm mb-1">
            {invalidMessage?.title || 'Ничего не найдено'}
          </span>
          {invalidMessage ? (
            invalidMessage.content.split('|').map((el) => (
              <span key={el} className="block font-normal text-sm text-gray-400">
                {el}
              </span>
            ))
          ) : (
            <span className="block font-normal text-sm text-gray-400">{'Ничего не найдено'}</span>
          )}
        </div>
      ) : (
        <div className="divide-y">
          {resultData.map((el) => {
            const Component = getResultComponent(criteria, isInner);
            return <Component key={el.key} el={el} platform={selectedPlatform} />;
          })}
        </div>
      )}
    </div>
  );

  if (isMobile) {
    return (
      <div className="bg-gray-50 grow">
        <div className="px-4 pb-4 border-b border-gray-150">
          <div className="flex pt-4 justify-between pb-0.5">
            <div className="flex pt-1 pl-1">
              {loopBlock}
              {searchParams}
            </div>
            {loadingCloseBlock}
          </div>
          <div className="flex pt-5 w-full pl-1">{searchInput}</div>
        </div>
        <div className="px-4">{(isFetched || invalidMessage) && results}</div>
      </div>
    );
  }
  return (
    <div
      ref={containerRef}
      className={cn(' flex relative', {
        'py-4 justify-end': !isExpanded,
        'py-3 ml-4 grow': isExpanded,
      })}
    >
      <div
        className={cn('border rounded border-gray-230 flex items-center px-4', {
          'lg:grow cursor-default': isExpanded,
          'cursor-pointer': !isExpanded,
        })}
        onClick={handleExpand}
      >
        {loopBlock}
        {isExpanded && (
          <>
            {searchParams}
            {searchInput}
            {loadingCloseBlock}
            {(isFetched || invalidMessage) && showSearch && (
              <div
                className="absolute top-18 -left-1 w-full px-6 bg-white shadow-popup max-h-72 overflow-y-auto"
                ref={ref}
              >
                {results}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
};

SearchInput.defaultProps = {
  isExpanded: true,
};

export default SearchInput;
