import { PAGE_PROFILE_SUBSCRIPTIONS } from 'constants/pages';
import { useAuth } from 'context/auth.context';
import { NavLink } from 'react-router';

const Notification = () => {
  const { tariff } = useAuth();

  if (tariff && tariff.project_name !== 'Расширение WB') {
    return <></>;
  }

  return (
    <div className="text-center text-gray-700 leading-4 bg-yellow py-3 px-2 text-[13px] header__notification mt-0">
      {tariff
        ? `У вас активирован тариф для расширения. Вам доступны тестовые данные для ознакомления с платформой. Для полноценного доступа, пожалуйста оплатите тариф.`
        : `Вам доступны тестовые данные для ознакомления с платформой. Для полноценного доступа,
      пожалуйста оплатите тариф`}
      <NavLink
        to={PAGE_PROFILE_SUBSCRIPTIONS}
        className="ml-3 pl-3 border-l border-gray-250 text-green hover:underline"
      >
        Оплатить
      </NavLink>
    </div>
  );
};

export default Notification;
