import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import ButtonPrimary from '../../../components/ButtonPrimary';
import Popover from '../../../components/Popover';
import { useAuth } from '../../../context/auth.context';
import { setSearchParams } from './utils';

const ClustersByArticleInput = ({ setMode }) => {
  const { noTariff } = useAuth();
  const tooltipNoTariffRef = useRef();
  const [phrase, setPhrase] = useState('');
  const navigate = useNavigate();
  const handleSubmit = () => {
    setSearchParams(phrase, navigate, setMode);
  };

  const handleClear = () => {
    setPhrase('');
  };
  return (
    <div>
      <div className="bg-gray-100 border border-gray-200 text-gray-500 mb-4 p-4 rounded-lg max-w-[800px]">
        Введите артикул товара и WeCheck покажет вам все поисковые фразы по которым был найден товар
        и их кластер
      </div>
      <input
        value={phrase || ''}
        onChange={(e) => setPhrase(e.target.value)}
        type="text"
        autoFocus={true}
        placeholder={'Введите Артикул...'}
        className="block p-4 border border-[#D9D9D9] rounded-lg w-[420px] placeholder:italic placeholder:text-gray-300 focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-offset-0 focus:ring-green z-10"
      />
      <div className="mt-4 flex space-x-2">
        <div className="relative w-fit">
          <div ref={tooltipNoTariffRef}>
            <ButtonPrimary
              onClick={handleSubmit}
              label="Получить отчёт"
              className="text-base"
              disabled={!phrase || noTariff}
            />
            {noTariff && (
              <Popover
                tooltipRef={tooltipNoTariffRef}
                className="whitespace-nowrap"
                tooltip="Чтобы воспользоваться сервисами SEO, необходимо оплатить тарифный план"
              />
            )}
          </div>
        </div>
        <button
          className={
            'text-base bg-gray-light border border-gray-250 text-red-bright rounded cursor-pointer py-1 px-4  whitespace-nowrap transition-all hover:bg-pink-light hover:border-pink focus:bg-pink-light focus:border-pink disabled:opacity-30 disabled:bg-gray-light disabled:cursor-not-allowed'
          }
          onClick={handleClear}
          disabled={!phrase || noTariff}
        >
          Очистить
        </button>
      </div>
    </div>
  );
};
ClustersByArticleInput.propTypes = {
  setMode: PropTypes.func,
};

export { ClustersByArticleInput };
