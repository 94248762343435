import LoadingSpin from 'components/LoadingSpin';
import { TABLE_INNER_TYPE, TABLE_TYPE } from 'constants';
import { useFilterForExportData } from 'hooks/useFetchFilteredData';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import baseClient from '../../services/baseClient';

const TableExportContent = ({ format, type, onHide }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userReportId, setUserReportId] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const filters = useFilterForExportData({ type });

  // Ref to store the interval ID
  const intervalRef = useRef(null);

  // Function to generate the report
  const generateReport = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);
    const params = { format, type, filters };
    try {
      const resp = await baseClient.generateUserReport(params);
      const { id } = resp || {};
      if (id) {
        setUserReportId(id); // Set the report ID
      }
    } catch (error) {
      setUserReportId(null);
      console.error('Error generating report:', error);
    } finally {
      setIsLoading(false);
    }
  }, [format, type, filters, isLoading]);

  // Run the report generation on component mount (or when format/type/filters change)
  useEffect(() => {
    if (!userReportId) {
      generateReport();
    }
  }, [generateReport, userReportId]);

  // Function to ping the server to check for the report
  const pingUserReport = useCallback(async () => {
    if (!userReportId) return;
    if (isLoading) return;
    setIsLoading(true);
    try {
      const data = await baseClient.getUserReport({ id: userReportId });
      const { status, file_url } = data || {};
      if (status === 1 && file_url) {
        setFileUrl(file_url);
        clearPollingInterval();
      }
      if (status === 2) {
        // if status - error, don't ping
        clearPollingInterval();
      }
    } catch (error) {
      console.error('Error checking report status:', error);
    } finally {
      setIsLoading(false);
    }
  }, [userReportId, isLoading]);

  // Set up interval to check report every 5 seconds
  useEffect(() => {
    if (userReportId && !fileUrl) {
      intervalRef.current = setInterval(() => {
        pingUserReport();
      }, 5000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [userReportId, fileUrl, pingUserReport]);

  const clearPollingInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if (fileUrl) {
      window.open(fileUrl, '_blank');
    }
  }, [fileUrl, onHide]);

  return (
    <div className="text-center">
      {fileUrl ? (
        <div className="mt-5">
          <span>
            Загрузка начинается автоматически, если такого не произошло, проверьте всплывающее окно
            или нажмите <a href={fileUrl}>сюда</a>
          </span>
        </div>
      ) : (
        <div>
          <LoadingSpin />
          <div className="mt-5">
            <div className="mt-5">Не закрывайте окно пока генерируется отчет</div>
          </div>
        </div>
      )}
    </div>
  );
};

TableExportContent.propTypes = {
  format: PropTypes.oneOf(['xlsx', 'csv']),
  type: PropTypes.oneOf([...Object.values(TABLE_TYPE), ...Object.values(TABLE_INNER_TYPE)])
    .isRequired,
  onHide: PropTypes.func,
};

export default TableExportContent;
