import PropTypes from 'prop-types';

import ProfileTabs from 'components/ProfileTabs';

const ProfileLayout = ({ children }) => (
  <div className="pb-4">
    <div className="px-3 lg:px-8">
      <ProfileTabs />
    </div>
    {children}
  </div>
);

ProfileLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default ProfileLayout;
