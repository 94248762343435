import PropTypes from 'prop-types';
import { lazy, useEffect } from 'react';

import { COLUMNS, TABLE_INNER_TYPE, TABLE_TYPE } from 'constants/table';
import { generateGridColumns, generateGridHierarchyColumns } from 'helpers/generateGridColumns';
import { generateGridRowData } from 'helpers/generateGridRowData';

import TableFooter from 'components/TableFooter';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useTable } from 'context/table.context';
import useStickyTable from 'hooks/useStickyTable';

const TableGrid = lazy(() => import('components/TableGrid'));

const Table = ({
  data,
  dataKey,
  type,
  additionalData,
  categoriesData,
  autoSize,
  autoSizeColumns,
  paginationOnBack,
  hideSoldColumn,
  isSellerCellClickable,
  isLoading,
  noRowsText,
  onRowSelected,
  typeNested,
  nested,
  hideFilterPanel,
  autoSizeNestedColumns,
  nestedCheckbox,
  footerExtra,
  footerDescription,
  hidePagination,
  asyncGetDetailRowData,
  showSidebar,
  useFooter,
  totalPages,
  paginationData,
  getRowHeight,
}) => {
  const { tableRef, isTableNeedHideFilter, setTableDataLoading } = useTable();
  const isFilterHidden = isTableNeedHideFilter(type);
  const {
    filter: { period },
  } = useFilterReducer();

  useStickyTable();

  // иногда данные приходят с разделением на периоды
  // например для таблиц growth темпы роста
  if (data?.[period]) {
    data = data?.[period];
  }

  if (data?.pages) {
    data = data?.pages?.reduce((acc, current) => {
      let currentData = [];

      // данные должны быть массивом
      if (Array.isArray(current)) {
        currentData = current;
        // иногда данные приходят с разделением на периоды
        // например для таблиц growth темпы роста
      } else if (current?.[period] && Array.isArray(current?.[period])) {
        currentData = current?.[period];
      }
      return [...acc, ...currentData];
    }, []);
  }

  let columnDefs = generateGridColumns({
    type,
    data,
    hideSoldColumn,
    isSellerCellClickable,
    showFilterHiddenTooltip: isFilterHidden,
  });

  const columnDefsNested = generateGridColumns({
    type: typeNested,
    data,
  });
  let transformedData = {};

  if (data && !isLoading) {
    transformedData = generateGridRowData({
      data,
      type,
      additionalData,
      columnDefs,
      categoriesData,
      nested,
    });
  }
  const hierarchyColumn = generateGridHierarchyColumns({
    type,
  });

  const rowData = dataKey
    ? transformedData?.rowData?.[dataKey] || []
    : transformedData?.rowData || [];

  columnDefs = dataKey ? columnDefs?.[dataKey] || [] : columnDefs;

  useEffect(() => {
    setTableDataLoading(isLoading);
  }, [setTableDataLoading, isLoading]);

  return (
    <>
      <div className="flex flex-auto flex-col" ref={tableRef}>
        <div id={`myGrid${type}`} className="ag-theme-alpine font-sans flex-auto">
          <TableGrid
            columnDefs={columnDefs}
            rowData={rowData}
            pinnedTopRowData={transformedData?.pinnedTopRowData}
            hierarchyColumn={hierarchyColumn}
            isSellerCellClickable={isSellerCellClickable}
            type={type}
            autoSize={autoSize}
            autoSizeColumns={autoSizeColumns}
            autoSizeNestedColumns={autoSizeNestedColumns}
            noRowsText={noRowsText}
            onRowSelected={onRowSelected}
            isLoading={isLoading}
            nested={nested}
            columnDefsNested={columnDefsNested}
            hideFilterPanel={hideFilterPanel}
            showFilterHiddenTooltip={isFilterHidden}
            asyncGetDetailRowData={asyncGetDetailRowData}
            showSidebar={showSidebar}
            getRowHeight={getRowHeight}
          />
        </div>
      </div>
      {useFooter && (
        <TableFooter
          type={type}
          paginationOnBack={paginationOnBack}
          nestedCheckbox={nestedCheckbox}
          extra={footerExtra}
          description={footerDescription}
          isLoading={isLoading}
          hidePagination={hidePagination}
          totalPages={totalPages}
          paginationData={paginationData}
        />
      )}
    </>
  );
};

Table.defaultProps = {
  data: { pages: [] },
  additionalData: null,
  error: '',
  categoriesData: [],
  autoSize: false,
  pagination: false,
  hideSoldColumn: false,
  isSellerCellClickable: true,
  autoSizeColumns: null,
  getRowHeight: null,
  noRowsText: null,
  hideFilterPanel: false,
  nestedCheckbox: false,
  paginationOnBack: false,
  showSidebar: true,
  useFooter: true,
};

Table.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      pages: PropTypes.array,
    }),
  ]).isRequired,
  dataKey: PropTypes.string,
  additionalData: PropTypes.object,
  type: PropTypes.oneOf([...Object.values(TABLE_TYPE), ...Object.values(TABLE_INNER_TYPE)])
    .isRequired,
  typeNested: PropTypes.oneOf(Object.values(TABLE_TYPE)),
  categoriesData: PropTypes.array,
  autoSize: PropTypes.bool,
  autoSizeColumns: PropTypes.arrayOf(PropTypes.oneOf(Object.values(COLUMNS))),
  autoSizeNestedColumns: PropTypes.arrayOf(PropTypes.string),
  paginationOnBack: PropTypes.bool,
  hideSoldColumn: PropTypes.bool,
  hideFilterPanel: PropTypes.bool,
  isSellerCellClickable: PropTypes.bool,
  isLoading: PropTypes.bool,
  totalPages: PropTypes.number,
  noRowsText: PropTypes.string,
  onRowSelected: PropTypes.func,
  getRowHeight: PropTypes.func,
  nested: PropTypes.string,
  nestedCheckbox: PropTypes.bool,
  nestedParentColumn: PropTypes.string,
  footerExtra: PropTypes.element,
  footerDescription: PropTypes.element,
  hidePagination: PropTypes.bool,
  asyncGetDetailRowData: PropTypes.func,
  showSidebar: PropTypes.bool,
  useFooter: PropTypes.bool,
  paginationData: PropTypes.any,
};

export default Table;
