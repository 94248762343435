import cn from 'classnames';

const BlockStats = (props) => {
  const { label, value, progress, extra, className, isUp = false } = props || {};
  return (
    <div className={cn('border border-solid border-gray-200 rounded-xl bg-white', className)}>
      <div className="flex w-full justify-between text-left px-3 mt-3 mb-3 group">
        <span className="text-gray-400 block font-medium text-sm">{label}</span>
        {extra && <div className="flex items-center min-w-max">{extra}</div>}
      </div>
      <div className="px-3 pb-3 flex items-center space-x-2">
        <div className="text-2xl leading-9">{value}</div>
        {progress && (
          <div
            className={cn(
              isUp ? 'bg-green-100 text-green' : 'bg-orange-100 text-orange-400',
              'text-base px-3 py-2 rounded-lg leading-5',
            )}
          >
            {progress}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlockStats;
