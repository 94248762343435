import { useAuth } from 'context/auth.context';

import Dropdown from 'components/Dropdown';
import { MobileContent } from 'components/SearchInput/MobileContent';
import { MobileOpener } from 'components/SearchInput/MobileOpener';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { /*PLATFORM_OZON,*/ PLATFORM_WB } from 'context/filter/filter.context.reducer.methods';
import { useUI } from 'context/ui.context';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { PLATFORMS, PLATFORMS_COLOR } from './constants';

const PLATFORMS_LABEL = {
  // [PLATFORM_OZON]: 'OZ',
  [PLATFORM_WB]: 'WB',
};

export const SearchInputPlatform = ({ setValue }) => {
  const { platforms } = useAuth();
  const { filter, setPlatform } = useFilterReducer();
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useUI();
  const selectedPlatform = filter?.platform;
  const [mobileSelect, setMobileSelect] = useState(selectedPlatform);
  const optionsPlatform = platforms
    .filter(({ id }) => id !== 1)
    .map((platform) => ({
      value: `${platform?.id}`,
      label: PLATFORMS[`${platform?.id}`],
    }));

  const label = PLATFORMS_LABEL[selectedPlatform];

  const platformColor = PLATFORMS_COLOR[selectedPlatform] ?? null;
  const onOpen = () => {
    setIsOpen(true);
    setMobileSelect(selectedPlatform);
  };
  if (isMobile) {
    return (
      <>
        <MobileOpener label={label} setIsOpen={onOpen} platformColor={platformColor} />
        <MobileContent
          mobileSelect={mobileSelect}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          options={optionsPlatform}
          setMobileSelect={setMobileSelect}
          handleApply={(value) => {
            setValue('');
            setPlatform(value);
            setIsOpen(false);
          }}
        />
      </>
    );
  }
  return (
    <Dropdown
      isOutlined
      label={label}
      bgColor={`${platformColor}-bright`}
      color={platformColor}
      options={optionsPlatform}
      onChange={(newPlatform) => {
        setPlatform(newPlatform);
        setValue('');
      }}
      selected={selectedPlatform}
      buttonClassnames={`bg-transparent !px-1 !py-[3px] border border-solid border-${platformColor} h-[18px] !flex`}
      labelClassnames="block text-[10px] leading-[10px] mt-0.5 font-bold"
      downIconClassnames="ml-0.5"
    />
  );
};

SearchInputPlatform.propTypes = {
  setValue: PropTypes.func,
};
