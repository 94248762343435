import ClearListIcon from 'assets/icons/ClearListIcon';
import DownIcon from 'assets/icons/DownIcon';
import EditIcon from 'assets/icons/EditIcon';
import { PlusIcon } from 'assets/icons/PlusIcon';
import TrashIcon from 'assets/icons/TrashIcon';
import cn from 'classnames';
import ListActiveTypeSelector from 'components/ListActiveTypeSelector';
import Modal from 'components/Modal';
import { useUI } from 'context/ui.context';
import { useUserList } from 'context/user.list.context';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { PAGE_BRANDS, PAGE_MAIN, PAGE_PRODUCTS, PAGE_SELLERS } from '../../constants';
import ButtonPrimary from '../ButtonPrimary';
import Chip from '../Chip';
import { MobileInterfaceV2 } from '../MobileInterface/MobileInterface';
import ActiveList from './Mobile/ActiveList';
import ListsList from './Mobile/ListsList';
import MobileTabs from './Mobile/MobileTabs';
import QuestionContent from './QuestionContent';
import { MODAL_MODES } from './constants';
// import {useFilterReducer} from "../../context/filter/filter.context.reducer";
import { useNavigate } from 'react-router';

const getResultLink = (type) => {
  switch (type) {
    case 0: {
      return PAGE_PRODUCTS;
    }
    case 1: {
      return PAGE_SELLERS;
    }
    case 2: {
      return PAGE_BRANDS;
    }
    case 3: {
      return PAGE_MAIN;
    }
  }
};

const ListsModal = ({ mode, LIST_MODE, FILTER_MODE, setMode }) => {
  const { listsByActiveType, handleListActive, selectedList, setSelectedList } = useUserList();
  // const { setFilterDefault } = useFilterReducer();
  const navigate = useNavigate();
  const { isMobile } = useUI();
  const [listIndex, setListIndex] = useState(!isMobile ? 0 : undefined);
  const [modalMode, setModalMode] = useState(MODAL_MODES.NO_SELECTED);
  const [selectedFavorite, setSelectedFavorite] = useState();

  useEffect(() => {
    if (isMobile) {
      setListIndex(listsByActiveType.findIndex((el) => el.id === selectedList?.id) || 0);
    }
  }, [listsByActiveType, setListIndex, selectedList, isMobile]);

  useEffect(() => {
    if (selectedList?.id) {
      const findedSelected = listsByActiveType.find((list) => list.id === selectedList.id);
      if (findedSelected) {
        setSelectedList(findedSelected);
      }
    }
  }, [listsByActiveType, selectedList, selectedList?.id, setSelectedList]);

  const handleCancel = () => {
    setMode(FILTER_MODE);
    setSelectedList(null);
    setModalMode(MODAL_MODES.NO_SELECTED);
  };

  const handleSubmit = () => {
    setMode(FILTER_MODE);
    if (selectedList?.id) {
      handleListActive(selectedList?.id);
      const resultLink = getResultLink(selectedList.type);
      if (window.location.pathname !== resultLink) {
        // setFilterDefault({ nextLink: resultLink, prevLink: window.location.pathname });
        navigate(resultLink);
      }
    }
  };

  const handleToListsList = () => {
    setSelectedList(null);
    setListIndex(undefined);
    setModalMode(MODAL_MODES.NO_SELECTED);
  };
  const commonSettingsButtonsClasses =
    'flex items-center pt-4 lg:pt-0 lg:mt-[15px] mb-6.5 py-[1px] group grow justify-start lg:justify-center pb-4 lg:pb-0 border-b border-[#E8E8E8] lg:border-b-0';
  const settingsButtons = (
    <>
      <button
        className={cn('text-[#EB392D]', {
          'cursor-not-allowed': listIndex === 0,
          [commonSettingsButtonsClasses]: commonSettingsButtonsClasses,
        })}
        onClick={() => setModalMode(MODAL_MODES.RENAME)}
        disabled={listIndex === 0}
      >
        <EditIcon width={12} height={12} className={'text-[#C4C4C4] group-hover:text-green'} />
        <span className="ml-2 text-gray-700 group-hover:text-green">Переименовать</span>
      </button>
      <button
        className={cn({
          [commonSettingsButtonsClasses]: commonSettingsButtonsClasses,
        })}
        onClick={() => setModalMode(MODAL_MODES.CLEAR)}
      >
        <ClearListIcon width={12} height={12} className={'text-[#C4C4C4] group-hover:text-green'} />
        <span className="ml-2 text-gray-700 group-hover:text-green whitespace-nowrap">
          Очистить список
        </span>
      </button>
      <button
        className={cn('text-[#EB392D]', {
          'cursor-not-allowed': listIndex === 0,
          [commonSettingsButtonsClasses]: commonSettingsButtonsClasses,
        })}
        onClick={() => setModalMode(MODAL_MODES.DELETE)}
        disabled={listIndex === 0}
      >
        <TrashIcon width={12} height={12} className={'group-hover:text-green'} />
        <span className="ml-2 group-hover:text-green whitespace-nowrap">Удалить список</span>
      </button>
    </>
  );
  const getContent = () => {
    switch (modalMode) {
      case MODAL_MODES.NO_SELECTED: {
        if (selectedList) {
          return (
            <ActiveList
              setModalMode={setModalMode}
              setSelectedFavorite={setSelectedFavorite}
              onBackClick={handleToListsList}
            />
          );
        }
        return <ListsList setModalMode={setModalMode} setListIndex={setListIndex} />;
      }
      case MODAL_MODES.SETTINGS: {
        return (
          <div>
            <div className="border-b border-[#E8E8E8] px-5 py-4 flex">
              <button
                className="self-center text-[#CBCBCB]"
                onClick={() => setModalMode(MODAL_MODES.NO_SELECTED)}
              >
                <DownIcon width={20} height={20} className="rotate-90" />
              </button>
              <div className="flex flex-col items-center grow leading-[18px]">
                <span>Настройки списка</span>
                <span className="text-green">{selectedList.name}</span>
              </div>
            </div>
            <div className="px-4 flex flex-col text-left">{settingsButtons}</div>
          </div>
        );
      }
      default:
        return (
          <QuestionContent
            mode={modalMode}
            setMode={setModalMode}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
            selectedFavorite={selectedFavorite}
          />
        );
    }
  };

  const getFooterContent = () => {
    switch (modalMode) {
      case MODAL_MODES.SETTINGS:
      case MODAL_MODES.NO_SELECTED: {
        if (selectedList) {
          return (
            <div
              className={`border-t border-gray-250 px-6 py-4 flex items-center justify-end gap-2 bg-[#F5F5F5]`}
            >
              <ButtonPrimary
                className="py-2 justify-center button-primary__inverted text-gray-400 border-gray-250"
                onClick={handleToListsList}
                label="Отмена"
              />
              <ButtonPrimary
                className="flex-1 py-2 justify-center button-primary grow"
                onClick={handleSubmit}
                label="Применить список"
              />
            </div>
          );
        }
        return (
          <div
            className={`border-t border-gray-250 px-6 py-4 flex items-center justify-end gap-2 bg-[#F5F5F5]`}
          >
            <ButtonPrimary
              className="flex-1 py-2 justify-center button-primary__inverted text-gray-400 border-gray-250"
              onClick={handleCancel}
              label="Закрыть"
            />
          </div>
        );
      }
    }
  };
  if (isMobile) {
    const isShowHeader =
      modalMode === MODAL_MODES.NO_SELECTED || modalMode === MODAL_MODES.SETTINGS;
    const showTabs = modalMode === MODAL_MODES.NO_SELECTED;
    return (
      <MobileInterfaceV2
        isActive={mode === LIST_MODE}
        onPanelClose={handleCancel}
        rootClassName="h-fit bottom-0 left-0 absolute"
        headerText={isShowHeader ? 'Избранное' : null}
      >
        {showTabs && <MobileTabs setModalMode={setModalMode} />}
        {getContent()}
        {getFooterContent()}
      </MobileInterfaceV2>
    );
  }
  return (
    <Modal
      isOpen={mode === LIST_MODE}
      title={<ListActiveTypeSelector setModalMode={setModalMode} />}
      closeModal={handleCancel}
      submitModal={handleSubmit}
      classNames="!p-0"
      headerClassNames="!px-5"
      width={700}
    >
      <div className="flex">
        <div className="border-r border-[#F0F0F0]">
          <div className="overflow-y-auto max-h-[423px] overflow-x-hidden pt-2.5">
            {listsByActiveType.map((list, index) => {
              const { id, name, items } = list;
              const isSelected = selectedList?.id === id;
              return (
                <button
                  key={id}
                  className={cn('flex py-4 px-5 border-r-2 lg:w-[200px] flex justify-between', {
                    'bg-green-100  border-green': isSelected,
                    'border-white': !isSelected,
                  })}
                  onClick={() => {
                    setSelectedList(list);
                    setListIndex(index);
                    setModalMode(MODAL_MODES.NO_SELECTED);
                  }}
                >
                  <span
                    className={cn('text-gray-700 text-left', {
                      '!text-green': isSelected,
                    })}
                  >
                    {name}
                  </span>
                  <span
                    className={cn('text-[#CBCBCB]', {
                      '!text-green': isSelected,
                    })}
                  >
                    {items.length}
                  </span>
                </button>
              );
            })}
          </div>
          <button
            className="flex text-green items-center py-4 px-5 mb-2.5"
            onClick={() => {
              setModalMode(MODAL_MODES.NEW);
              setSelectedList(null);
            }}
          >
            <PlusIcon width={16} height={16} />
            <span className="ml-2">Добавить список</span>
          </button>
        </div>
        <div className="flex flex-col w-full">
          {modalMode === MODAL_MODES.NO_SELECTED ? (
            <>
              <div className="overflow-y-auto max-h-[423px] pt-2.5 grow px-3 grow">
                <div className="w-full flex flex-wrap">
                  {selectedList?.items?.map((el) => (
                    <Chip
                      key={el.id}
                      title={el.name}
                      action={() => {
                        setSelectedFavorite(el);
                        setModalMode(MODAL_MODES.REMOVE_FAVORITE);
                      }}
                      color="bg-blue"
                      className="w-min ml-2 mt-2"
                    />
                  ))}
                </div>
              </div>
              {selectedList && <div className="flex w-full divide-x">{settingsButtons}</div>}
            </>
          ) : (
            <QuestionContent
              mode={modalMode}
              setMode={setModalMode}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              selectedFavorite={selectedFavorite}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

ListsModal.propTypes = {
  mode: PropTypes.string,
  LIST_MODE: PropTypes.string,
  FILTER_MODE: PropTypes.string,
  setMode: PropTypes.func,
};

export default ListsModal;
