import React, { useCallback, useEffect, useReducer } from 'react';

import { useFilterReducer } from 'context/filter/filter.context.reducer';

const FilterModalContext = React.createContext();
FilterModalContext.displayName = 'FilterModal.Context';

const LOCAL_FILTER_INITIAL_STATE = {
  touched: {
    category: false,
    brand: false,
    seller: false,
    isNew: false,
    hasAdvertising: false,
  },
  localChips: {
    category: [],
    brand: [],
    seller: [],
  },
  localFilter: {
    category: [],
    brand: [],
    seller: [],
    isNew: false,
    hasAdvertising: false,
  },
};

const ACTIONS = {
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  CLEAN_CATEGORY: 'CLEAN_CATEGORY',
  ADD_BRAND: 'ADD_BRAND',
  REMOVE_BRAND: 'REMOVE_BRAND',
  ADD_SELLER: 'ADD_SELLER',
  ADD_SUBJECT: 'ADD_SUBJECT',
  REMOVE_SUBJECT: 'REMOVE_SUBJECT',
  REMOVE_SELLER: 'REMOVE_SELLER',
  CLEAN_SELLERS: 'CLEAN_SELLERS',
  CLEAN_SUBJECTS: 'CLEAN_SUBJECTS',
  CLEAN_BRANDS: 'CLEAN_BRANDS',
  RESET_LOCAL_FILTER: 'RESET_LOCAL_FILTER',
  GLOBAL_FILTER_UPDATED: 'GLOBAL_FILTER_UPDATED',
  SET_SELLER: 'SET_SELLER',
  SET_SUBJECT: 'SET_SUBJECT',
  SET_BRAND: 'SET_BRAND,',
  SET_IS_NEW: 'SET_IS_NEW',
  SET_HAS_ADVERTISING: 'SET_HAS_ADVERTISING',
};

const filterModalReducer = (state, action) => {
  const { localFilter, localChips, touched } = state;

  switch (action.type) {
    case ACTIONS.GLOBAL_FILTER_UPDATED: {
      const { date, warehouse, platform, localChips, localFilter } = action.payload;
      return { ...state, date, warehouse, platform, localChips, localFilter };
    }

    case ACTIONS.UPDATE_CATEGORY: {
      const newNodes = action.payload || [];

      return {
        ...state,
        touched: { ...touched, category: true },
        // localFilter: { ...localFilter, category: newNodes.map((item) => item.key) },
        localFilter: { ...localFilter, category: newNodes.map((item) => item.key) },
        localChips: { ...localChips, category: newNodes },
      };
    }
    case ACTIONS.CLEAN_CATEGORY: {
      return {
        ...state,
        touched: { ...touched, category: true },
        localFilter: { ...localFilter, category: [] },
        localChips: { ...localChips, category: [] },
      };
    }

    case ACTIONS.ADD_BRAND: {
      const item = action.payload;

      return {
        ...state,
        touched: { ...touched, brand: true },
        localFilter: { ...localFilter, brand: [...localFilter.brand, item.key] },
        localChips: { ...localChips, brand: [...localChips.brand, item] },
      };
    }

    case ACTIONS.REMOVE_BRAND: {
      const item = action.payload;
      const newFilterBrands = localFilter.brand.filter((key) => key !== item.key);
      const newSelectedNodesBrands = localChips.brand.filter(({ key }) => key !== item.key);
      return {
        ...state,
        touched: { ...touched, brand: true },
        localFilter: { ...localFilter, brand: newFilterBrands },
        localChips: { ...localChips, brand: newSelectedNodesBrands },
      };
    }

    case ACTIONS.CLEAN_BRANDS: {
      return {
        ...state,
        touched: { ...touched, brand: true },
        localFilter: { ...localFilter, brand: [] },
        localChips: { ...localChips, brand: [] },
      };
    }

    case ACTIONS.ADD_SELLER: {
      const item = action.payload;
      return {
        ...state,
        touched: { ...touched, seller: true },
        localFilter: { ...localFilter, seller: [...localFilter.seller, item.key] },
        localChips: { ...localChips, seller: [...localChips.seller, item] },
      };
    }

    case ACTIONS.SET_SELLER: {
      const item = action.payload;
      return {
        ...state,
        touched: { ...touched, seller: true },
        localFilter: { ...localFilter, seller: [item.key] },
        localChips: { ...localChips, seller: [item] },
      };
    }

    case ACTIONS.SET_BRAND: {
      const item = action.payload;
      return {
        ...state,
        touched: { ...touched, brand: true },
        localFilter: { ...localFilter, brand: [item.key] },
        localChips: { ...localChips, brand: [item] },
      };
    }

    case ACTIONS.REMOVE_SELLER: {
      const item = action.payload;
      const newFilterSellers = localFilter.seller.filter((key) => key !== item.key);
      const newSelectedNodesSellers = localChips.seller.filter(({ key }) => key !== item.key);
      return {
        ...state,
        touched: { ...touched, seller: true },
        localFilter: { ...localFilter, seller: newFilterSellers },
        localChips: { ...localChips, seller: newSelectedNodesSellers },
      };
    }

    case ACTIONS.CLEAN_SELLERS: {
      return {
        ...state,
        touched: { ...touched, seller: true },
        localFilter: { ...localFilter, seller: [] },
        localChips: { ...localChips, seller: [] },
      };
    }

    case ACTIONS.CLEAN_SUBJECTS: {
      return {
        ...state,
        touched: { ...touched, subject: true },
        localFilter: { ...localFilter, subject: [] },
        localChips: { ...localChips, subject: [] },
      };
    }

    case ACTIONS.SET_IS_NEW: {
      const item = action.payload;
      return {
        ...state,
        touched: { ...touched, isNew: true },
        localFilter: { ...localFilter, isNew: item },
      };
    }

    case ACTIONS.SET_HAS_ADVERTISING: {
      const item = action.payload;
      return {
        ...state,
        touched: { ...touched, hasAdvertising: true },
        localFilter: { ...localFilter, hasAdvertising: item },
      };
    }

    case ACTIONS.RESET_LOCAL_FILTER: {
      return {
        ...LOCAL_FILTER_INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};

const FilterModalProvider = (props) => {
  const [state, dispatch] = useReducer(filterModalReducer, LOCAL_FILTER_INITIAL_STATE);
  const { filter, chips } = useFilterReducer();

  useEffect(() => {
    const { date, warehouse, platform, category, brand, subject, seller, isNew, hasAdvertising } =
      filter;
    dispatch({
      type: ACTIONS.GLOBAL_FILTER_UPDATED,
      payload: {
        date,
        warehouse,
        platform,
        localChips: chips,
        localFilter: { category, brand, seller, subject, isNew, hasAdvertising },
      },
    });
  }, [filter, chips]);

  const value = {
    state,
    updateCategoriesList: useCallback(
      (payload) => dispatch({ type: ACTIONS.UPDATE_CATEGORY, payload }),
      [dispatch],
    ),
    addBrand: useCallback((payload) => dispatch({ type: ACTIONS.ADD_BRAND, payload }), [dispatch]),
    removeBrand: useCallback(
      (payload) => dispatch({ type: ACTIONS.REMOVE_BRAND, payload }),
      [dispatch],
    ),
    addSeller: useCallback(
      (payload) => dispatch({ type: ACTIONS.ADD_SELLER, payload }),
      [dispatch],
    ),
    removeSeller: useCallback(
      (payload) => dispatch({ type: ACTIONS.REMOVE_SELLER, payload }),
      [dispatch],
    ),
    setSeller: useCallback(
      (payload) => dispatch({ type: ACTIONS.SET_SELLER, payload }),
      [dispatch],
    ),
    addSubject: useCallback(
      (payload) => dispatch({ type: ACTIONS.ADD_SUBJECT, payload }),
      [dispatch],
    ),
    removeSubject: useCallback(
      (payload) => dispatch({ type: ACTIONS.REMOVE_SUBJECT, payload }),
      [dispatch],
    ),
    setSubject: useCallback(
      (payload) => dispatch({ type: ACTIONS.SET_SUBJECT, payload }),
      [dispatch],
    ),
    setBrand: useCallback((payload) => dispatch({ type: ACTIONS.SET_BRAND, payload }), [dispatch]),
    cleanSellers: useCallback(() => dispatch({ type: ACTIONS.CLEAN_SELLERS }), [dispatch]),
    cleanSubjects: useCallback(() => dispatch({ type: ACTIONS.CLEAN_SUBJECTS }), [dispatch]),
    cleanBrands: useCallback(() => dispatch({ type: ACTIONS.CLEAN_BRANDS }), [dispatch]),
    cleanCategories: useCallback(() => dispatch({ type: ACTIONS.CLEAN_CATEGORY }), [dispatch]),
    resetLocalFilter: useCallback(() => dispatch({ type: ACTIONS.RESET_LOCAL_FILTER }), [dispatch]),
    setIsNew: useCallback((payload) => dispatch({ type: ACTIONS.SET_IS_NEW, payload }), [dispatch]),
    setHasAdvertising: useCallback(
      (payload) => dispatch({ type: ACTIONS.SET_HAS_ADVERTISING, payload }),
      [dispatch],
    ),
  };

  return <FilterModalContext.Provider {...props} value={value} />;
};

const useModalFilter = () => React.useContext(FilterModalContext);

export { FilterModalProvider, useModalFilter };
