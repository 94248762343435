import DownIcon from 'assets/icons/DownIcon';
import LinkIcon from 'assets/icons/LinkIcon';
import NoPhotoIcon from 'assets/icons/NoPhotoIcon';
import HelpModal from 'components/HelpModal';
import { useUI } from 'context/ui.context';
import { useProductLink, useSellerLink } from 'hooks/useLocationPath';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const ProductCard = ({ cardInfo, showFullInfo }) => {
  const { image, cpm, position, name, seller, page, id, external_id, listing, url } = cardInfo;
  const { isMobile } = useUI();

  const productLink = useProductLink(id);
  const sellerLink = useSellerLink(seller.id);

  return (
    <div className="flex sm:flex-col rounded-lg border border-solid border-gray-150 sm:divide-y sm:divide-gray-150 bg-white">
      <div className="relative">
        <a
          href={
            url ? url : `https://www.wildberries.ru/catalog/${external_id}/detail.aspx?targetUrl=XS`
          }
          rel="noreferrer"
          target="_blank"
          className={
            'absolute top-2 left-3 cursor-pointer border border-wb bg-wb rounded-sm text-xs leading-none px-1 py-0.5 inline-block align-middle transition-all text-white'
          }
        >
          <span className="inline-block align-middle pt-0.5 text-white">WB</span>
          <LinkIcon className="ml-1 h-2 w-2 inline-block align-middle text-white" />
        </a>

        <div
          className={`w-[140px] h-full rounded-l-lg sm:h-[19rem] sm:w-full sm:rounded-l-none sm:rounded-t-lg flex`}
        >
          {!image || image === '-' ? (
            <NoPhotoIcon className="self-center mx-auto" />
          ) : (
            <img
              src={image}
              alt={name}
              className="w-full rounded-l-lg sm:h-full sm:rounded-l-none sm:rounded-t-lg object-cover"
            />
          )}
        </div>
      </div>

      <div className="divide-y divide-gray-150 px-3 sm:px-5 pt-2 sm:pt-1 grow sm:grow-0">
        <Link
          to={productLink}
          rel="noreferrer"
          target="_blank"
          className={`block group cursor-pointer py-1.5 sm:py-2 relative`}
        >
          <div className="flex justify-between text-gray-400 group-hover:text-green">
            <span className="text-xs sm:text-sm block">Товар</span>
            <DownIcon width={14} height={14} className="-rotate-90 self-center" />
          </div>
          <div
            className={`leading-5 sm:leading-6 transition-all group-hover:text-green h-10 sm:h-12 
            font-medium text-black text-base mt-1 text-ellipsis overflow-hidden`}
          >
            {name}
          </div>
          {!isMobile && (
            <HelpModal
              title={<span>{name}</span>}
              orientation="top"
              externalClasses="opacity-100 z-[1] min-w-max -top-14 px-4 py-3 text-gray-450 shadow-sm"
            />
          )}
        </Link>
        <Link
          to={sellerLink}
          target="_blank"
          rel="noreferrer"
          className={`block group cursor-pointer py-1.5 sm:py-2`}
        >
          <div className="flex justify-between text-gray-400 transition-all group-hover:text-green">
            <span className="text-xs sm:text-sm block">Продавец</span>
            <DownIcon width={14} height={14} className="-rotate-90 self-center" />
          </div>
          <div className="transition-all group-hover:text-green text-base sm:mt-1 min-h-[2.5rem] sm:min-h-[3rem]">
            {seller.name}
          </div>
        </Link>
        {showFullInfo && (
          <div className="py-1.5 sm:py-2">
            <div className="flex justify-between text-gray-400 pb-1">
              <span className="text-xs sm:text-sm block">За 1000 показов</span>
            </div>
            <span className={'block font-semibold text-base pb-1 sm:text-2xl sm:w-52'}>
              {`${cpm.toLocaleString()} ₽`}
            </span>
          </div>
        )}
        <div className="py-1.5 sm:py-2 text-gray-400 sm:flex divide-y sm:divide-y-0 sm:divide-x divide-gray-150">
          <div className="flex justify-between items-center pb-1 sm:pb-0 sm:pr-2 grow sm:block">
            <span className="block text-xs sm:text-sm">Позиция</span>
            <span className="block w-26 text-green sm:text-black text-xl lg:pt-1.5">{`#${
              position || listing
            }`}</span>
          </div>
          {showFullInfo && (
            <div className="flex justify-between items-center pt-1 sm:pt-0 sm:pl-2 grow sm:block">
              <span className="block text-xs sm:text-sm">Страница</span>
              <span className="block w-26 text-black text-xl lg:pt-1.5">{`${page}`}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
ProductCard.propTypes = {
  cardInfo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string,
    position: PropTypes.number,
    listing: PropTypes.number,
    cpm: PropTypes.number,
    name: PropTypes.string.isRequired,
    external_id: PropTypes.number,
    page: PropTypes.number,
    seller: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      external_id: PropTypes.number,
    }),
    url: PropTypes.string,
  }).isRequired,
  showFullInfo: PropTypes.bool,
};
ProductCard.defaultProps = {
  showFullInfo: true,
};
export default ProductCard;
