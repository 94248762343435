import { useSearchParams } from 'react-router';

import { SEOSearchProvider } from 'context/seo.search.context';
import Form from 'pages/SEO/Form';
import Search from 'pages/SEO/Search';

const SEO = () => {
  const [searchParams] = useSearchParams();
  return <SEOSearchProvider>{searchParams.size ? <Search /> : <Form />}</SEOSearchProvider>;
};

export default SEO;
