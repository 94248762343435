/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { Navigate } from 'react-router';

import CardListInner from 'components/CardListInner';
import Layout from 'components/Layout/Layout';
import TableLayout from 'components/Layout/TableLayout';
import LoadingSpin from 'components/LoadingSpin';
import { TableHeaderInner } from 'components/TableHeader';
import { PAGE_API_KEYS } from 'constants';
import { FETCH_INNER } from 'constants/inner';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useFetchInnerData } from 'hooks/useFetchInnerData';
import { useMainLinkDefault } from 'hooks/useLocationPath';
import EmptyKeys from 'pages/Inner/EmptyKeys';
import { isInnerProductPage } from 'utils/pages';

const TableInnerLayout = ({ children }) => {
  const { isInnerAnalyticsAvailable } = useUserTariffs();
  const mainLink = useMainLinkDefault();

  const {
    data: apiKeys = [],
    isLoading,
    isFetched,
  } = useFetchInnerData({
    type: FETCH_INNER.API_KEYS,
    enabled: isInnerAnalyticsAvailable,
  });

  // redirect on main page is inner analytics is not available for user
  if (!isInnerAnalyticsAvailable) {
    return <Navigate to={mainLink} />;
  }

  if (isFetched && apiKeys.length === 0) {
    return <Navigate to={PAGE_API_KEYS} />;
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <LoadingSpin />
      </div>
    );
  }

  if (apiKeys.length === 0) {
    return <EmptyKeys />;
  }
  if (isInnerProductPage()) {
    return <Layout>{children}</Layout>;
  }
  return (
    <TableLayout isPeriodControlsShown={false} isDateControlsShown={true}>
      <CardListInner />

      <TableHeaderInner />

      <Layout>{children}</Layout>
    </TableLayout>
  );
};

TableInnerLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default TableInnerLayout;
