import CheckIcon from 'assets/icons/CheckIcon';
import AuthorizationForm from 'components/AuthorizationForm';
import { IS_PRODUCTION } from 'constants/environment';
import { useAuth } from 'context/auth.context';
import gtag from 'ga-gtag';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router';
import ym from 'react-yandex-metrika';

const LoginSubmitForm = () => {
  const navigate = useNavigate();
  const { login, username } = useAuth();
  const [error, setError] = useState(null);

  const handleSubmit = async (values) => {
    return new Promise((resolve, reject) => {
      login({ ...values, username })
        .then((data) => {
          if (data) {
            if (typeof data === 'object' && 'success' in data && !data.success) {
              setError(data?.message || 'Что пошло не так. Попробуйте позже ещё раз.');
            } else {
              setError(null);

              try {
                if (IS_PRODUCTION) {
                  gtag('event', 'user_login', {
                    login: 'success',
                  });

                  ym('reachGoal', 'login_success');
                }
              } catch (err) {
                console.error(`Error in LoginForm -> ${err}`);
              }
            }

            //window.location.assign(window.location);
            window.location.pathname = '/navigation';
            resolve(data);
          } else {
            throw new Error('No data from API');
          }
        })
        .catch((e) => {
          console.error(e);

          if (e.response && e?.response?.data?.error === 'Вы не подтвердили свой e-mail') {
            navigate('/send-mail-confirmation');
          }

          setError(e?.response?.data?.error ?? 'Неверный e-mail или пароль');

          reject(error);
        });
    });
  };

  return (
    <>
      <AuthorizationForm
        showNavigation={false}
        title={
          <div className={'space-y-4 text-center'}>
            <div className="text-gray-300">Ваш аккаунт</div>
            <div className="flex items-center space-x-1 justify-center">
              <span>{username}</span>{' '}
              <span className="rounded-full bg-green p-1">
                <CheckIcon width={14} height={14} className="text-white" />
              </span>
            </div>
          </div>
        }
        fields={[
          {
            name: 'password',
            label: 'Пароль',
            type: 'password',
          },
        ]}
        initialValues={{
          password: '',
        }}
        onSubmit={handleSubmit}
        submitLabel="Войти"
        superError={error}
        successMessage={'Вы вошли...'}
        useCaptcha
      />
      <NavLink to="/recovery-password" className="text-green">
        Забыли пароль?
      </NavLink>
    </>
  );
};

export default LoginSubmitForm;
