import CopyIcon from 'assets/icons/CopyIcon';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import PropTypes from 'prop-types';

const InputWithLabelAndCopy = ({ label, onChange, value, initValue, type = 'input' }) => {
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(value);
  };
  const onReset = () => {
    onChange(initValue);
  };
  const hasChanged = value != initValue;
  return (
    <div className="space-y-1">
      <div className="flex  justify-between">
        <div className="flex space-x-2 divide-x">
          <div className="text-base">{label}</div>
          {hasChanged && (
            <>
              <div className="flex space-x-2 pl-2">
                <span className="text-orange leading-6">Внесены изменения</span>
                <span className="text-green leading-6 cursor-pointer" onClick={onReset}>
                  Отменить
                </span>
              </div>
            </>
          )}
        </div>
        <button className="text-green flex space-x-1 items-center" onClick={copyToClipboard}>
          <CopyIcon width={14} height={14} />
          <span>Скопировать</span>
        </button>
      </div>

      {type === 'input' ? (
        <Input value={value} onChange={onChange} size={'large'} />
      ) : (
        <Textarea value={value} onChange={onChange} rows={6} className="p-3" />
      )}
    </div>
  );
};

InputWithLabelAndCopy.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  initValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['input', 'textarea']),
};

export default InputWithLabelAndCopy;
