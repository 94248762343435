import { useParams } from 'react-router';
import { getClusterInfoByType } from './utils';

const ClustersInput = (props) => {
  const { tab } = useParams();
  const clustersInfo = getClusterInfoByType(tab);
  const Component = clustersInfo.inputComponent;
  return (
    <div>
      <div className="py-4 px-8">{<Component {...props} />}</div>
    </div>
  );
};

export { ClustersInput };
