import { LOGIN_STEPS } from 'constants';
import { useAuth } from 'context/auth.context';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import LoginCheckForm from './LoginCheckForm';
import LoginSubmitForm from './LoginSubmitForm';

const LoginForm = ({ showNavigation }) => {
  const { step, setStep } = useAuth();

  useEffect(() => {
    return () => {
      setStep(LOGIN_STEPS.CHECK);
    };
  }, [setStep]);

  switch (step) {
    case LOGIN_STEPS.SUBMIT:
      return <LoginSubmitForm showNavigation={showNavigation} />;
    default:
      return <LoginCheckForm showNavigation={showNavigation} />;
  }
};

LoginForm.defaultProps = {
  showNavigation: true,
};

LoginForm.propTypes = {
  showNavigation: PropTypes.bool,
};

export default LoginForm;
