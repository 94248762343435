import cn from 'classnames';
import PropTypes from 'prop-types';

const TableListingWithPositionCell = (props) => {
  const { value, isSearch = false, data, mainField } = props || {};

  if (!value) {
    return '-';
  }
  if (value === ' ') {
    return '';
  }
  if (typeof value === 'number') {
    return value;
  }

  const { listing, sort_position, new_position, old_position, cpm, promotion_type } = value || {};

  const hasPromotion = promotion_type?.id !== null;

  if (new_position === null) {
    return listing || '-';
  }
  if (old_position === null) {
    return sort_position || '-';
  }

  const diff = isSearch ? data[mainField.split('_').join('_diff_')] || null : null;
  return (
    <div style={{ height: 100 }}>
      <div className="flex justify-end ">
        {isSearch && (
          <div
            className={cn('mr-2', {
              'text-[#5D9D52]': diff > 0,
              'text-[#D4483E]': diff < 0,
              '!text-gray-300': diff === 0,
            })}
          >
            {!diff ? '' : diff < 0 ? `↓ ${Math.abs(diff)}` : `↑ ${Math.abs(diff)}`}
          </div>
        )}

        <div>{listing || sort_position}</div>
      </div>
      {hasPromotion && (
        <div className="text-gray-400 text-xs leading-5 -mt-2">
          <div>
            {old_position} ›› {new_position}
          </div>
          {cpm} ₽
        </div>
      )}
    </div>
  );
};

TableListingWithPositionCell.propTypes = {
  data: PropTypes.any,
  mainField: PropTypes.any,
  isSearch: PropTypes.bool,
};

export default TableListingWithPositionCell;
