export const COLORS = {
  'ярко-синий': '#007CAD',
  'ярко-розовый': '#FC0FC0',
  'ярко-зеленый': '#66FF00',
  'ярко-желтый': '#FFB300',
  'яркий оранжевый': '#FF6800',
  янтарный: '#FFBF00',
  электрик: '#7DF9FF',
  экрю: '#CDB891',
  шоколадный: '#D2691E',
  шафрановый: '#F4C430',
  черный: '#000000',
  'черно-красный': '#412227',
  'черно-зеленый': '#343E40',
  хаки: '#806B2A',
  фисташковый: '#BEF574',
  фиолетовый: '#8B00FF',
  'фиолетово-баклажанный': '#991199',
  фиалковый: '#EA8DF7',
  'умеренный розовый': '#EE9086',
  'умеренно зеленый': '#C0DCC0',
  'умбра жженая': '#8A3324',
  ультрамариновый: '#120A8F',
  'тускло-розовый': '#FFE4E1',
  'травяной зеленый': '#35682D',
  томатный: '#FF6347',
  тиффани: '#0ABAB5',
  терракотовый: '#904D30',
  терракота: '#CC4E5C',
  'темный циан': '#008B8B',
  'темный хаки': '#BDB76B',
  'темный желто-зеленый': '#57A639',
  'темно-фиолетовый': '#9400D3',
  'темно-синий': '#002137',
  'темно-серый': '#49423D',
  'темно-розовый': '#E75480',
  'темно-пурпурный': '#472A3F',
  'темно-персиковый': '#FFDAB9',
  'темно-оранжевый': '#FF8C00',
  'темно-оливковый': '#556832',
  'темно-лазурный': '#08457E',
  'темно-красный': '#8B0000',
  'темно-коричневый': '#654321',
  'темно-каштановый': '#986960',
  'темно-зеленый': '#013220',
  'темно-желтый': '#B07D2B',
  'темно-голубой': '#3B83BD',
  'темно-бирюзовый': '#116062',
  'темно-алый': '#CB2821',
  'старое кружево': '#FDF5E6',
  'солнечно-желтый': '#F39F18',
  'слоновая кость': '#E1CC4F',
  сливовый: '#660066',
  сиреневый: '#C8A2C8',
  'синий серый': '#474B4E',
  синий: '#0000FF',
  'синевато-серый': '#7D746D',
  'сиена жженая': '#E97451',
  'сигнальный синий': '#1E2460',
  'сигнальный серый': '#969992',
  'серый шелк': '#CAC4B0',
  серый: '#808080',
  'серовато-розовый': '#CF9B8F',
  'серовато-пурпурный': '#72525C',
  'серовато-красный': '#8C4743',
  'серовато-зеленый': '#575E4E',
  'серо-бежевый': '#9E9764',
  'светлый хаки': '#F0E68C',
  'светлый синевато-зеленый': '#669E85',
  'светло-фиолетовый': '#876C99',
  'светло-синий': '#A6CAF0',
  'светло-серый': '#BBBBBB',
  'светло-розовый': '#FFB6C1',
  'светло-пурпурный': '#BA7FA2',
  'светло-песочный': '#FDEAA8',
  'светло-оливковый': '#846A20',
  'светло-коричневый': '#987654',
  'светло-золотистый': '#FFEC8B',
  'светло-зеленый': '#90EE90',
  'светло-желтый': '#FFFFE0',
  'светло-голубой': '#87CEFA',
  'светло-бирюзовый': '#40E0D0',
  сапфировый: '#082567',
  'сапфирово-синий': '#1D1E33',
  салатовый: '#99FF99',
  рыжий: '#D77D31',
  румянец: '#DE5D83',
  'розовый фламинго': '#FC74FD',
  'розовый кварц': '#D36E70',
  'розовый антик': '#D36E70',
  розовый: '#FFC0CB',
  'розово-лавандовый': '#FFF0F5',
  'розово-коричневый': '#BC8F8F',
  'розово-золотой': '#B76E79',
  'розовая фуксия': '#FF77FF',
  'розовая долина': '#AB4E52',
  'розовая гвоздика': '#FFAACC',
  'розовато-серый': '#C8A696',
  'резедово-зеленый': '#587246',
  'радикальный красный': '#FF496C',
  пюсовый: '#CC8899',
  пшеничный: '#F5DEB3',
  'пурпурно-синий': '#20155E',
  'пурпурно-красный': '#75151E',
  песочный: '#FCDD76',
  'персиковый крайола': '#FFCFAB',
  персиковый: '#FFE5B4',
  'персидский индиго': '#32127A',
  'перламутровый медный': '#763C28',
  'перламутрово-рубиновый': '#721422',
  'перламутрово-розовый': '#B44C43',
  'перламутрово-оранжевый': '#C35831',
  'пастельно-розовый': '#FFD1DC',
  'пастельно-оранжевый': '#FF7514',
  охра: '#CC7722',
  'охотничий зеленый': '#355E3B',
  орхидея: '#DA70D6',
  оранжевый: '#FFA500',
  'оранжево-розовый': '#FF9966',
  оливковый: '#808000',
  'огненно-красный': '#AF2B1E',
  ниагара: '#9DB1CC',
  'незрелый желтый': '#FFFF66',
  небесный: '#7FC7FF',
  мятный: '#3EB489',
  'мятно-бирюзовый': '#497E76',
  морковный: '#F36223',
  медовый: '#FEE5AC',
  марсала: '#AD655F',
  малиновый: '#DC143C',
  'малиново-розовый': '#B3446C',
  льняной: '#FAF0E6',
  'лососевый крайола': '#FF9BAA',
  лососевый: '#FF8C69',
  лимонный: '#FDE910',
  'лимонно-желтый': '#C7B446',
  лайм: '#00FF00',
  кремовый: '#FDF4E3',
  'красное дерево': '#C04000',
  'красно-оранжевый': '#C93C20',
  'красно-коричневый': '#592321',
  кофейный: '#442D25',
  коричневый: '#964B00',
  коралловый: '#FF7F50',
  'кораллово-красный': '#B32821',
  кармин: '#960018',
  'изумрудно-зеленый': '#287233',
  'золотисто-березовый': '#DAA520',
  зеленый: '#008000',
  'зеленое море': '#2E8B57',
  'зеленовато-серый': '#7A7666',
  гранатовый: '#F34723',
  'голубино-синий': '#606E8C',
  'глубокий коричневый': '#4D220E',
  'бриллиантово-синий': '#3E5F8A',
  бордовый: '#9B2D30',
  'бордово-фиолетовый': '#641C34',
  'бледно-желтый': '#FFDB8B',
  белый: '#FFFFFF',
  красный: '#FF0000',
  желтый: '#FFFF00',
  голубой: '#42AAFF',
};

export const COLORS_BY_INDEX = [
  '#007CAD',
  '#FC0FC0',
  '#66FF00',
  '#FFB300',
  '#FF6800',
  '#FFBF00',
  '#7DF9FF',
  '#CDB891',
  '#D2691E',
  '#F4C430',
  '#000000',
  '#412227',
  '#343E40',
  '#806B2A',
  '#BEF574',
  '#8B00FF',
  '#991199',
  '#EA8DF7',
  '#EE9086',
  '#C0DCC0',
  '#8A3324',
  '#120A8F',
  '#FFE4E1',
  '#35682D',
  '#FF6347',
  '#0ABAB5',
  '#904D30',
  '#CC4E5C',
  '#008B8B',
  '#BDB76B',
  '#57A639',
  '#9400D3',
  '#002137',
  '#49423D',
  '#E75480',
  '#472A3F',
  '#FFDAB9',
  '#FF8C00',
  '#556832',
  '#08457E',
  '#8B0000',
  '#654321',
  '#986960',
  '#013220',
  '#B07D2B',
  '#3B83BD',
  '#116062',
  '#CB2821',
  '#FDF5E6',
  '#F39F18',
  '#E1CC4F',
  '#660066',
  '#C8A2C8',
  '#474B4E',
  '#0000FF',
  '#7D746D',
  '#E97451',
  '#1E2460',
  '#969992',
  '#CAC4B0',
  '#808080',
  '#CF9B8F',
  '#72525C',
  '#8C4743',
  '#575E4E',
  '#9E9764',
  '#F0E68C',
  '#669E85',
  '#876C99',
  '#A6CAF0',
  '#BBBBBB',
  '#FFB6C1',
  '#BA7FA2',
  '#FDEAA8',
  '#846A20',
  '#987654',
  '#FFEC8B',
  '#90EE90',
  '#FFFFE0',
  '#87CEFA',
  '#40E0D0',
  '#082567',
  '#1D1E33',
  '#99FF99',
  '#D77D31',
  '#DE5D83',
  '#FC74FD',
  '#D36E70',
  '#D36E70',
  '#FFC0CB',
  '#FFF0F5',
  '#BC8F8F',
  '#B76E79',
  '#FF77FF',
  '#AB4E52',
  '#FFAACC',
  '#C8A696',
  '#587246',
  '#FF496C',
  '#CC8899',
  '#F5DEB3',
  '#20155E',
  '#75151E',
  '#FCDD76',
  '#FFCFAB',
  '#FFE5B4',
  '#32127A',
  '#763C28',
  '#721422',
  '#B44C43',
  '#C35831',
  '#FFD1DC',
  '#FF7514',
  '#CC7722',
  '#355E3B',
  '#DA70D6',
  '#FFA500',
  '#FF9966',
  '#808000',
  '#AF2B1E',
  '#9DB1CC',
  '#FFFF66',
  '#7FC7FF',
  '#3EB489',
  '#497E76',
  '#F36223',
  '#FEE5AC',
  '#AD655F',
  '#DC143C',
  '#B3446C',
  '#FAF0E6',
  '#FF9BAA',
  '#FF8C69',
  '#FDE910',
  '#C7B446',
  '#00FF00',
  '#FDF4E3',
  '#C04000',
  '#C93C20',
  '#592321',
  '#442D25',
  '#964B00',
  '#FF7F50',
  '#B32821',
  '#960018',
  '#287233',
  '#DAA520',
  '#008000',
  '#2E8B57',
  '#7A7666',
  '#F34723',
  '#606E8C',
  '#4D220E',
  '#3E5F8A',
  '#9B2D30',
  '#641C34',
  '#FFDB8B',
  '#FFFFFF',
  '#FF0000',
  '#FFFF00',
  '#42AAFF',
];
