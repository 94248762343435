import { GREEN, PAGE_SIGNUP } from 'constants';
import { NavLink } from 'react-router';

const LoginNavigation = () => {
  const attrs = {
    className:
      'py-3 flex-1 text-base px-2 text-gray-500 border-b-2 border-transparent text-center hover:text-green',
  };

  const activeStyle = {
    color: GREEN,
    borderColor: GREEN,
  };

  return (
    <nav className="flex border-b border-gray-200 mb-12 w-full">
      <NavLink to="/" {...attrs} exact activeStyle={activeStyle}>
        Вход
      </NavLink>
      <NavLink to={PAGE_SIGNUP} {...attrs} activeStyle={activeStyle}>
        Регистрация
      </NavLink>
    </nav>
  );
};

export default LoginNavigation;
