const CopyIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 9.75V15C12 15.8284 11.3284 16.5 10.5 16.5H3.75C2.92157 16.5 2.25 15.8284 2.25 15V6C2.25 5.17157 2.92157 4.5 3.75 4.5H6.75M12 9.75L6.75 4.5M12 9.75H7.125C6.91789 9.75 6.75 9.58211 6.75 9.375V4.5M15.75 6V11.25C15.75 12.0784 15.0784 12.75 14.25 12.75H12M15.75 6L10.5 0.75M15.75 6H10.875C10.6679 6 10.5 5.83211 10.5 5.625V0.75M10.5 0.75H7.5C6.67157 0.75 6 1.4212 6 2.24962V4.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;

export const CopyIconWithClipboard = (props) => {
  const { textToCopy, ...otherProps } = props || {};
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(textToCopy);
  };
  return <CopyIcon {...otherProps} onClick={copyToClipboard} />;
};
