import Checkbox from 'components/Checkbox';
import { useFilterReducer } from 'context/filter/filter.context.reducer';

const TableIsPromotionalFilter = () => {
  const {
    filter: { isPromotional },
    toggleIsPromotional,
  } = useFilterReducer();
  return (
    <Checkbox
      label="Акционные категории"
      onChange={toggleIsPromotional}
      name="is-promotional"
      checked={!!isPromotional}
      className={'whitespace-nowrap'}
    />
  );
};

export default TableIsPromotionalFilter;
