import PropTypes from 'prop-types';

const TableCellSeoProductCheckAppearanceCell = ({ value }) => {
  console.log(value, 'value');
  switch (value) {
    case 1:
      return 'Полное вхождение';
    case 2:
      return 'Полное вхождение (К)';
    case 3:
      return 'Все слова';
    case 4:
      return 'Все слова (К)';
    case 5:
      return '-';
  }

  return '-';
};
TableCellSeoProductCheckAppearanceCell.propTypes = {
  value: PropTypes.string.isRequired,
};
export default TableCellSeoProductCheckAppearanceCell;
