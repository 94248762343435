import cn from 'classnames';

import ProductTableNav from 'components/ProductTableNav';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUI } from 'context/ui.context';
import ProductTableHeaderExtraFilters from './ProductTableHeaderExtraFilters';

const ProductTableHeader = (props) => {
  const {
    filter: { platform },
  } = useFilterReducer();
  const { isMobile } = useUI();
  if (!platform) return <></>;

  return (
    <>
      <div
        className={cn('flex justify-between items-center px-4', {
          ['flex-nowrap touch-auto overflow-x-auto']: isMobile,
        })}
      >
        <ProductTableNav {...props} />
      </div>
      <ProductTableHeaderExtraFilters />
    </>
  );
};

export default ProductTableHeader;
