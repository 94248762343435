import { useState } from 'react';
import { Navigate, useParams } from 'react-router';

import ChartDoughnutWithTable from 'components/ChartDoughnutWithTable';
import HelpBlock from 'components/HelpBlock';
import LoadingSpin from 'components/LoadingSpin';
import { TABLE_TYPE } from 'constants/table';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';
import OrdersStockToggler from 'pages/Product/OrdersStockToggler';

const Sizes = () => {
  const { id } = useParams();
  const [isOrdersData, setIsOrdersData] = useState(true);

  const { isProductSizesColorsAvailable } = useUserTariffs();

  const { /*data,*/ isLoading, isFetching } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_DATA,
    id,
    enabled: isProductSizesColorsAvailable,
  });

  const {
    data: productSizes = [],
    isLoading: isProductSizesLoading,
    isFetching: isProductSizesFetching,
  } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_SIZES,
    id,
    enabled: isProductSizesColorsAvailable,
  });

  if (!isProductSizesColorsAvailable) {
    return <Navigate to={`/product/${id}`} />;
  }
  const chartKey = isOrdersData ? 'orders_rub' : 'stock_rub';
  const pieData = productSizes.map((size, index) => ({
    index,
    name: size.name,
    value: +size[chartKey],
  }));
  const toggler = (
    <OrdersStockToggler isOrdersData={isOrdersData} setIsOrdersData={setIsOrdersData} />
  );

  return (
    <>
      <HelpBlock type="product_sizes" />
      <div className="p-4 bg-gray-100 gap-3">
        {isLoading ? (
          <LoadingSpin />
        ) : (
          <div className="bg-white rounded-xl">
            <ChartDoughnutWithTable
              tableData={productSizes.map((el, index) => ({ ...el, index }))}
              pieData={pieData}
              isLoading={isLoading || isFetching || isProductSizesLoading || isProductSizesFetching}
              tableType={TABLE_TYPE.PRODUCT_SIZES}
              noRowText="Нет данных"
              postfix="₽"
              key="sizes-rub"
              toggler={toggler}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Sizes;
