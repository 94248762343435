import cn from 'classnames';
import { COLUMNS } from 'constants/table';
import PropTypes from 'prop-types';
import { replaceJpgWithWebp } from 'utils/images';

const TableCellImageTooltip = (props) => {
  const { data, location, reactContainer, colDef, valueFormatted, value: propsValue } = props;
  const { isSeo } = propsValue;
  let value = valueFormatted || data?.[colDef?.field];
  if (location === 'header' || !value) {
    return <div />;
  }
  value = replaceJpgWithWebp(value);

  reactContainer.style.height = '234px';
  reactContainer.style.width = '208px';

  return (
    <div
      className={cn(
        `
      opacity-100 -mt-12
      pl-7
      `,
        {
          '!mt-1': isSeo,
        },
      )}
    >
      <div
        className={`
        max-h-full max-w-full
        bg-white p-2
        shadow-black-bright shadow rounded-md
        flex items-center justify-center
        `}
        style={{
          height: '186px',
          width: '180px',
        }}
      >
        <img
          src={value}
          alt={data?.[COLUMNS.NAME] || 'photo'}
          className="rounded-lg max-h-full max-w-full"
        />
      </div>
    </div>
  );
};

TableCellImageTooltip.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
  }),
  location: PropTypes.string.isRequired,
  reactContainer: PropTypes.object,
  colDef: PropTypes.object,
  valueFormatted: PropTypes.any,
  value: PropTypes.any,
};

export default TableCellImageTooltip;
