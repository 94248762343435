import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import {
  BLUE,
  CHARTS_COLORS_WB,
  CONTESSA,
  GRAY_DARK,
  GREEN,
  HELIOTROPE,
  HOT_PINK,
  LEAF,
  PINK,
  VIKING,
  YELLOW,
} from 'constants/colors';
import { COLUMNS, TABLE_INNER_TYPE } from 'constants/table';

import MultiChart, { formatDateLabel, formatXAxisDate } from 'components/MultiChart';
import Table from 'components/Table';
import { TYPE_ABSOLUTE } from 'constants/chart';
import { useProduct } from 'context/product.context';
import { useUI } from 'context/ui.context';
import {
  useFetchFilteredInnerData,
  useFetchFilteredInnerDataPaginated,
} from 'hooks/useFetchFilteredInnerData';

const MOBILE_LABELS = {
  [COLUMNS.SUM_ORDERS_INNER]: 'Заказы, руб.',
  [COLUMNS.SUM_SALES_INNER]: 'Продажи, руб.',
  [COLUMNS.SUM_RETURN_INNER]: 'Возвраты, руб.',
  [COLUMNS.COUNT_ORDERS_INNER]: 'Заказы, шт.',
  [COLUMNS.COUNT_SALES_INNER]: 'Продажи, шт.',
  [COLUMNS.COUNT_RETURN_INNER]: 'Возвраты, шт.',
  [COLUMNS.PAYOFF]: 'Прибыль',
  [COLUMNS.PRICE]: 'Цена',
  [COLUMNS.QUANTITY]: 'Остаток',
};

const LABELS = {
  [COLUMNS.SUM_ORDERS_INNER]: 'Заказы, руб.',
  [COLUMNS.SUM_SALES_INNER]: 'Продажи, руб.',
  [COLUMNS.SUM_RETURN_INNER]: 'Возвраты, руб.',
  [COLUMNS.PAYOFF]: 'Прибыль',
  [COLUMNS.PRICE]: 'Цена',
  [COLUMNS.QUANTITY]: 'Остаток',
  [COLUMNS.COUNT_ORDERS_INNER]: 'Заказы, шт.',
  [COLUMNS.COUNT_SALES_INNER]: 'Продажи, шт.',
  [COLUMNS.COUNT_RETURN_INNER]: 'Возвраты, шт.',
};

const INIT_LABELS = [COLUMNS.SUM_ORDERS_INNER, COLUMNS.SUM_SALES_INNER, COLUMNS.PAYOFF];
const COLORS = [PINK, BLUE, GREEN, GRAY_DARK];
const COLORS_SCATTER = [VIKING, HELIOTROPE, YELLOW, LEAF, CONTESSA, HOT_PINK];

const InnerProductCharts = ({ fetchEnabled, columns }) => {
  const { id } = useParams();
  const { isMobile } = useUI();
  const { productData, isLoading: productLoading } = useProduct();

  const {
    data: ratingsChartData,
    isLoading: isRatingChartLoading,
    error: isRatingChartError,
  } = useFetchFilteredInnerData({
    type: TABLE_INNER_TYPE.RATINGS_CHART,
    data: { id },
    enabled: fetchEnabled,
  });

  const {
    data: ratingsData,
    isLoading: isRatingLoading,
    error: isRatingError,
  } = useFetchFilteredInnerDataPaginated({
    type: TABLE_INNER_TYPE.RATINGS,
    data: { id },
    enabled: fetchEnabled,
  });

  const platformColors = CHARTS_COLORS_WB;
  // This made for duplicate colors for rows price/count
  let transformedColors = [...platformColors, ...COLORS, ...platformColors, ...COLORS];
  let transformedLabelsPostfix = {
    [COLUMNS.SUM_ORDERS_INNER]: `${
      productData?.[COLUMNS.ORDERS_RUB_]?.toLocaleString('ru') || '-'
    } ₽`,
    [COLUMNS.COUNT_ORDERS_INNER]: `${
      productData?.[COLUMNS.ORDERS_QTY_]?.toLocaleString('ru') || '-'
    } шт`,
    [COLUMNS.SUM_SALES_INNER]: `${productData?.[COLUMNS.SALES_SUM]?.toLocaleString('ru') || '-'} ₽`,
    [COLUMNS.COUNT_SALES_INNER]: `${
      productData?.[COLUMNS.SALES_QTY]?.toLocaleString('ru') || '-'
    } шт`,
    [COLUMNS.SUM_RETURN_INNER]: `${
      productData?.[COLUMNS.RETURNS_PERIOD_RUB]?.toLocaleString('ru') || '-'
    } ₽`,
    [COLUMNS.COUNT_RETURN_INNER]: `${
      productData?.[COLUMNS.RETURNS_PERIOD_QTY]?.toLocaleString('ru') || '-'
    } шт`,
    [COLUMNS.PAYOFF]: `${productData?.[COLUMNS.PAYOFF_PERIOD]?.toLocaleString('ru') || '-'} ₽`,
    [COLUMNS.PRICE]: `${productData?.[COLUMNS.PRICE]?.toLocaleString('ru') || '-'} ₽`,
    [COLUMNS.QUANTITY]: `${productData?.[COLUMNS.QUANTITY]?.toLocaleString('ru') || '-'} шт`,
  };

  const convertForMobile = (array) => {
    const resultArr = [...array];
    resultArr.splice(3, 0, ...array.slice(0, 3));
    return resultArr;
  };

  const transformedData = {
    absolute: ratingsChartData
      ? Object.values(ratingsChartData).map((el) => {
          const { date, ...other } = el;
          const resultNote = { name: date };
          Object.entries(other).forEach(([key, value]) => {
            if (Object.keys(LABELS).includes(key)) {
              resultNote[`${key}_absolute`] = +value;
            } else {
              resultNote[`${key}_absolute`] = value;
            }
          });
          return resultNote;
        })
      : [],
  };

  return (
    <div className="relative z-0">
      <div className="bg-gray-100 py-4">
        <div className="wrapper-wide">
          <MultiChart
            colors={isMobile ? convertForMobile(transformedColors) : transformedColors}
            data={transformedData}
            isLoading={productLoading || isRatingChartLoading}
            error={isRatingChartError || isRatingError}
            periods={[]}
            type={TYPE_ABSOLUTE}
            types={[TYPE_ABSOLUTE]}
            labels={isMobile ? MOBILE_LABELS : LABELS}
            labelsPostfix={transformedLabelsPostfix}
            initialLabels={INIT_LABELS}
            columns={isMobile ? 3 : columns}
            formatXAxis={formatXAxisDate}
            formatTooltipLabel={formatDateLabel}
            yAxisAngle={-30}
            scatterColors={COLORS_SCATTER}
            chartKey={`inner_${TABLE_INNER_TYPE.RATINGS}`}
          />
        </div>
      </div>
      {ratingsData && fetchEnabled && (
        <Table
          data={ratingsData}
          type={TABLE_INNER_TYPE.RATINGS}
          isLoading={isRatingLoading || productLoading}
          noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
          // asyncGetDetailRowData={asyncGetDateHoursData}
          // typeNested={TABLE_TYPE.PRODUCT_SALES_INNER}
        />
      )}
    </div>
  );
};

InnerProductCharts.defaultProps = {
  columns: 6,
};

InnerProductCharts.propTypes = {
  columns: PropTypes.number,
  fetchEnabled: PropTypes.bool,
};
export default InnerProductCharts;
