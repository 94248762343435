import cn from 'classnames';
import PropTypes from 'prop-types';
const Input = ({
  name,
  value,
  onChange,
  autoFocus,
  valid,
  prefix,
  postfix,
  placeholder,
  className,
  size,
}) => {
  let sizeClassName = 'px-1.5 pt-0.5 h-[22px] text-xs leading-none';
  if (size === 'large') {
    sizeClassName = 'h-11 px-3 py-4 text-sm leading-5';
  }
  return (
    <div className="relative flex items-center">
      {prefix && (
        <span className="text-xs absolute pointer-events-none left-[8px] top-[4px]">{prefix}</span>
      )}
      <input
        className={cn(
          ' w-full appearance-none border rounded  text-gray-700   focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-offset-2 ',
          !valid ? 'border-red focus:ring-red' : 'border-gray-300 focus:ring-green',
          {
            'pl-6': prefix,
          },
          sizeClassName,
          className,
        )}
        id={name}
        name={name}
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
      {postfix && (
        <span className="text-xs  pointer-events-none left-[8px] top-[4px]">{postfix}</span>
      )}
    </div>
  );
};

Input.defaultProps = {
  autoFocus: false,
  valid: true,
  prefix: null,
  postfix: null,
  placeholder: '',
  className: '',
  size: 'small',
};
Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  valid: PropTypes.bool,
  prefix: PropTypes.string,
  postfix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
};

export default Input;
