import { useEffect, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router';

import ChartDoughnutWithTable from 'components/ChartDoughnutWithTable';
import HelpBlock from 'components/HelpBlock';
import LoadingSpin from 'components/LoadingSpin';
import { TABLE_TYPE } from 'constants/table';
import { useChartDoughnut } from 'context/chart.doughnut.context';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';
import OrdersStockToggler from 'pages/Product/OrdersStockToggler';
import { formatColorChartsDataByKey } from 'utils/charts';
import { COLORS_BY_INDEX } from 'utils/colors';

const Colors = () => {
  const { id } = useParams();
  const [isOrdersData, setIsOrdersData] = useState(true);

  const { isProductSizesColorsAvailable } = useUserTariffs();
  const { isLoading, isFetching } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_DATA,
    id,
    enabled: isProductSizesColorsAvailable,
  });

  const {
    data: productColors,
    isLoading: isProductColorsLoading,
    isFetching: isProductColorsFetching,
  } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_COLORS,
    id,
    enabled: isProductSizesColorsAvailable,
  });

  const { setDefaultColors, defaultColors, setColors } = useChartDoughnut();
  const tableData = useMemo(
    () => (productColors && 'all_data' in productColors ? productColors.all_data : {}),
    [productColors],
  );

  useEffect(() => {
    let indexOfDefault = 0;
    setColors(
      Object.values(tableData).map((_, index) => {
        if (index in COLORS_BY_INDEX) {
          return COLORS_BY_INDEX[index];
        } else {
          const resultDefaultColor = defaultColors[indexOfDefault];
          indexOfDefault++;
          return resultDefaultColor;
        }
      }),
    );
    return () => setDefaultColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  if (!isProductSizesColorsAvailable) {
    return <Navigate to={`/product/${id}`} />;
  }

  const toggler = (
    <OrdersStockToggler isOrdersData={isOrdersData} setIsOrdersData={setIsOrdersData} />
  );
  const chartKey = isOrdersData ? 'orders_rub' : 'stock_qty';
  const pieData = formatColorChartsDataByKey(productColors, chartKey);
  return (
    <>
      <HelpBlock type="product_colors" />
      <div className="p-4 bg-gray-100 gap-3">
        {isProductColorsLoading || !productColors ? (
          <LoadingSpin />
        ) : (
          <div className="bg-white rounded-xl">
            <ChartDoughnutWithTable
              tableData={tableData}
              pieData={pieData}
              key="colors-orders-count"
              postfix="₽"
              isLoading={
                isLoading || isProductColorsLoading || isFetching || isProductColorsFetching
              }
              tableType={TABLE_TYPE.PRODUCT_COLORS}
              noRowText="Нет данных"
              toggler={toggler}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Colors;
