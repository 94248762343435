import cn from 'classnames';
import PropTypes from 'prop-types';
const Textarea = ({ onChange, value, placeholder, rows, className }) => {
  return (
    <textarea
      type={'text'}
      className={cn('w-full border rounded border-gray-300 p-1', className)}
      rows={rows}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      value={value}
    />
  );
};

Textarea.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  rows: PropTypes.number,
};
export default Textarea;
