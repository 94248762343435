import { PAGE_SIGNUP } from 'constants';
import { Navigate, Outlet, Route, Routes } from 'react-router';

import Footer from 'components/Footer';
import { AuthorizationLayout } from 'components/Layout';
import { PAGE_ADVERTISING_RATES } from 'constants/pages';
import {
  ActivateDemo,
  LogIn,
  RecoveryPassword,
  RegistrationVerification,
  ResetPassword,
  SendMailConfirmation,
  SignUp,
  UnauthorizedAdvertisingRates,
} from 'pages';
import { ActivationRoutes } from './authenticated-app';

const AuthorizationRoutes = () => (
  <AuthorizationLayout>
    <Outlet />
  </AuthorizationLayout>
);

const UnauthenticatedApp = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Routes>
        <Route path={PAGE_ADVERTISING_RATES} element={<UnauthorizedAdvertisingRates />} />
        <Route path="/activate-account" element={<ActivationRoutes />} />
        <Route path="/activate-demo/:tariffId/:token" element={<ActivateDemo />} />
        <Route path="/" element={<AuthorizationRoutes />}>
          <Route path={PAGE_SIGNUP} element={<SignUp />} />
          <Route path="/sign-in/:code" element={<RegistrationVerification />} />
          <Route path="/recovery-password" element={<RecoveryPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/send-mail-confirmation" element={<SendMailConfirmation />} />
          <Route index element={<LogIn />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
};

export default UnauthenticatedApp;
