import BlockInner from './BlockInner';

const Block = (props) => {
  const { label, type, extra, children } = props || {};

  if (type === 'inner') {
    return <BlockInner {...props} />;
  }
  return (
    <div className="border border-solid border-gray-200 rounded-xl bg-white">
      <div className="flex w-full justify-between text-left px-6 mt-6 mb-4 group">
        <span className="block font-medium text-lg">{label}</span>
        {extra && <div className="flex items-center min-w-max">{extra}</div>}
      </div>
      <div className="px-6">
        <div className="py-6 border-t border-grey">{children}</div>
      </div>
    </div>
  );
};

export default Block;
