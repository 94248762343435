import { SeoProductProvider, useSeoProductContext } from 'context/seo.product.context';
import ProductCard from './SeoProductCard';
import ProductCardSearch from './SeoProductSearch';

const Product = () => {
  return (
    <SeoProductProvider>
      <ProductWithProvider />
    </SeoProductProvider>
  );
};
const ProductWithProvider = () => {
  const { state } = useSeoProductContext();

  if (state) {
    return <ProductCard />;
  } else {
    return <ProductCardSearch />;
  }
};

export default Product;
