import cn from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';

import LoadingSpin from 'components/LoadingSpin';
import Product from 'components/Product';
import ProductCardList from 'components/ProductCardList';
import ProductFilter from 'components/ProductFilter';
import ProductHeader from 'components/ProductHeader';
import ProductTabWrapper from 'components/ProductWrapper';
import {
  PAGE_PRODUCT,
  PAGE_PRODUCT_END_ADV,
  PAGE_PRODUCT_END_CHANGES,
  PAGE_PRODUCT_END_COLORS,
  PAGE_PRODUCT_END_LISTING,
  PAGE_PRODUCT_END_SALES,
  PAGE_PRODUCT_END_SEARCH,
  PAGE_PRODUCT_END_SIMILAR,
  PAGE_PRODUCT_END_SIZES,
  PAGE_PRODUCT_END_STOCKS,
} from 'constants';
import { TABLE_TYPE } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useProduct } from 'context/product.context';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';
import useStickyObserver from 'hooks/useStickyObserver';
import {
  ProductAdvertising,
  ProductChanges,
  ProductCharts,
  ProductColors,
  ProductListing,
  ProductSalesByDay,
  ProductSearch,
  ProductSimilar,
  ProductSizes,
  ProductWarehouses,
} from 'pages';

export const ProductFilterSticky = () => {
  const [ref, isSticky] = useStickyObserver({ disableOnMobile: true });

  return (
    <div
      ref={ref}
      className={cn('pt-3 lg:py-2 lg:bg-white', {
        ['lg:shadow-sm']: isSticky,
        ['lg:relative']: !isSticky,
      })}
    >
      <ProductFilter isSticky={isSticky} />
    </div>
  );
};

ProductFilterSticky.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

const ProductLayout = () => {
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(tab || '');

  useEffect(() => {
    setCurrentTab(tab || '');
  }, [tab]);

  const goToPage = ({ link }) => {
    const tab = link.replace(`${PAGE_PRODUCT}/${id}`, '').replace('/', '');
    setCurrentTab(tab);

    navigate(
      {
        pathname: link,
      },
      { replace: true },
    );
  };
  const { filter, error } = useFilterReducer();
  const { isLoaded: isFilterLoaded, setProductPlatform } = useFilterReducer();
  const { updateProductData, updateProductError, updateLoading } = useProduct();

  const {
    data: productData,
    isLoading: productLoading,
    isFetching,
    isFetched: productFetched,
    error: productError,
  } = useFetchFilteredProductData({
    type: TABLE_TYPE.EXT_PRODUCT_SUM,
    id,
    date: filter.date,
  });
  useEffect(() => {
    updateProductData(productData);
  }, [updateProductData, productData]);

  useEffect(() => {
    setProductPlatform();
  }, [setProductPlatform]);

  useEffect(() => {
    updateProductError(productError);
  }, [productError, updateProductError]);

  useEffect(() => {
    updateLoading(!productFetched || productLoading);
  }, [productLoading, productFetched, updateLoading]);

  useEffect(() => {
    if (productData) {
      let { name } = productData;
      if (name.length > 40) {
        name = name.split('').slice(0, 38).join('') + '...';
      }
      document.title = `${name} - WeCheck`;
    } else {
      document.title = 'WeCheck - аналитика маркетплейсов';
    }
  }, [productData]);

  if (error) {
    return (
      <Navigate
        to={{
          pathname: '/error-access',
          state: { error: error },
        }}
      />
    );
  }

  return (
    <>
      {!productFetched || productLoading || !isFilterLoaded ? (
        <LoadingSpin />
      ) : (
        <>
          <ProductFilterSticky />

          <ProductHeader />

          <ProductCardList isLoading={productLoading || isFetching} />

          <Product onTabChange={goToPage}>
            <ProductTabWrapper
              currentTab={currentTab}
              tab={PAGE_PRODUCT_END_SALES}
              component={ProductSalesByDay}
            />

            <ProductTabWrapper
              currentTab={currentTab}
              tab={PAGE_PRODUCT_END_LISTING}
              component={ProductListing}
            />

            <ProductTabWrapper
              currentTab={currentTab}
              tab={PAGE_PRODUCT_END_SEARCH}
              component={ProductSearch}
            />

            <ProductTabWrapper
              currentTab={currentTab}
              tab={PAGE_PRODUCT_END_SIZES}
              component={ProductSizes}
            />

            <ProductTabWrapper
              currentTab={currentTab}
              tab={PAGE_PRODUCT_END_COLORS}
              component={ProductColors}
            />

            <ProductTabWrapper
              currentTab={currentTab}
              tab={PAGE_PRODUCT_END_STOCKS}
              component={ProductWarehouses}
            />

            <ProductTabWrapper
              currentTab={currentTab}
              tab={PAGE_PRODUCT_END_SIMILAR}
              component={ProductSimilar}
            />

            <ProductTabWrapper
              currentTab={currentTab}
              tab={PAGE_PRODUCT_END_CHANGES}
              component={ProductChanges}
            />

            <ProductTabWrapper
              currentTab={currentTab}
              tab={PAGE_PRODUCT_END_ADV}
              component={ProductAdvertising}
            />

            <ProductTabWrapper currentTab={currentTab} tab={'main'} component={ProductCharts} />
          </Product>
        </>
      )}
    </>
  );
};

export default ProductLayout;
