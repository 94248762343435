import { useState } from 'react';
import { Navigate } from 'react-router';

import ChartDoughnutWithTable from 'components/ChartDoughnutWithTable';
import HelpBlock from 'components/HelpBlock';
import { TABLE_TYPE } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useFetchFilteredData } from 'hooks/useFetchFilteredData';
import OrdersStockToggler from 'pages/Product/OrdersStockToggler';

const Warehouses = () => {
  const [isOrdersData, setIsOrdersData] = useState(true);

  const { checkWarehousesAvailability } = useUserTariffs();
  const { isOzonPlatform } = useFilterReducer();
  const { filter, isLoaded } = useFilterReducer();

  const { isWarehousesAvailable } = checkWarehousesAvailability(filter);

  let {
    data = [],
    isLoading,
    isFetching,
  } = useFetchFilteredData({
    type: TABLE_TYPE.WAREHOUSES,
    enabled: isWarehousesAvailable && !isOzonPlatform,
  });

  const toggler = (
    <OrdersStockToggler isOrdersData={isOrdersData} setIsOrdersData={setIsOrdersData} />
  );
  const chartKey = isOrdersData ? 'orders_rub' : 'stock_rub';
  const pieData = data.map((warehouse, index) => ({
    index,
    name: warehouse.name,
    value: +warehouse[chartKey],
  }));

  if ((!isWarehousesAvailable && isLoaded) || isOzonPlatform) {
    return <Navigate to="/main" />;
  }

  return (
    <>
      <HelpBlock type="warehouses" />
      <div className="p-4 bg-gray-100 gap-3">
        <div className="bg-white rounded-xl">
          <ChartDoughnutWithTable
            tableData={data.map((el, index) => ({ ...el, index }))}
            pieData={pieData}
            isLoading={isLoading || isFetching}
            tableType={TABLE_TYPE.PRODUCT_WAREHOUSES}
            noRowText="Нет данных"
            postfix="₽"
            key="warehouses-rub"
            toggler={toggler}
          />
        </div>
      </div>
    </>
  );
};

export default Warehouses;
