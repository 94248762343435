import { TABLE_INNER_TYPE } from 'constants/table';

import ChartDoughnutWithTable from 'components/ChartDoughnutWithTable';
import { useFetchFilteredInnerData } from 'hooks/useFetchFilteredInnerData';
import OrdersStockToggler from 'pages/Product/OrdersStockToggler';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router';

const InnerWarehouses = ({ isProduct, fetchEnabled }) => {
  const { id } = useParams();
  const [isOrdersData, setIsOrdersData] = useState(true);
  const resultType = isProduct ? TABLE_INNER_TYPE.PRODUCT_WAREHOUSES : TABLE_INNER_TYPE.WAREHOUSES;

  const { data = [], isLoading } = useFetchFilteredInnerData({
    type: resultType,
    data: {
      id,
    },
    enabled: fetchEnabled,
  });
  const toggler = (
    <OrdersStockToggler isOrdersData={isOrdersData} setIsOrdersData={setIsOrdersData} />
  );

  const pieData = data.map((el, index) => ({
    index: index,
    name: el.warehouse,
    value:
      +el[
        isOrdersData
          ? `warehouses_orders_rub`
          : isProduct
          ? 'warehouse_stock'
          : 'quantity_retail_cost'
      ],
  }));
  return (
    <>
      <ChartDoughnutWithTable
        tableData={data.map((el, index) => ({ ...el, index }))}
        pieData={pieData}
        key={resultType}
        postfix={isOrdersData || !isProduct ? '₽' : 'шт'}
        isLoading={isLoading || isLoading}
        tableType={resultType}
        noRowText="Нет данных"
        toggler={toggler}
      />
    </>
  );
};

InnerWarehouses.propTypes = {
  isProduct: PropTypes.bool,
  fetchEnabled: PropTypes.bool,
};

export default InnerWarehouses;
