import { PAGE_PRODUCTS } from 'constants';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const CategoryLink = ({ id, label }) => {
  return (
    <Link to={PAGE_PRODUCTS + '?filter=category%3D' + id} target={'_blank'}>
      {label}
    </Link>
  );
};

CategoryLink.propTypes = {
  id: PropTypes.integer,
  label: PropTypes.string,
};

export default CategoryLink;
