const COLORS_LABELS = [
  { color: 'bg-orange-100', label: '- товар показывается в Авторекламе' },
  { color: 'bg-pink-100', label: ' - товар показывается в рекламе типа Аукцион' },
];
const ProductListingLegend = () => {
  return (
    <div className="flex space-x-10">
      {COLORS_LABELS.map((info) => {
        const { color, label } = info;
        return (
          <div className="flex items-center space-x-2" key={label}>
            <span className={'h-5 w-10 ' + color}></span>
            <span className="text-xs">{label}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ProductListingLegend;
