import CSVIcon from 'assets/icons/CSVIcon';
import ExcelIcon from 'assets/icons/ExcelIcon';
import Modal from 'components/Modal';
import Popover from 'components/Popover';
import TableExportContent from 'components/TableExportContent';
import { NOT_AVAILABLE_BECAUSE_TARIFF, TABLE_INNER_TYPE, TABLE_TYPE } from 'constants';
import { useTable } from 'context/table.context';
import { useUI } from 'context/ui.context';
import { useUserTariffs } from 'context/user.tariffs.context';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

const TableExportButton = ({ type, format }) => {
  const { isMobile } = useUI();
  const [isOpened, setIsOpened] = useState(false);
  const { exportByType, isBackendExport } = useTable();

  const tooltipRef = useRef();
  const { isTableExportAvailable } = useUserTariffs();
  const exportExcel = () => {
    if (!isBackendExport(type)) {
      exportByType(format);
    } else {
      setIsOpened(true);
    }
  };
  return (
    <div className="flex items-center">
      <button
        ref={tooltipRef}
        onClick={exportExcel}
        disabled={!isTableExportAvailable}
        className={!isTableExportAvailable ? 'cursor-not-allowed' : ''}
      >
        {format === 'xlsx' ? (
          <ExcelIcon className="h-6 w-4 transition-all text-gray-300 hover:text-green" />
        ) : (
          <CSVIcon className="h-6 w-4 transition-all text-gray-300 hover:text-green" />
        )}
      </button>
      {!isTableExportAvailable && (
        <Popover tooltipRef={tooltipRef} tooltip={NOT_AVAILABLE_BECAUSE_TARIFF} />
      )}
      <Modal
        footer={false}
        width={isMobile ? undefined : 600}
        title="Создать отчет"
        isOpen={isOpened}
        closeModal={() => setIsOpened(false)}
      >
        <TableExportContent format={format} type={type} onHide={() => setIsOpened(false)} />
      </Modal>
    </div>
  );
};

TableExportButton.propTypes = {
  format: PropTypes.oneOf(['xlsx', 'csv']),
  type: PropTypes.oneOf([...Object.values(TABLE_TYPE), ...Object.values(TABLE_INNER_TYPE)])
    .isRequired,
};

export default TableExportButton;
