import { PAGE_ADVERTISING_RATES } from 'constants/pages';
import { UnauthorizedAdvertisingRates } from 'pages';
import { Route, Routes } from 'react-router';
import FrontLoginForm from './FrontLoginForm';

const FrontUnauthenticatedApp = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Routes>
        <Route path={PAGE_ADVERTISING_RATES} element={<UnauthorizedAdvertisingRates />} />
        <Route path="/" element={<FrontLoginForm />} />
      </Routes>
    </div>
  );
};

export default FrontUnauthenticatedApp;
