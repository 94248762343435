import cn from 'classnames';
import PropTypes from 'prop-types';
import { lazy, useState } from 'react';

import { barChartPropTypes } from 'components/BarChart';
import Card from 'components/Card';
import ColoredBack, { coloredBBackPropTypes } from 'components/ColoredBack';
import Info, { infoPropTypes } from 'components/Info';
import PieChartRating from 'components/PieChart/PieChartRating';
import { Skeleton } from '../Skeleton';

const BarChart = lazy(() => import('components/BarChart'));

const columnCardPropTypes = PropTypes.shape({
  title: PropTypes.any,
  labels: PropTypes.arrayOf(PropTypes.string),
  shortcut: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      chart: PropTypes.shape(barChartPropTypes),
      info: PropTypes.arrayOf(PropTypes.shape(infoPropTypes)),
      coloredBack: PropTypes.arrayOf(PropTypes.shape(coloredBBackPropTypes)),
      rating: PropTypes.shape({
        ratingKey: PropTypes.string.isRequired,
        reviewKey: PropTypes.string.isRequired,
      }),
    }),
  ),
  labelsDisabled: PropTypes.func,
  hideActiveTitle: PropTypes.bool,
  component: PropTypes.elementType,
});

const ColumnCard = ({
  card,
  isLoading,
  data,
  index,
  isQuestion,
  isQuestionToRight,
  questionText,
  width,
  activeDefault,
}) => {
  const [activeTitle, setActiveTitle] = useState(Array.isArray(card.title) ? 0 : null);
  const getValue = (key) => (data?.[key] !== undefined && data?.[key] !== null ? data?.[key] : '-');
  const getValueZero = (key) =>
    data?.[key] !== undefined && data?.[key] !== null ? data?.[key] : 0;
  const getFormattedValue = (key) =>
    data?.[key] !== undefined && data?.[key] !== null
      ? Number(data?.[key]).toLocaleString('ru')
      : '-';

  const [infoHovered, setInfoHovered] = useState(false);

  const infoEvents = {
    onMouseEnter: () => setInfoHovered(true),
    onMouseLeave: () => setInfoHovered(false),
  };
  return (
    <div {...infoEvents} style={{ width: width }}>
      <Card
        title={card.title}
        isQuestion={isQuestion}
        isQuestionToRight={isQuestionToRight}
        questionText={questionText}
        labels={card.labels}
        labelsDisabled={card.labelsDisabled}
        shortcut={card.shortcut}
        hideActiveTitle={card.hideActiveTitle}
        setActiveTitle={setActiveTitle}
        activeTitle={activeTitle}
        activeDefault={activeDefault}
        tabs={card.tabs.map((tab) => (
          <>
            {tab.chart && (
              <BarChart
                data={tab.chart.data}
                nameKey={tab.chart.nameKey}
                valueKey={tab.chart.valueKey}
                isLoading={isLoading}
              />
            )}
            {tab.info && (
              <div className="space-y-2.5">
                {tab.info.map((info, z) => (
                  <Info
                    key={`${index}-info-${z}`}
                    label={info.label}
                    externalLabel={info.externalLabel}
                    value={getValue(Array.isArray(info.key) ? info.key[activeTitle] : info.key)}
                    percentValue={
                      info.key_percent &&
                      getValue(
                        Array.isArray(info.key_percent)
                          ? info.key_percent[activeTitle]
                          : info.key_percent,
                      )
                    }
                    totalValue={info.totalKey && getValue(info.totalKey)}
                    shortcut={card.shortcut}
                    highlighted={info.highlighted}
                    type={info.type}
                    isQuestion={info.isQuestion}
                    isQuestionToRight={info.isQuestionToRight}
                    questionText={info.questionText}
                    isEditable={info.editable}
                    isSelectable={info.selectable}
                    options={info.options}
                    isAllActionsAvailable={info.allActions}
                    isLoading={isLoading}
                    hovered={infoHovered}
                    getDateInFormat={info.getDateInFormat}
                    onUpdate={info.onUpdate}
                    onCreateOption={info.onCreateOption}
                    onUpdateOption={info.onUpdateOption}
                    onDeleteOption={info.onDeleteOption}
                  />
                ))}
              </div>
            )}

            {tab.coloredBack && (
              <div className="grid gap-3 grid-cols-2 min-w-min">
                {tab.coloredBack.map((colored, x) => (
                  <div
                    className={cn({
                      ['col-span-2']: (x + 1) % 3 === 0,
                    })}
                    key={`${index}-colored-${x}`}
                  >
                    <ColoredBack
                      color={colored.color}
                      label={colored.label}
                      value={colored.value || getValue(colored.key)}
                      type={colored.type}
                      percent={colored.percent}
                      pie={colored.pie}
                      isLoading={isLoading}
                      tooltip={colored.tooltip}
                    />
                  </div>
                ))}
              </div>
            )}

            {tab.rating && (
              <div className="flex text-center divide-x divide-gray-200">
                <div className="flex-grow-0 w-6/12 flex flex-col items-center pr-4">
                  <PieChartRating
                    isLoading={isLoading}
                    rating={getValueZero(tab.rating.ratingKey)}
                  />
                  <div className="text-gray-400">Рейтинг</div>
                </div>
                <div
                  className={cn(
                    'justify-end pl-3 ml-3 text-center flex-grow flex flex-col items-center',
                    {
                      ['gap-3 ']: isLoading,
                    },
                  )}
                >
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    <div className="text-green text-xl mb-2">
                      {getFormattedValue(tab.rating.reviewKey)}
                    </div>
                  )}
                  <div className="text-gray-400">Отзывов</div>
                </div>
              </div>
            )}
          </>
        ))}
      />
    </div>
  );
};
ColumnCard.propTypes = {
  card: columnCardPropTypes,
  isLoading: PropTypes.bool,
  data: PropTypes.any,
  index: PropTypes.string.isRequired,
  isQuestion: PropTypes.bool,
  isQuestionToRight: PropTypes.bool,
  questionText: PropTypes.node,
  width: PropTypes.string,
  activeDefault: PropTypes.number,
};
const CardList = ({
  data,
  isLoading,
  columns,
  width,
  useWrapperStyles,
  isColGrow,
  colClassName,
}) => (
  <div
    className={cn({ 'bg-gray-100 border border-l-0 border-r-0 border-gray-200': useWrapperStyles })}
  >
    <div
      className={cn(
        'overflow-x-auto wrapper-wide flex space-x-4 justify-start flex-1 flex-nowrap touch-auto overflow-y-hidden xl:overflow-visible items-start py-4',
      )}
    >
      {columns.map((column, i) => (
        <div
          key={`column-${i}`}
          className={cn('min-w-fit space-y-4', {
            grow: isColGrow,
            [colClassName]: colClassName,
          })}
        >
          {column.map((card, j) => {
            const { component: Component } = card;
            if (Component) {
              return (
                <Component key={`column-${i}-card-${j}`} template={ColumnCard} width={width} />
              );
            }
            return (
              <ColumnCard
                key={`column-${i}-card-${j}`}
                isLoading={isLoading}
                card={card}
                data={data}
                index={`column-${i}-card-${j}`}
                isQuestion={card.isQuestion}
                isQuestionToRight={card.isQuestionToRight}
                questionText={card.questionText}
                width={width}
                activeDefault={card.activeDefault !== undefined ? card.activeDefault : 0}
              />
            );
          })}
        </div>
      ))}
    </div>
  </div>
);
CardList.defaultProps = {
  width: '330px',
  useWrapperStyles: true,
};
CardList.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  width: PropTypes.any,
  columns: PropTypes.arrayOf(PropTypes.arrayOf(columnCardPropTypes)).isRequired,
  useWrapperStyles: PropTypes.bool,
  isColGrow: PropTypes.bool,
  colClassName: PropTypes.string,
};
export default CardList;
