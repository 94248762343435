export const DATE_FORMAT = 'yyyy-MM-DD';

export const TARIFF_BASIC = 'Lite';
export const TARIFF_EXPERT = 'Expert';
export const TARIFF_PRO = 'Pro';
export const TARIFF_ADMIN = 'Admin';
export const TARIFF_TRIAL = 'Trial';
export const TARIFF_S24_TRIAL = 'S24 Trial';

export const F = {
  CATEGORY: 'category',
  BRAND: 'brand',
  SELLER: 'seller',
  PLATFORM: 'platform',
  SUBJECT: 'subject',
  WAREHOUSE: 'warehouse',
  WAREHOUSE_TYPE: 'warehouseType',
  DATE: 'date',
  IS_NEW: 'isNew',
  IS_PROMOTIONAL: 'isPromotional',
  HAS_ADVERTISING: 'hasAdvertising',
  SORT: 'sort',
  SORT_NESTED: 'sortNested',
  ORDER: 'order',
  ORDER_NESTED: 'orderNested',
  PRODUCT: 'product',
  PERIOD: 'period',
};

export const F_INNER = {
  CATEGORY: 'inner_category',
  BRAND: 'inner_brand',
  SUBJECT: 'inner_subject',
  API_KEYS: 'inner_api_keys',
  QUANTITY: 'quantity',
  USE_WAREHOUSE: 'inner_use_warehouse',
  WAREHOUSE: 'inner_warehouse',
  DELIVERY_DATE: 'inner_delivery_date',
  DELIVERY_STOCK_DATE: 'inner_delivery_stock_date',
  STATUS: 'status',
};
