import { useEffect, useState } from 'react';

import ConsultIcon from 'assets/icons/ConsultIcon';
import CrossIcon from 'assets/icons/CrossIcon';
import NewExclamationIcon from 'assets/icons/NewExclamationIcon';
import WindowIcon from 'assets/icons/WindowIcon';
import cn from 'classnames';
import Modal from 'components/Modal';
import { useAuth } from 'context/auth.context';
import { usePayment } from 'context/payment.context';
import { axiosClient } from 'hooks/useAxiosClient';
import { useMainLinkDefault } from 'hooks/useLocationPath';
import { useNavigate } from 'react-router';
import LoadingSpin from '../LoadingSpin';

const ExtTariffModal = () => {
  const navigate = useNavigate();
  const {
    openPaymentWithTariff,
    paymentRequestFields,
    isShowConsulting,
    setIsShowConsulting,
    isConsultLoading,
    setIsConsultLoading,
  } = usePayment();
  const { tariff, user } = useAuth();
  const [isExtTariffModal, setIsExtTariffModal] = useState(false);
  const mainLink = useMainLinkDefault();
  const handleClose = () => {
    setIsExtTariffModal(false);
  };

  useEffect(() => {
    if (tariff?.project_name === 'Расширение WB') {
      setIsExtTariffModal(true);
    }
  }, [tariff]);

  const handlePay = () => {
    navigate('/profile/subscriptions');
    setIsExtTariffModal(false);
    openPaymentWithTariff(paymentRequestFields[0].value);
  };

  const handleMain = () => {
    setIsExtTariffModal(false);
    navigate(mainLink);
  };

  const handleConsult = async () => {
    setIsExtTariffModal(false);
    const data = {
      name: user.name,
      email: user.email,
      comment: 'from service',
      type: 'demonstration',
    };
    setIsConsultLoading(true);
    axiosClient('/saveform', data, 'POST').then(() => setIsConsultLoading(false));

    setIsShowConsulting(true);
  };

  return (
    <>
      <Modal
        width={450}
        title={isConsultLoading ? 'Регистрируем Вашу заявку...' : 'Отлично, заявка принята!'}
        isOpen={isShowConsulting}
        disableCloseOnClickOutside={true}
        closeModal={() => setIsShowConsulting(false)}
        footer={false}
      >
        <LoadingSpin isVisible={isConsultLoading} />
        <article
          className={cn('grid gap-16 content-center justify-center', {
            ['hidden']: isConsultLoading,
          })}
        >
          <header className="font-SofiaPro text-gray-400 text-base text-center">
            Скоро мы с вами свяжемся и договоримся о месте и времени проведения консультации
          </header>
          <footer className="w-full">
            <button
              onClick={() => setIsShowConsulting(false)}
              type="button"
              className="bg-green w-full py-4 text-center text-white text-base rounded-full"
            >
              Хорошо, жду
            </button>
          </footer>
        </article>
      </Modal>
      <Modal isOpen={isExtTariffModal} closeModal={handleClose} footer={false} classNames={'!p-10'}>
        <CrossIcon
          className="w-4 h-4 hover:text-green absolute right-5 top-5 cursor-pointer"
          onClick={handleClose}
        />
        <div className="flex w-full">
          <div className="grow flex">
            <div className="text-center flex flex-col items-center justify-center w-full">
              <div className="rounded-[32px] py-[33px] px-[54px] bg-green-lightest w-fit">
                <NewExclamationIcon width={12} height={54} />
              </div>
              <span className="block font-bold text-[32px] leading-[38px] mt-8">
                У вас нет
                <br />
                оплаченной подписки
              </span>
              <span className="block text-lg text-gray-400 leading-[25px] mt-4">
                Для того, чтобы пользоваться
                <br />
                аналитикой WeCheck на сайте,
                <br />
                необходимо оплатить тариф
              </span>
              <button
                className="px-4 py-3 rounded-lg mt-8 bg-green text-white border-none shadow-none focus:outline-none"
                onClick={handlePay}
              >
                Оплатить тариф
              </button>
            </div>
          </div>
          <div className="border-l border-[#E8E8E8] pl-8">
            <div className="border-b border-[#E8E8E8] pr-[18px]">
              <div className="rounded-xl py-[11px] px-[13px] bg-green-lightest w-fit">
                <ConsultIcon width={18} height={22} />
              </div>
              <div className="mt-5 text-[#262626] font-bold text-lg leading-[22px]">
                <span>Закажите персональную</span>
                <br />
                <span>консультацию по возможностям</span>
                <br />
                <span>сервиса</span>
              </div>
              <span className="block mt-2 text-gray-400 text-base leading-[22px]">
                Наши эксперты продемонстрируют,
                <br /> как использовать платформу
                <br /> для увеличения продаж.
                <br /> Это бесплатно
              </span>
              <button
                className="px-4 py-3 rounded-lg mt-5 mb-6 bg-green text-white"
                onClick={handleConsult}
              >
                Заказать консультацию
              </button>
            </div>
            <div className="pr-[18px] mt-6">
              <div className="rounded-xl p-2.5 bg-green-lightest w-fit">
                <WindowIcon width={24} height={24} />
              </div>
              <div className="mt-5 text-[#262626] font-bold text-lg leading-[22px]">
                <span>А сейчас вам доступны</span>
                <br />
                <span>демоданные</span>
              </div>
              <span className="block mt-2 text-gray-400 text-base leading-[22px]">
                Изучайте интерфейс сервиса
              </span>
              <button
                className="px-4 py-3 rounded-lg mt-5 mb-6 bg-green text-white"
                onClick={handleMain}
              >
                На главную
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ExtTariffModal;
