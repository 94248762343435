import cn from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router';

import { PROMO_CODE_MODAL, useUI } from 'context/ui.context';

import SearchInput from '../SearchInput';
import {
  DatePickerPanel,
  MobileMenu,
  PlatformPanel,
  PromoCodePanel,
  WarehousesPanel,
} from './components';

import { ReactComponent as CrossImage } from 'assets/images/crossTariffs.svg';
import { usePayment } from 'context/payment.context';
import './MobileInterface.css';

/**
 * @typedef IMobileInterfaceV2Props
 *
 * @property {import('react').ReactNode} children
 * @property {Function} onPanelClose Функция, которая будет вызвана при нажатии на "крестик"
 * @property {boolean} isActive Флаг, определяющий видимость модального окна
 * @property {string} [headerText] Опциональный текст, если передан - будет отрисован перед кнопкой закрытия модального окна
 * @property {string} [overlayClassName] Классы, которые будут добавлены к контейнеру, создающему фон
 * @property {string} [rootClassName] Классы, которые будут добавлены к контейнеру, внутри которого находиться `children`
 *
 * */
/**
 * @returns {JSX.Element} Компонент, котрый создает модальное окно с базовыми анимациями
 * @param {IMobileInterfaceV2Props} props
 * */
export function MobileInterfaceV2({
  onPanelClose,
  children,
  isActive,
  overlayClassName,
  rootClassName,
  headerText,
}) {
  const el = useRef(document.createElement('div'));
  const { isMobile } = useUI();

  const onClose = () => {
    if (onPanelClose) onPanelClose();
  };

  useEffect(() => {
    const current = el.current;
    document.body.appendChild(current);

    return () => {
      document.body.removeChild(current);
    };
  }, []);

  return ReactDOM.createPortal(
    isMobile && isActive && (
      <div
        style={{ background: 'rgba(0, 0, 0, 0.25)' }}
        className={cn('h-screen z-[10101] w-screen fixed bottom-0', {
          [overlayClassName]: overlayClassName,
          ['animate-modal-mobile-show-overlay']: isActive,
        })}
      >
        <div
          className={cn('bg-white flex flex-col z-[10102] w-screen origin-bottom', {
            [rootClassName]: rootClassName,
            ['animate-modal-mobile-show-inner']: isActive,
          })}
        >
          <header
            className={cn('flex h-fit justify-between items-center p-4 border-b-2', {
              ['hidden']: !headerText,
            })}
          >
            <div className="font-bold text-[16px]">{headerText || ''}</div>
            <button type="button" className="flex justify-end w-10" onClick={onClose}>
              <CrossImage className="w-4 h-4 fill-[#219653]" />
            </button>
          </header>
          {children}
        </div>
      </div>
    ),
    el.current,
  );
}

MobileInterfaceV2.propTypes = {
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onPanelClose: PropTypes.func,
  headerText: PropTypes.any,
  rootClassName: PropTypes.string,
  overlayClassName: PropTypes.string,
};

const MobileInterface = () => {
  const { activeModal, setActiveModal } = useUI();
  const { hidePromoField } = usePayment();
  const didMount = useRef(null);
  const location = useLocation();
  const handleCloseModal = () => {
    setActiveModal('');

    if (typeof components[activeModal]?.onClose === 'function') {
      components[activeModal]?.onClose();
    }
  };
  const components = {
    calendar: {
      component: <MobileMenu />,
    },
    platform: {
      component: <PlatformPanel />,
      rootClassName: 'bottom-0 left-0 h-1/3',
      header: {
        title: 'Выбор платформы',
      },
    },
    warehouses: {
      rootClassName: 'bottom-0 left-0 h-1/2',
      component: <WarehousesPanel />,
      header: {
        title: 'Выбор склада',
      },
    },
    menu: {
      component: <MobileMenu />,
      rootClassName: 'h-full bottom-0 left-0',
      header: {
        title: 'Меню',
      },
    },
    datePicker: {
      component: <DatePickerPanel />,
      rootClassName: 'bottom-0 left-0 h-[35%]',
      header: {
        title: 'Выбор периода',
      },
    },
    search: {
      overlayClassName: 'h-[calc(100vh-16rem)] bottom-0',
      rootClassName: 'top-16 left-0 h-full',
      component: <SearchInput setIsExpanded={handleCloseModal} />,
    },
    [PROMO_CODE_MODAL]: {
      component: <PromoCodePanel />,
      rootClassName: 'bottom-0 left-0',
      header: {
        title: 'Добавить промокод',
      },
      onClose: hidePromoField,
    },
  };

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    } else {
      setActiveModal('');
    }
  }, [location, setActiveModal]);

  useEffect(() => {
    if (activeModal) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = '';
    }
  }, [activeModal]);

  if (!activeModal) return null;

  return (
    <div
      style={{
        background: 'rgba(0, 0, 0, 0.25)',
      }}
      onClick={handleCloseModal}
      className={cn('fixed h-screen z-[10101] w-screen', {
        [components[activeModal].overlayClassName]: components[activeModal].overlayClassName,
      })}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={cn(
          'fixed bg-white flex flex-col w-full z-[10102]',
          components[activeModal].rootClassName,
        )}
      >
        {components[activeModal].header && (
          <div className="flex justify-between items-center p-4 border-b-2">
            <div className="font-bold text-[16px]">{components[activeModal].header.title}</div>
            <button onClick={handleCloseModal}>
              <CrossImage className="w-4 h-4 fill-green" />
            </button>
          </div>
        )}
        {components[activeModal].component}
      </div>
    </div>
  );
};

export default MobileInterface;
