import NavList from 'components/NavList';
import { BRAND, CATEGORY, SELLER, SUBJECT } from 'constants/navColors';
import {
  PAGE_BRANDS,
  PAGE_MAIN,
  PAGE_PRICE_SEGMENTS,
  PAGE_PRODUCTS,
  PAGE_SALES,
  PAGE_SELLERS,
  PAGE_SUBJECTS,
  PAGE_TRENDS,
  PAGE_WAREHOUSES,
} from 'constants/pages';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUserTariffs } from 'context/user.tariffs.context';

const TableNav = () => {
  const { filter, updateChips, isOzonPlatform } = useFilterReducer();

  const { isSellersAvailable, checkDynamicAvailability, isPriceSegmentsAvailable } =
    useUserTariffs();

  const { isDynamicAvailableByTariff, isDynamicAvailableByFilter, isDynamicAvailable } =
    checkDynamicAvailability(filter);

  const handleClean = (key) => {
    updateChips({ [key]: [] });
  };

  return (
    <NavList
      items={[
        {
          link: PAGE_MAIN,
          title: 'Категории',
          label: {
            value: filter?.category?.length || 0,
            bg: CATEGORY,
            onClick: () => handleClean('category'),
          },
        },
        {
          link: PAGE_SUBJECTS,
          title: 'Предметы',
          label: {
            value: filter?.subject?.length || 0,
            bg: SUBJECT,
            onClick: () => handleClean('subject'),
          },
        },
        {
          link: PAGE_SELLERS,
          title: 'Продавцы',
          isAvailable: isSellersAvailable,
          label: {
            value: filter?.seller?.length || 0,
            bg: SELLER,
            onClick: () => handleClean('seller'),
          },
        },
        {
          link: PAGE_BRANDS,
          title: 'Бренды',
          label: {
            value: filter?.brand?.length || 0,
            bg: BRAND,
            onClick: () => handleClean('brand'),
          },
        },
        {
          link: PAGE_PRODUCTS,
          title: 'Товары',
        },
        {
          link: PAGE_TRENDS,
          title: 'Тренды',
          single: true,
          isAvailable: isDynamicAvailable,
          tip:
            isDynamicAvailableByTariff && !isDynamicAvailableByFilter
              ? 'Выберите категорию, бренд или продавца для изучения трендов'
              : '',
        },
        {
          link: PAGE_WAREHOUSES,
          title: 'Склады',
          hidden: isOzonPlatform,
          single: true,
          isAvailable: isDynamicAvailable,
          tip:
            isDynamicAvailableByTariff && !isDynamicAvailableByFilter
              ? 'Выберите категорию, бренд или продавца для изучения трендов'
              : '',
        },
        {
          link: PAGE_PRICE_SEGMENTS,
          title: 'Ценовая сегментация',
          isAvailable: isPriceSegmentsAvailable,
          single: true,
        },
        {
          link: PAGE_SALES,
          title: 'По дням',
          single: true,
        },
      ]}
    />
  );
};

export default TableNav;
