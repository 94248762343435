import Table from 'components/Table';
import { COLUMNS } from 'constants';
import { useQuerySeo } from 'hooks/useFetchSeo';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { getClusterResultsByType } from './utils';
const ClustersResults = () => {
  const searchParams = new URLSearchParams(document.location.search);

  const { tab } = useParams();
  const { tableType, seoQueryProps, prepareData } = getClusterResultsByType(tab, searchParams);
  const {
    data = [],
    refetch,
    isLoading,
    isFetching,
  } = useQuerySeo({ type: tab, ...seoQueryProps });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const { data: result, pagination } = prepareData(data);
  return (
    <Table
      data={result}
      isLoading={isLoading || isFetching}
      totalPages={pagination?.total_pages}
      paginationData={{ page: pagination?.page }}
      type={tableType}
      autoSizeColumns={[COLUMNS.PHRASE_NAME, COLUMNS.CLUSTER_NAME]}
      hideFilterPanel={true}
      noRowsText={'Данные отсутствуют'}
    />
  );
};

export { ClustersResults };
