import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import cn from 'classnames';
import { useVideoLessons } from 'context/video.lessons.context';
import Modal from '../Modal';

const VideoLessonsModal = () => {
  const { isModalOpen, setIsModalOpen, tabs, selectedTab, setSelectedTab } = useVideoLessons();

  return (
    <Modal
      isOpen={isModalOpen}
      title={'Видеоуроки'}
      closeModal={() => setIsModalOpen(false)}
      footer={false}
      width={1200}
    >
      <TabGroup
        selectedIndex={selectedTab}
        onChange={setSelectedTab}
        vertical
        className="flex -m-6"
      >
        <TabList
          className={`border-b-2 lg:border-b-0 border-gray-150 lg:border-r
              flex-none flex lg:flex-col lg:w-[293px]
              px-5 lg:px-0 lg:pt-4 space-x-5 lg:space-x-0
              overflow-x-scroll lg:overflow-x-visible`}
        >
          {tabs.map(({ id, label, count, available, lvl }) =>
            available ? (
              <div key={`tab-${id}`} className="relative w-full">
                <Tab
                  className={({ selected }) =>
                    cn('pl-1 lg:pr-16 py-4 lg:py-3 text-left hover:text-green w-[calc(100%+1px)]', {
                      'text-green border-b-2 lg:border-b-0 lg:border-r-2 border-green lg:bg-green-100 mb-[-2px] lg:mb-0':
                        selected,
                      'text-black': !selected,
                      'pr-14': count,
                      'pr-1': !count,
                      [`lg:pl-${lvl * 6}`]: lvl,
                    })
                  }
                >
                  {label}
                </Tab>
              </div>
            ) : null,
          )}
        </TabList>

        <TabPanels className="h-full lg:max-h-full lg:h-[80vh] lg:flex-1 px-4 lg:p-6 overflow-y-auto">
          {tabs.map(({ available, panel: PanelComponent, id, label }) =>
            available ? (
              <TabPanel key={`panel-${id}`} className="h-full flex flex-col">
                <PanelComponent setSelectedTab={setSelectedTab} label={label} />
              </TabPanel>
            ) : null,
          )}
        </TabPanels>
      </TabGroup>
    </Modal>
  );
};

export default VideoLessonsModal;
