import Table from 'components/Table';
import { TABLE_TYPE } from 'constants';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useQuerySeo } from 'hooks/useFetchSeo';
import { useEffect } from 'react';

const SearchContent = () => {
  const { filter } = useFilterReducer();

  const {
    data = [],
    refetch,
    isLoading,
  } = useQuerySeo({
    type: TABLE_TYPE.SEO_SEARCH,
    filter: {
      platform: ['2'],
      date: filter.date,
    },
    sort: filter.sort || 'frequency_wb',
    order: filter.order,
  });

  useEffect(() => {
    if (filter.date.length !== 0) {
      refetch();
    }
  }, [refetch, filter.date, filter.sort, filter.order]);

  return (
    <Table
      data={data}
      type={TABLE_TYPE.SEO_SEARCH}
      isLoading={isLoading}
      noRowsText={`По вашему запросу нет данных.`}
    />
  );
};

export default SearchContent;
