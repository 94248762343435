import cn from 'classnames';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import { PAGE_PRODUCTS } from 'constants/pages';
import { COLUMNS } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';

function getRange(value) {
  if (!value) return null;

  const range = value.split('-');
  let from = parseFloat(range[0]);
  let to = parseFloat(range[1]);

  if (!Number.isInteger(from)) {
    from = '';
  }

  if (!Number.isInteger(to)) {
    to = '';
  }

  return [from, to];
}

const TableCellProductsPriceRangeLink = ({ value, valueFormatted }) => {
  const navigate = useNavigate();
  const { addFilterRange, setSort } = useFilterReducer();

  const cellValue = value || valueFormatted;

  const range = getRange(cellValue);

  const handleFilterUpdate = () => {
    if (!range) return;

    addFilterRange({ value: range, key: COLUMNS.PRICE });
    setSort({ sort: COLUMNS.ORDERS_RUB_, order: 'desc' });

    navigate(PAGE_PRODUCTS);
  };

  return (
    <button
      className={cn({
        ['cursor-pointer text-green hover:text-green hover:underline']: !!range.length,
      })}
      onClick={handleFilterUpdate}
    >
      {cellValue}
    </button>
  );
};

TableCellProductsPriceRangeLink.propTypes = {
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
};

export default TableCellProductsPriceRangeLink;
