import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router';

import AuthorizationForm from 'components/AuthorizationForm';
import { useAuth } from 'context/auth.context';

const ResetPassword = () => {
  const { recoverPassword } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const [params, setParams] = useState({
    token: '',
    email: '',
  });

  const handleSubmit = (values) => {
    setError(null);

    return recoverPassword({
      token: params.token,
      email: params.email,
      password: values.password,
      passwordConfirmation: values.rePassword,
    })
      .then((data) => {
        if (('success' in data && !data.success) || data.status !== 200) {
          setError(data?.status || 'Что пошло не так. Поробуйте позже ещё раз.');
        } else {
          setError(null);
        }
        return data;
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  useEffect(() => {
    setParams({
      token: searchParams.get('token'),
      email: searchParams.get('email'),
    });

    navigate({ search: '' }, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <AuthorizationForm
      fields={[
        {
          name: 'password',
          label: 'Новый пароль',
          type: 'password',
          checkStrength: true,
        },
        {
          name: 'rePassword',
          label: 'Повторить',
          type: 'password',
        },
      ]}
      initialValues={{
        password: '',
        rePassword: '',
      }}
      onSubmit={handleSubmit}
      submitLabel="Сохранить"
      showNavigation={false}
      title="Изменить пароль"
      successMessage="Ваш пароль успешно обновлен."
      superError={error}
      showButtonLoginOnMessage={true}
    />
  );
};

export default ResetPassword;
