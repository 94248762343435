import { Popover as PopoverHeadless, PopoverPanel } from '@headlessui/react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
/**
 * @typedef IProps
 * @property {string} tooltip Содержимое компонента (то, что будет отрисовано внутри всплывашки)
 * @property {import("react").MutableRefObject<HTMLDivElement>} tooltipRef REF, к которому будет "прицеплено" всплывающее сообщение
 * @property {string} [className] Классы, которые буду дополнительно применены к компоненту
 * @property [offset] Отступ, который будет применен к компоненту (по отношению к родителю)
 * */
/**
 * @return {JSX.Element} Всплывающее сообщение, которое содержит некоторое сообшение
 * @param {IProps} props
 * */
const Popover = ({ tooltipRef, tooltip, className, offset }) => {
  const [popperElement, setPopperElement] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const { styles, attributes } = usePopper(tooltipRef?.current, popperElement, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset,
        },
      },
    ],
  });

  const show = () => setIsOpen(true);
  const hide = () => setIsOpen(false);

  useEffect(() => {
    if (!tooltipRef) return;

    const button = tooltipRef.current;

    button.addEventListener('mouseenter', show);
    button.addEventListener('mouseleave', hide);

    return () => {
      button.removeEventListener('mouseenter', show);
      button.removeEventListener('mouseleave', hide);
    };
  }, [tooltipRef]);

  return (
    <PopoverHeadless className="relative" placement="top">
      {isOpen && (
        <PopoverPanel
          static
          ref={setPopperElement}
          style={styles.popper}
          placement="top"
          {...attributes.popper}
          className={cn(
            'absolute z-10 bg-white rounded shadow-md p-3 z-[100] text-gray-500 text-sm leading-5 bg-opacity-90',
            {
              [className]: className,
            },
          )}
        >
          <div dangerouslySetInnerHTML={{ __html: tooltip }} />
        </PopoverPanel>
      )}
    </PopoverHeadless>
  );
};
Popover.defaultProps = {
  className: 'min-w-[250px]',
  offset: [0, 14],
};
Popover.propTypes = {
  tooltip: PropTypes.string.isRequired,
  tooltipRef: PropTypes.object.isRequired,
  className: PropTypes.string,
  offset: PropTypes.arrayOf(PropTypes.number),
};
export default Popover;
