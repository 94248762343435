import cn from 'classnames';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { DATE_FORMAT } from 'constants/filter';
import { TABLE_TYPE } from 'constants/table';
import { useUI } from 'context/ui.context';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';
import { declOfNum } from 'utils/client';

import Arrow from 'assets/icons/Arrow';
import CategoryLink from 'components/Links/CategoryLink';
import Loading from 'components/Loading';
import { useFilterReducer } from 'context/filter/filter.context.reducer';

const LISTING_DATE = [
  moment().subtract(7, 'days').format(DATE_FORMAT),
  moment().subtract(1, 'days').format(DATE_FORMAT),
];

const getCategoriesListing = (tableData) => {
  if (!tableData?.length) return { min: null, categories: [] };

  let minListingIndex = 0;
  const result = [];

  let minListing = null;

  tableData?.forEach((item, index) => {
    const category = item?.category;
    const category_id = item?.category_id;
    const listing = item?.listing
      .map((item) => {
        const { listing } = item || {};
        return listing;
      })
      ?.slice()
      ?.reverse()
      .reduce((acc, cur) => (acc === null ? cur : acc));

    const data = { category, category_id, listing };

    if (!minListing || listing < minListing.listing) {
      minListingIndex = index;
      minListing = data;
    }

    result.push(data);
  });

  return {
    min: minListing,
    categories: [...result.slice(0, minListingIndex), ...result.slice(minListingIndex + 1)],
  };
};

const ProductListingList = () => {
  const { id } = useParams();
  const { isMobile } = useUI();

  const { filter } = useFilterReducer();
  const {
    data: listingData,
    error,
    isLoading,
  } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_LISTING,
    date: filter.date || LISTING_DATE,
    id,
  });

  const tableData = listingData?.table_data;

  const [showFullListingList, setShowFullListingList] = useState(false);

  const { min, categories } = getCategoriesListing(tableData);
  const computedPlaceInCategory = useCallback((condition, placeBeforeResult) => {
    if (condition) return `${placeBeforeResult} место в категории:`;

    return 'в категории';
  }, []);

  if (isLoading) return <Loading />;

  if (!min || error || !min.listing) return <></>;

  return (
    <div className="pb-3 lg:pb-0">
      <div className="flex w-full flex-col lg:flex-row lg:items-center pt-3 lg:py-2">
        <div className="flex flex-col lg:flex-row lg:items-center">
          <div className="text-green text-base pr-3">
            {computedPlaceInCategory(min.listing || false, min.listing || false)}
          </div>
          <div className="lg:mr-2">
            <CategoryLink id={min.category_id} label={min.category} />
          </div>
        </div>
        {!!categories.length && (
          <>
            <div
              className={cn(
                'flex items-center text-gray-400 lg:pl-2 lg:hover:text-green lg:border-l lg:border-gray-200 lg:cursor-pointer mt-1 lg:mt-0',
                {
                  ['order-1 lg:order-none']: showFullListingList,
                },
              )}
              onClick={() => setShowFullListingList(!showFullListingList)}
            >
              Еще в {categories.length}{' '}
              {declOfNum(categories.length, ['категории', 'категориях', 'категориях'])}
              <Arrow
                strokeWidth={1}
                className={cn('ml-2 transition-all', {
                  ['rotate-[270deg]']: showFullListingList,
                  ['rotate-90']: !showFullListingList,
                })}
              />
            </div>
          </>
        )}
      </div>
      {showFullListingList && (
        <div className="flex-wrap flex flex-col lg:flex-row lg:items-center mt-2 lg:mt-1">
          {categories.map((item, index) => (
            <div
              className="flex flex-col mb-2 lg:mb-3 lg:w-3/6 lg:flex-row"
              key={`category${index}`}
            >
              {item.listing && (
                <div className="text-green text-md lg:py-1 leading-none lg:rounded lg:px-2 lg:bg-green-lightest">
                  {isMobile
                    ? computedPlaceInCategory(item.listing || false, item.listing || false)
                    : `#${item.listing}`}
                </div>
              )}
              <div className="lg:pl-3 lg:flex lg:items-center">
                <CategoryLink id={item.category_id} label={item.category} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductListingList;
