import Block from 'components/Block';
import BlockStats from 'components/Block/BlockStats';
import LoadingSpin from 'components/LoadingSpin';
import { useSeoProductContext } from 'context/seo.product.context';
import { countPercent } from 'helpers/utils';
import PropTypes from 'prop-types';

const SeoProductSearchPhraseOverview = () => {
  const { queryResults, totalPhrases, isLoading } = useSeoProductContext();
  const { stats } = queryResults || {};

  const total = totalPhrases;

  if (isLoading) {
    return (
      <div style={{ height: 168 }}>
        <LoadingSpin />
      </div>
    );
  }
  return (
    <div className="flex space-between space-x-4">
      <div className="flex-none w-full lg:w-2/4">
        <Block type={'inner'} label={'Описание'}>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
            <BlockStatsCalculate label={'Запросы'} value={stats?.in_card_total} total={total} />
            <BlockStatsCalculate
              label={'Полные Запросы'}
              value={stats?.full_in_card_total}
              total={total}
            />
            <BlockStatsCalculate
              label={'Есть слова'}
              value={stats?.all_words_total}
              total={total}
            />
            <BlockStatsCalculate
              label={'Упущ. запросы'}
              value={stats?.missing_phrases}
              total={total}
            />
          </div>
        </Block>
      </div>
      <div className="flex-none w-full lg:w-1/5">
        <Block type={'inner'} label={'Частота запросов'}>
          <div className="grid grid-cols-2 gap-4">
            <BlockStats label={'Вписанные'} value={stats?.full_frequency_wb} />
            <BlockStats label={'Упущенные'} value={stats?.missing_frequency_wb} />
          </div>
        </Block>
      </div>
    </div>
  );
};

export default SeoProductSearchPhraseOverview;

const BlockStatsCalculate = ({ label, value, total }) => {
  const percent = countPercent(value, total);
  return (
    <BlockStats
      label={label}
      value={value + '/' + total}
      progress={percent + '%'}
      isUp={percent > 90}
    />
  );
};

BlockStatsCalculate.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};
