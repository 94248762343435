import CardList from 'components/CardList';
import { COLUMNS } from 'constants';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { transformDate } from 'helpers/utils';
import { useProductSettings } from 'hooks/useProductSettings';
import { useSuppliers } from 'hooks/useSuppliers';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import baseInnerClient from 'services/baseInnerClient';

const InnerProductCardList = ({ data, isLoading, barcode }) => {
  const { filter } = useFilterReducer();
  const { settings = {}, setSetting } = useProductSettings({ id: barcode });
  const { suppliers, updateSupplier, deleteSupplier, addSupplier } = useSuppliers();

  const getPercentValue = (value) => {
    if (value === undefined) return '-';
    if (typeof value === 'number') {
      if (Number.isInteger(value)) {
        return value;
      } else {
        return value.toFixed(2);
      }
    }
  };

  const handleUpdateSetting = async (key, value, isOption = false) => {
    const { status } = await baseInnerClient.updateInnerProductSettings({
      id: barcode,
      data: {
        [key === COLUMNS.SUPPLIER ? `${COLUMNS.SUPPLIER}_id` : key]:
          isOption && value ? value.id : value,
      },
    });
    if (status === 204) setSetting(key, value);
  };

  const handleCreateSupplier = async (key, value) => {
    const { status, data } = await baseInnerClient.createSupplier({ data: { name: value } });
    if (status === 201) {
      const { id, name } = data?.data;
      addSupplier(id, name);
      handleUpdateSetting(key, { id, name }, true);
    }
  };

  const handleUpdateSupplier = async (key, id, name) => {
    const { status } = await baseInnerClient.updateSupplier({ id, data: { name } });
    if (status === 204) {
      setSetting(key, { id, name });
      updateSupplier(id, name);
    }
  };

  const handleDeleteSupplier = async (key, id) => {
    const { status } = await baseInnerClient.deleteSupplier({ id });
    if (status === 204) {
      if (settings[key]?.id === id) {
        setSetting(key, null);
      }
      deleteSupplier(id);
    }
  };

  return (
    <CardList
      data={{ ...data, ...settings }}
      isLoading={isLoading}
      width={'auto'}
      isColGrow
      columns={[
        // column first
        [
          {
            title: ['Заказы', 'Продажи', 'Прибыль'],
            labels: ['Руб', 'Шт'],
            labelsDisabled: (activeTitle, labelIndex) => activeTitle === 2 && labelIndex === 1,
            shortcut: true,
            tabs: [
              {
                info: [
                  {
                    label: 'Вчера',
                    key: [
                      COLUMNS.ORDERS_YESTERDAY_RUB,
                      COLUMNS.SALES_YESTERDAY_RUB,
                      COLUMNS.PAYOFF_YESTERDAY,
                    ],
                    type: 'currency',
                  },
                  {
                    label: 'За&nbsp;7&nbsp;дней',
                    key: [COLUMNS.ORDERS_7D_RUB, COLUMNS.SALES_7D_RUB, COLUMNS.PAYOFF_7],
                    type: 'currency',
                  },
                  {
                    label: transformDate(filter.date),
                    key: [
                      COLUMNS.ORDERS_PERIOD_RUB,
                      COLUMNS.SALES_PERIOD_RUB,
                      COLUMNS.PAYOFF_PERIOD,
                    ],
                    highlighted: true,
                    type: 'currency',
                    shortcut: true,
                  },
                ],
              },
              {
                info: [
                  {
                    label: 'Вчера',
                    key: [
                      COLUMNS.ORDERS_YESTERDAY_QTY,
                      COLUMNS.SALES_YESTERDAY_QTY,
                      COLUMNS.RETURNS_YESTERDAY_QTY,
                    ],
                    type: 'amount',
                  },
                  {
                    label: 'За&nbsp;7&nbsp;дней',
                    key: [COLUMNS.ORDERS_7D_QTY, COLUMNS.SALES_7D_QTY, COLUMNS.RETURNS_7D_QTY],
                    type: 'amount',
                  },
                  {
                    label: transformDate(filter.date),
                    key: [
                      COLUMNS.ORDERS_PERIOD_QTY,
                      COLUMNS.SALES_PERIOD_QTY,
                      COLUMNS.RETURNS_PERIOD_QTY,
                    ],
                    highlighted: true,
                    shortcut: true,
                    type: 'amount',
                  },
                ],
              },
            ],
          },
          {
            title: 'Возможности',
            labels: [/*'%',*/ 'Руб', 'Шт'],
            shortcut: true,
            tabs: [
              /* {
                info: [
                  {
                    label: 'Упущенные&nbsp;заказы',
                    key: COLUMNS.LOST_PERCENT_ORDERS,
                    type: 'percent',
                  },
                  {
                    label: 'Упущенная&nbsp;прибыль',
                    key: COLUMNS.LOST_REVENUE_ORDERS,
                    type: 'percent',
                  },
                  {
                    label: 'Потенциал',
                    key: '',
                    type: 'percent',
                  },
                ],
              },*/
              {
                info: [
                  {
                    label: `<span>Упущенные заказы <span class='text-green'>&middot;</span> <span class='text-green'>${getPercentValue(
                      data?.[COLUMNS.LOST_PERCENT_ORDERS],
                    )}%</span></span>`,
                    key: COLUMNS.LOST_RUB_ORDERS,
                    type: 'currency',
                  },
                  {
                    label: `<span>Упущенная прибыль <span class='text-green'>&middot;</span> <span class='text-green'>${getPercentValue(
                      data?.[COLUMNS.LOST_REVENUE_ORDERS],
                    )}%</span></span>`,
                    key: COLUMNS.LOST_REVENUE,
                    type: 'currency',
                  },
                  {
                    label: 'Потенциал прибыли',
                    key: 'potencial_profit',
                    type: 'currency',
                  },
                ],
              },
              {
                info: [
                  {
                    label: `<span>Упущенные заказы <span class='text-green'>&middot;</span> <span class='text-green'>${getPercentValue(
                      data?.[COLUMNS.LOST_PERCENT_ORDERS],
                    )}%</span></span>`,
                    key: COLUMNS.LOST_QTY_ORDERS,
                    type: 'amount',
                  },
                  {
                    label: `<span>Упущенная прибыль <span class='text-green'>&middot;</span> <span class='text-green'>${getPercentValue(
                      data?.[COLUMNS.LOST_REVENUE_ORDERS],
                    )}%</span></span>`,
                    key: '',
                    type: 'amount',
                  },
                  {
                    label: 'Потенциал прибыли',
                    key: '',
                    type: 'amount',
                  },
                ],
              },
            ],
          },
        ],

        // column second
        [
          {
            title: 'Экономика',
            labels: [/*'%', 'Шт',*/ 'Руб'],
            tabs: [
              /*{
                info: [
                  {
                    label: 'Прибыль',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'Маржа',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'Рентабельность',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'Комиссия',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'Логистика',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                ],
              },
              {
                info: [
                  {
                    label: 'Прибыль',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'Маржа',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'Рентабельность',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'Комиссия',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'Логистика',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                ],
              },*/
              {
                info: [
                  // {
                  //   label: 'Прибыль',
                  //   key: COLUMNS.PAYOFF,
                  //   type: 'currency',
                  // },
                  {
                    label: `<span>Маржа <span class='text-green'>&middot;</span> <span class='text-green'>${getPercentValue(
                      data?.[COLUMNS.MARGINALITY],
                    )}%</span></span>`,
                    key: COLUMNS.MARGIN,
                    type: 'currency',
                  },
                  {
                    label: 'Рентабельность',
                    key: COLUMNS.PROFITABILITY,
                    type: 'percent',
                  },
                  {
                    label: `<span>Комиссия <span class='text-green'>&middot;</span> <span class='text-green'>${getPercentValue(
                      data?.['commission_percent'],
                    )}%</span></span>`,
                    key: 'commission',
                    type: 'currency',
                  },
                  {
                    label: 'Логистика',
                    key: COLUMNS.LOGISTIC,
                    type: 'currency',
                  },
                ],
              },
            ],
          },
          // {
          //   title: 'Оборачиваемость',
          //   labels: ['Суток'],
          //   tabs: [
          //     {
          //       info: [
          //         {
          //           label: 'По заказам',
          //           key: COLUMNS.TURN_AROUND_ORDERS,
          //           type: '',
          //         },
          //         {
          //           label: 'По продажам',
          //           key: COLUMNS.TURN_AROUND_SALES,
          //           type: '',
          //         },
          //       ],
          //     },
          //   ],
          // },
          {
            title: 'Выкупы',
            labels: ['%'],
            shortcut: true,
            tabs: [
              {
                info: [
                  {
                    label: 'Всего',
                    key: COLUMNS.BUYOUT,
                    type: 'percent',
                  },
                  {
                    label: 'С возвратами',
                    key: COLUMNS.BUYOUT_WITH_REFUND,
                    type: 'percent',
                  },
                ],
              },
            ],
          },
        ],
        // column third
        [
          {
            title: 'Цена',
            labels: [/*'%', 'Шт',*/ 'Руб'],
            tabs: [
              /*{
                info: [
                  {
                    label: 'До скидки',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'СПП',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'Промокод',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'К оплате покупателем',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'Для выплаты',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                ],
              },
              {
                info: [
                  {
                    label: 'До скидки',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'СПП',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'Промокод',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'К оплате покупателем',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                  {
                    label: 'Для выплаты',
                    key: COLUMNS.LOST_ORDERS_PERCENT,
                    type: 'percent',
                  },
                ],
              },*/
              {
                info: [
                  {
                    label: `<span>Цена со скидкой <span class='text-green'>&middot;</span> <span class='text-green'>${getPercentValue(
                      data?.[COLUMNS.DISCOUNT],
                    )}%</span></span>`,
                    key: COLUMNS.RETAIL_PRICE,
                    type: 'currency',
                  },
                  // {
                  //   label: 'Для выплаты',
                  //   key: COLUMNS.PRICE_WITH_DISC,
                  //   type: 'currency',
                  // },
                  {
                    label: `<span>Цена с СПП <span class='text-green'>&middot;</span> <span class='text-green'>${getPercentValue(
                      data?.[COLUMNS.SPP_PERCENT],
                    )}%</span></span>`,
                    key: COLUMNS.PRICE_WITH_SPP,
                    type: 'currency',
                  },

                  // {
                  //   label: 'Промокод',
                  //   key: '',
                  //   type: 'currency',
                  // },
                  {
                    // label: `<span>До скидки <span class='text-green'>&middot;</span> <span class='text-green'>${getPercentValue(
                    //   data?.[COLUMNS.DISCOUNT],
                    // )}%</span></span>`,
                    label: `До скидки`,
                    key: COLUMNS.TOTAL_PRICE,
                    type: 'currency',
                  },
                ],
              },
            ],
          },
          {
            title: 'В среднем',
            labels: ['Шт', 'Руб'],
            shortcut: true,
            tabs: [
              {
                info: [
                  {
                    label: 'Заказов в сутки',
                    key: COLUMNS.AVG_ORDERS_QTY,
                    type: 'amount',
                  },
                  {
                    label: 'Заказов когда в наличии',
                    key: COLUMNS.AVG_STOCK_QTY,
                    type: 'amount',
                  },
                  {
                    label: 'Продаж в сутки',
                    key: COLUMNS.SALES_SPEED,
                    type: 'amount',
                  },
                ],
              },
              {
                info: [
                  {
                    label: 'Заказов в сутки',
                    key: COLUMNS.AVG_ORDERS_RUB,
                    type: 'currency',
                  },
                  {
                    label: 'Заказов когда в наличии',
                    key: COLUMNS.AVG_STOCK_RUB,
                    type: 'currency',
                  },
                  {
                    label: 'Продаж в сутки',
                    key: 'sales_speed_rub',
                    type: 'currency',
                  },
                ],
              },
            ],
          },
        ],
        // column fourth
        [
          {
            title: 'Наличие',
            labels: ['Дней'],
            shortcut: true,
            tabs: [
              {
                info: [
                  {
                    label: 'Дней в наличии',
                    key: COLUMNS.DAYS_ON_AVAILABLE,
                    totalKey: COLUMNS.PERIOD,
                    type: '',
                  },
                  {
                    label: `<span>Дней с заказами <span class='text-green'>&middot;</span> <span class='text-green'>${getPercentValue(
                      data?.[COLUMNS.DAYS_WIDTH_ORDERS_PERCENT],
                    )}%</span></span>`,
                    totalKey: COLUMNS.PERIOD,
                    key: COLUMNS.DAYS_WITH_ORDERS,
                    type: '',
                  },
                ],
              },
            ],
          },
          {
            title: 'Остатки',
            labels: [/*'Шт',*/ 'Розн', 'Себес'],
            shortcut: true,
            tabs: [
              /*{
                info: [
                  {
                    label: 'На складе',
                    key: COLUMNS.QUANTITY,
                    type: 'amount',
                  },
                  {
                    label: 'В пути',
                    key: COLUMNS.QUANTITY_IN_WAY,
                    type: 'amount',
                  },
                  {
                    label: 'Всего',
                    key: COLUMNS.QUANTITY_FULL,
                    type: 'amount',
                  },
                  {
                    label: 'Закончатся к',
                    key: COLUMNS.OUT_OF_STOCK_DATE,
                    type: 'date',
                    getDateInFormat: (date) => {
                      const splited = date.split('-');
                      const month = moment(date).format('MMM').split('').slice(0, -1).join('');
                      const day = splited[2];
                      const year = splited[0];
                      return `${day} ${month} ${year}`;
                    },
                  },
                ],
              },*/
              {
                info: [
                  {
                    label: `<span>На складе <span class='text-green'>&middot;</span> <span class='text-green'>${
                      data?.[COLUMNS.QUANTITY] || '-'
                    } шт</span></span>`,
                    key: COLUMNS.QUANTITY_RETAIL,
                    type: 'currency',
                  },
                  {
                    label: `<span>В пути <span class='text-green'>&middot;</span> <span class='text-green'>${
                      data?.[COLUMNS.QUANTITY_IN_WAY] || '-'
                    } шт</span></span>`,
                    key: COLUMNS.QUANTITY_IN_WAY_RETAIL,
                    type: 'currency',
                  },
                  {
                    label: `<span>Всего <span class='text-green'>&middot;</span> <span class='text-green'>${
                      data?.[COLUMNS.QUANTITY_FULL] || '-'
                    } шт</span></span>`,
                    key: COLUMNS.QUANTITY_FULL_RETAIL,
                    type: 'currency',
                  },
                  {
                    label: 'Закончатся к',
                    key: COLUMNS.OUT_OF_STOCK_DATE,
                    type: 'date',
                    getDateInFormat: (date) => {
                      const splited = date.split('-');
                      let month = moment(date).format('MMM');
                      if (month.charAt(month.length - 1) === '.') {
                        month = month.split('').slice(0, -1).join('');
                      }
                      const day = splited[2];
                      const year = splited[0];
                      return `${day} ${month} ${year}`;
                    },
                  },
                ],
              },
              {
                info: [
                  {
                    label: `<span>На складе <span class='text-green'>&middot;</span> <span class='text-green'>${
                      data?.[COLUMNS.QUANTITY] || '-'
                    } шт</span></span>`,
                    key: COLUMNS.QUANTITY_PRIME,
                    type: 'currency',
                  },
                  {
                    label: `<span>В пути <span class='text-green'>&middot;</span> <span class='text-green'>${
                      data?.[COLUMNS.QUANTITY_IN_WAY] || '-'
                    } шт</span></span>`,
                    key: COLUMNS.QUANTITY_IN_WAY_PRIME,
                    type: 'currency',
                  },
                  {
                    label: `<span>Всего <span class='text-green'>&middot;</span> <span class='text-green'>${
                      data?.[COLUMNS.QUANTITY_FULL] || '-'
                    } шт</span></span>`,
                    key: COLUMNS.QUANTITY_FULL_PRIME,
                    type: 'currency',
                  },
                  {
                    label: 'Закончатся к',
                    key: COLUMNS.OUT_OF_STOCK_DATE,
                    type: 'date',
                    getDateInFormat: (date) => {
                      const splited = date.split('-');
                      const month = moment(date).format('MMM').split('').slice(0, -1).join('');
                      const day = splited[2];
                      const year = splited[0];
                      return `${day} ${month} ${year}`;
                    },
                  },
                ],
              },
            ],
          },
        ],
        // column fifth
        [
          {
            title: 'Настройки',
            labels: [],
            tabs: [
              {
                info: [
                  {
                    label: 'Себестоимость',
                    key: COLUMNS.PRIME_COST,
                    type: 'currency',
                    editable: true,
                    onUpdate: (value) => handleUpdateSetting(COLUMNS.PRIME_COST, value),
                  },
                  {
                    label: 'Кратность короба',
                    key: 'box_multiplicity',
                    editable: true,
                    onUpdate: (value) => handleUpdateSetting('box_multiplicity', value),
                  },
                  {
                    label: 'Товар в дороге',
                    key: 'days_road',
                    editable: true,
                    onUpdate: (value) => handleUpdateSetting('days_road', value),
                  },
                  {
                    label: 'На своем складе',
                    key: 'warehouse_count',
                    editable: true,
                    onUpdate: (value) => handleUpdateSetting('warehouse_count', value),
                  },
                  {
                    label: 'Поставщик',
                    key: COLUMNS.SUPPLIER,
                    selectable: true,
                    allActions: true,
                    options: suppliers,
                    onUpdate: (value) => handleUpdateSetting(COLUMNS.SUPPLIER, value, true),
                    onCreateOption: (value) => handleCreateSupplier(COLUMNS.SUPPLIER, value),
                    onUpdateOption: (id, value) =>
                      handleUpdateSupplier(COLUMNS.SUPPLIER, id, value),
                    onDeleteOption: (id) => handleDeleteSupplier(COLUMNS.SUPPLIER, id),
                  },
                  {
                    label: 'Тип поставки',
                    key: 'delivery_type',
                    selectable: true,
                    options: [
                      { value: 1, name: 'Короб' },
                      { value: 2, name: 'Монопаллет' },
                      { value: 3, name: 'Суперсейф' },
                    ],
                    onUpdate: (value) => handleUpdateSetting('delivery_type', value, true),
                  },
                ],
              },
            ],
          },
        ],
      ]}
    />
  );
};

InnerProductCardList.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  barcode: PropTypes.string,
};

export default InnerProductCardList;
