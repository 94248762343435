/* eslint-disable react/prop-types */
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router';

import { TariffsProvider } from 'context/tariffs.context';
import { UserTariffsProvider } from 'context/user.tariffs.context';
import { ProblemsProductsProvider } from '../hooks/useProblemsProducts';
import { AnalyticTypeProvider } from './analytic.type.context';
import { AuthProvider } from './auth.context';
import { ChartDoughnutProvider } from './chart.doughnut.context';
import { FilterReducerProvider } from './filter/filter.context.reducer';
import { FrontAuthenticationProvider } from './front.authentication.context';
import { NavigationProvider } from './navigation.context';
import { PaymentProvider } from './payment.context';
import { ProductProvider } from './product.context';
import { TableProvider } from './table.context';
import { TableTemplatesProvider } from './table.templates.context';
import { TableTooltipProvider } from './table.tooltip.context';
import { UIProvider } from './ui.context';
import { UserListProvider } from './user.list.context';
import { VideoLessonsProvider } from './video.lessons.context';

export const queryClient = new QueryClient();

const AppProviders = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <UIProvider>
      <FrontAuthenticationProvider>
        <AuthProvider>
          <ProductProvider>
            <UserTariffsProvider>
              <AnalyticTypeProvider>
                <TableTemplatesProvider>
                  <FilterReducerProvider>
                    <NavigationProvider>
                      <UserListProvider>
                        <ChartDoughnutProvider>
                          <TableProvider>
                            <TableTooltipProvider>
                              <BrowserRouter>
                                <VideoLessonsProvider>
                                  <TariffsProvider>
                                    <ProblemsProductsProvider>
                                      <PaymentProvider>{children}</PaymentProvider>
                                    </ProblemsProductsProvider>
                                  </TariffsProvider>
                                </VideoLessonsProvider>
                              </BrowserRouter>
                            </TableTooltipProvider>
                          </TableProvider>
                        </ChartDoughnutProvider>
                      </UserListProvider>
                    </NavigationProvider>
                  </FilterReducerProvider>
                </TableTemplatesProvider>
              </AnalyticTypeProvider>
            </UserTariffsProvider>
          </ProductProvider>
        </AuthProvider>
      </FrontAuthenticationProvider>
    </UIProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default AppProviders;
