import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { topBannerContent } from './TopBannerContent';
import { TopBannerIcon } from './TopBannerIcon';

const TopBanner = () => {
  if (topBannerContent?.visible === false) {
    return null;
  }
  if (!topBannerContent?.link) {
    return <TopBannerInner topBannerContent={topBannerContent} />;
  }
  return (
    <Link to={topBannerContent.link} target="blank">
      <TopBannerInner
        topBannerContent={topBannerContent}
        extra={
          <span className="text-xl leading-4">
            <TopBannerIcon />
          </span>
        }
      />
    </Link>
  );
};

export default TopBanner;

const TopBannerInner = ({ topBannerContent, extra }) => {
  return (
    <div className="align-center text-center p-2 bg-[#FFEC41]">
      <span className="inline-flex align-center space-x-2">
        <span className="leading-4">{topBannerContent?.text}</span> {extra}
      </span>
    </div>
  );
};
TopBannerInner.propTypes = {
  topBannerContent: PropTypes.object,
  extra: PropTypes.node,
};
