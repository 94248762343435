import cn from 'classnames';
import ButtonLogout from 'components/ButtonLogout';
import Navigation from 'components/Navigation';
import { PAGE_PROFILE_SUBSCRIPTIONS } from 'constants/pages';
import { useAuth } from 'context/auth.context';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const getDays = (amount) => {
  const last = +amount.toString().slice(amount.toString().length - 1);

  if (last === 1) {
    return 'д.';
  } else if (last > 1 && last < 5) {
    return 'д.';
  } else if (last === 0 || last >= 5) {
    return 'д.';
  }
};

const Info = ({ label, greenText, to, prefix, borderBottom }) => {
  const { tariff } = useAuth();
  return (
    <div className={cn('flex flex-col px-4 py-3 bg-[#F5F5F5] border-b border-[#E8E8E8]')}>
      <div className="flex justify-between items-center">
        <div className="block text-gray-400 text-xs">{label}</div>
        <div className="block text-green text-xs whitespace-nowrap">
          {prefix && <span className="text-black">{prefix}</span>} <Link to={to}>{greenText}</Link>
        </div>
      </div>
      {borderBottom && <div className="w-full h-[1px] my-2 bg-[#E8E8E8]" />}
      <div className="flex justify-between items-center">
        <div className="flex text-xs">
          <span className="text-gray-400 block mr-1">Тариф:</span>
          <span className="block text-green">{tariff?.project_name || ''}</span>
        </div>
        <div className="flex text-xs text-gray-400">
          {tariff?.days_left === 0 ? (
            <>Осталось менее 1 д.</>
          ) : (
            <>
              Осталось {tariff?.days_left} {getDays(tariff?.days_left)}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Info.propTypes = {
  label: PropTypes.string,
  greenText: PropTypes.string,
  to: PropTypes.string,
  prefix: PropTypes.string,
  borderBottom: PropTypes.bool,
};

export const MobileMenu = () => {
  const { user, logout, setUser } = useAuth();

  const handleLogout = async () => {
    logout().then(() => setUser(null));
  };

  return (
    <div className="flex flex-col grow">
      <Navigation />
      <div>
        {user && (
          <Info
            label="Профиль"
            greenText={/*user.name + ', ' + */ user.email}
            to={PAGE_PROFILE_SUBSCRIPTIONS}
            borderBottom={true}
          />
        )}
      </div>
      {user && (
        <div
          onClick={handleLogout}
          className="flex justify-between items-center mt-auto p-4 border-t border-[#E8E8E8]"
        >
          <div className="text-gray-400 font-normal">Выйти из профиля</div>
          {user && <ButtonLogout onClick={() => {}} />}
        </div>
      )}
    </div>
  );
};
