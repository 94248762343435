import React, { useCallback, /*useEffect,*/ useMemo, useReducer } from 'react';

import { F, PAGE_API_KEYS, PAGE_MAIN } from 'constants';
import { useAnalyticType } from 'context/analytic.type.context';
import { useAuth } from 'context/auth.context';
import { isTrendsPage } from 'utils/pages';
import { useTableTemplates } from '../table.templates.context';
import {
  getChipsFromParams,
  getChipsInitial,
  getDateInitial,
  getDateRewrite,
  getPeriod,
  getPeriodRewrite,
  getSortInitial,
  getSortInitialByPath,
} from './filter.context.reducer.methods';
import {
  CHIPS_KEYS_INNER,
  filterInitial as filterInitialInner,
  useFilterInner,
} from './useFilterInner';
import {
  CHIPS_KEYS_OUTER,
  filterInitial as filterInitialOuter,
  useFilterOuter,
} from './useFilterOuter';

const FilterReducerContext = React.createContext();
FilterReducerContext.displayName = 'Filter.Reducer.Context';

const initialStateCommon = {
  dateInitial: [],
  filterText: {},
  filterRange: {},
  priceRange: [0, 5000],
  step: 250,
  isLoaded: false,
  error: null,
};

const INITIAL_ORDER = 'desc';
const getInitialState = ({ filterInitial, chipsInitial }) => {
  return {
    filter: {
      ...filterInitial,
      [F.DATE]: [],
      [F.PERIOD]: [],
      [F.SORT]: null,
      [F.ORDER]: INITIAL_ORDER,
    },
    chips: chipsInitial,
    ...initialStateCommon,
  };
};

export const CHIP_ID_KEY = 'key';
export const CHIP_TITLE_KEY = 'title';

export const ACTIONS = {
  SET_FILTER_INITIAL: 'SET_FILTER_INITIAL',
  SET_FILTER: 'SET_FILTER',
  SET_FILTER_DEFAULT: 'SET_FILTER_DEFAULT',
  UPDATE_FILTER: 'UPDATE_FILTER',
  ADD_FILTER_PARAM_WITH_CHIP: 'ADD_FILTER_PARAM_WITH_CHIP',
  SET_FILTER_PARAM_WITH_CHIP: 'SET_FILTER_PARAM_WITH_CHIP',
  TOGGLE_FILTER_BOOL_PARAM: 'TOGGLE_FILTER_BOOL_PARAM',
  SET_FILTER_BOOL_PARAM: 'SET_FILTER_BOOL_PARAM',
  DELETE_CHIP: 'DELETE_CHIP',
  ADD_TEXT_FILTER: 'ADD_TEXT_FILTER',
  REMOVE_TEXT_FILTER: 'REMOVE_TEXT_FILTER',
  ADD_FILTER_RANGE: 'ADD_FILTER_RANGE',
  REMOVE_FILTER_RANGE: 'REMOVE_FILTER_RANGE',
  CLEAR_FILTER_RANGE: 'CLEAR_FILTER_RANGE',
  SET_ERROR: 'SET_ERROR',
  SET_PRICE_RANGE: 'SET_PRICE_RANGE',
  CHANGE_FILTER_TYPE: 'CHANGE_FILTER_TYPE',
  SET_FULL_STATE: 'SET_FULL_STATE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_FULL_STATE: {
      return action.state;
    }
    case ACTIONS.SET_FILTER_INITIAL:
      return {
        ...state,
        filter: {
          ...action.filterInitial,
          ...action.filter,
        },
        dateInitial: action.filter.date || [],
        chips: action.chipsInitial,
        isLoaded: true,
      };

    case ACTIONS.SET_FILTER:
      return {
        ...state,
        filter: {
          ...action.filterInitial,
          ...action.filter,
        },
        dateInitial: action.filter.date || [],
        chips: {
          ...action.chipsInitial,
          ...action.chips,
        },
        filterText: action.nameParams,
        filterRange: action.restrictsParams,
        isLoaded: true,
      };

    case ACTIONS.SET_FILTER_DEFAULT:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filter,
          [F.DATE]: getDateRewrite({
            date: state.filter[F.DATE],
            tariffName: action.tariffName,
            nextLink: action.nextLink,
            prevLink: action.prevLink,
            isInner: action.isInner,
          }),
          [F.PERIOD]: getPeriodRewrite({
            date: state.filter[F.DATE],
            period: state.filter[F.PERIOD],
            tariffName: action.tariffName,
            nextLink: action.nextLink,
            prevLink: action.prevLink,
          }),
        },
        filterText: initialStateCommon.filterText,
        filterRange: initialStateCommon.filterRange,
        priceRange: initialStateCommon.priceRange,
        step: initialStateCommon.step,
      };

    case ACTIONS.CHANGE_FILTER_TYPE:
      return {
        ...state,
        filter: action.filter,
        chips: action.chips,
        isLoaded: false,
      };

    case ACTIONS.UPDATE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filter,
        },
        chips: action.chips
          ? {
              ...state.chips,
              ...action.chips,
            }
          : state.chips,
      };

    case ACTIONS.TOGGLE_FILTER_BOOL_PARAM:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.key]: state.filter[action.key] ? 0 : 1,
        },
      };

    case ACTIONS.SET_FILTER_BOOL_PARAM:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.key]: !action.value ? 0 : 1,
        },
      };

    case ACTIONS.ADD_FILTER_PARAM_WITH_CHIP: {
      const updatedFilterActionValueType = state.filter[action.value.type].includes(
        action.value.key,
      )
        ? state.filter[action.value.type]
        : [...state.filter[action.value.type], action.value.key];

      console.log(state.chips);
      const updatedChipsActionValueType = state.chips[action.value.type].some(
        (chip) => chip[CHIP_ID_KEY] === action.value[CHIP_ID_KEY],
      )
        ? state.chips[action.value.type]
        : [
            ...state.chips[action.value.type],
            {
              [CHIP_ID_KEY]: action.value[CHIP_ID_KEY],
              [CHIP_TITLE_KEY]: action.value[CHIP_TITLE_KEY],
            },
          ];

      return {
        ...state,
        filter: {
          ...state.filter,
          ...(action.filter && action.filter),
          ...(action.nextLink && {
            [F.SORT]: getSortInitialByPath(action.nextLink),
            [F.ORDER]: INITIAL_ORDER,
          }),
          [action.value.type]: updatedFilterActionValueType,
        },
        chips: {
          ...state.chips,
          [action.value.type]: updatedChipsActionValueType,
        },
        filterText: initialStateCommon.filterText,
        filterRange: initialStateCommon.filterRange,
      };
    }
    case ACTIONS.SET_FILTER_PARAM_WITH_CHIP:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...(action.filter && action.filter),
          [action.value.type]: [action.value.key],
        },
        chips: {
          ...state.chips,
          [action.value.type]: [
            {
              [CHIP_ID_KEY]: action.value[CHIP_ID_KEY],
              [CHIP_TITLE_KEY]: action.value[CHIP_TITLE_KEY],
            },
          ],
        },
        filterText: initialStateCommon.filterText,
        filterRange: initialStateCommon.filterRange,
      };

    case ACTIONS.DELETE_CHIP:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.chip.type]: state.filter[action.chip.type].filter(
            (item) => item !== action.chip.key,
          ),
        },
        chips: {
          ...state.chips,
          [action.chip.type]: state.chips[action.chip.type].filter(
            (item) => item.key !== action.chip.key,
          ),
        },
      };

    case ACTIONS.ADD_TEXT_FILTER:
      return {
        ...state,
        filterText: {
          ...state.filterText,
          [action.filterText.key]: action.filterText.value,
        },
      };

    case ACTIONS.REMOVE_TEXT_FILTER:
      return {
        ...state,
        filterText: Object.keys(state.filterText)
          .filter((key) => key !== action.filterText.key)
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: state.filterText[key] });
          }, {}),
      };

    case ACTIONS.ADD_FILTER_RANGE:
      return {
        ...state,
        filterRange: {
          ...state.filterRange,
          [action.filterRange.key]: action.filterRange.value,
        },
      };

    case ACTIONS.REMOVE_FILTER_RANGE:
      return {
        ...state,
        filterRange: Object.keys(state.filterRange)
          .filter((key) => key !== action.filterRange.key)
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: state.filterRange[key] });
          }, {}),
      };

    case ACTIONS.CLEAR_FILTER_RANGE:
      return {
        ...state,
        filterRange: Object.keys(state.filterRange)
          .filter((key) => key === 'period')
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: state.filterRange[key] });
          }, {}),
      };

    case ACTIONS.SET_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case ACTIONS.SET_PRICE_RANGE:
      return {
        ...state,
        priceRange: action.priceRange,
        step: action.step,
      };

    default:
      return state;
  }
};

const FilterReducerProvider = (props) => {
  // используются разные фильтры
  // для внутренней и внешней аналитик
  const { isInner } = useAnalyticType();
  const { tariffName } = useAuth();
  const { deactivateTemplate } = useTableTemplates();

  const filterInitial = isInner ? filterInitialInner : filterInitialOuter;
  const chipsKeys = isInner ? CHIPS_KEYS_INNER : CHIPS_KEYS_OUTER;
  const chipsInitial = getChipsInitial(chipsKeys);

  const initialState = useMemo(
    () =>
      getInitialState({
        filterInitial,
        chipsInitial,
      }),
    [filterInitial, chipsInitial],
  );

  const [state, defDispatch] = useReducer(reducer, initialState);
  const resultUrl = window.location.pathname.split('/').slice(1).join('-');

  const dispatch = useCallback(
    (action) => {
      if (action.type !== ACTIONS.SET_FILTER && action.type !== ACTIONS.SET_FULL_STATE) {
        deactivateTemplate(resultUrl);
      }
      defDispatch(action);
    },
    [defDispatch, deactivateTemplate, resultUrl],
  );

  const { common: commonOuter, exceptional: exceptionalOuter } = useFilterOuter({
    state,
    dispatch,
  });

  const { common: commonInner, exceptional: exceptionalInner } = useFilterInner({
    state,
    dispatch,
  });

  const currentCommon = isInner ? commonInner : commonOuter;
  const currentExceptional = isInner ? exceptionalInner : exceptionalOuter;
  // задавать данные фильтра по умолчанию при первой загрузке страницы,
  // если фильтр не был передан в адресной строке
  const setFilterInitial = () => {
    const isTrends = isTrendsPage();
    const filter = currentCommon.getFilterInitial();
    const date = getDateInitial({ tariffName, isTrends, isInner });
    // console.log(chipsInitial, 'chipsInitial');
    dispatch({
      type: ACTIONS.SET_FILTER_INITIAL,
      filter: {
        ...filter,
        [F.SORT]: getSortInitial(),
        [F.ORDER]: INITIAL_ORDER,
        [F.DATE]: date,
        [F.PERIOD]: getPeriod(date),
      },
      filterInitial,
      chipsInitial,
    });
  };

  // используется при первой загрузке если есть фильтр в пути
  const setFilter = ({ filterParams, nameParams, restrictsParams }) => {
    const filterKeys = getInitialState({ filterInitial, chipsInitial });

    const filterParamsCorrected = Object.keys(filterParams)
      .filter((key) => Object.keys(filterKeys?.filter).includes(key))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: filterParams[key] ?? null });
      }, {});

    const filter = currentCommon.getFilterFromParams(filterParamsCorrected);
    dispatch({
      type: ACTIONS.SET_FILTER,
      filter,
      nameParams: nameParams || {},
      restrictsParams: restrictsParams || {},
      chips: getChipsFromParams(filter, chipsKeys),
      filterInitial,
      chipsInitial,
    });
  };

  // обнулять некторые параметры фильтра при переходе между страницами
  // (sort, order, date, filterText, filterRange)
  const setFilterDefault = useCallback(
    ({ nextLink, prevLink }) => {
      const filter = currentCommon.getFilterDefault({ nextLink, prevLink });
      // console.log(filter, 'filter');
      dispatch({
        type: ACTIONS.SET_FILTER_DEFAULT,
        filter: {
          ...filter,
          [F.SORT]: getSortInitialByPath(nextLink),
          [F.ORDER]: INITIAL_ORDER,
        },
        tariffName,
        nextLink,
        prevLink,
        isInner,
      });
    },
    [currentCommon, isInner, tariffName, dispatch],
  );

  // при смене типа фильтра (внутренний/внешний)
  // менять isLoaded на false
  // чтобы было ожидание пока не будут задан
  // все необходимые параметры фильтра
  const changeFilterType = useCallback(
    (isNewTypeInner) => {
      const chipsKeys = !isNewTypeInner ? CHIPS_KEYS_OUTER : CHIPS_KEYS_INNER;
      const filterInitial = !isNewTypeInner
        ? commonOuter.getFilterInitial()
        : commonInner.getFilterInitial();
      dispatch({
        type: ACTIONS.CHANGE_FILTER_TYPE,
        filter: {
          ...(!isNewTypeInner ? filterInitialOuter : filterInitialInner),
          ...filterInitial,
          [F.ORDER]: INITIAL_ORDER,
          [F.SORT]: getSortInitialByPath(!isNewTypeInner ? PAGE_MAIN : PAGE_API_KEYS),
          [F.DATE]: getDateInitial({ tariffName, isTrends: false, isInner: isNewTypeInner }),
        },
        chips: getChipsInitial(chipsKeys),
      });
    },
    [commonInner, commonOuter, tariffName, dispatch],
  );

  const updateChips = (chips) => {
    const keys = Object.keys(chips);
    const filter = {};

    for (let i = 0; i < keys.length; i++) {
      filter[keys[i]] = chips[keys[i]].map((chip) => chip.key);
    }

    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter,
      chips,
    });
  };

  const deleteChip = ({ type, key }) => {
    dispatch({
      type: ACTIONS.DELETE_CHIP,
      chip: {
        key,
        type,
      },
    });
  };

  const setSort = ({ sort, order }) => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [F.SORT]: sort,
        [F.ORDER]: order,
      },
    });
  };

  const setDate = (date) => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [F.DATE]: date,
        [F.PERIOD]: getPeriod(date),
      },
    });
  };

  // фильтр в таблицах по имени/тексту
  const addFilterText = ({ key, value }) => {
    dispatch({
      type: ACTIONS.ADD_TEXT_FILTER,
      filterText: { key, value },
    });
  };

  const removeFilterText = ({ key }) => {
    dispatch({
      type: ACTIONS.REMOVE_TEXT_FILTER,
      filterText: { key },
    });
  };

  // фильтр по диапзону в таблицах от-до
  const addFilterRange = ({ key, value }) => {
    dispatch({
      type: ACTIONS.ADD_FILTER_RANGE,
      filterRange: { key, value },
    });
  };

  const removeFilterRange = ({ key }) => {
    dispatch({
      type: ACTIONS.REMOVE_FILTER_RANGE,
      filterRange: { key },
    });
  };

  const clearFilterRange = () => {
    dispatch({
      type: ACTIONS.CLEAR_FILTER_RANGE,
    });
  };

  const setError = (error) => {
    dispatch({
      type: ACTIONS.SET_ERROR,
      error,
    });
  };

  const isFilterTextApplied = !!Object.keys(state.filterText).length;
  const isFilterRangeApplied = !!Object.keys(state.filterRange).length;
  const isFilterApplied = currentCommon.isFilterApplied;

  const isFilterGlobalApplied = isFilterApplied || !!isFilterTextApplied;

  const setPriceRange = ({ range, step }) => {
    dispatch({
      type: ACTIONS.SET_PRICE_RANGE,
      priceRange: range,
      step,
    });
  };

  const getFilterOuterMainDefault = () => {
    const date = getDateInitial({ tariffName });

    return {
      ...commonOuter.getFilterInitial(),
      [F.SORT]: getSortInitialByPath(PAGE_MAIN),
      [F.ORDER]: INITIAL_ORDER,
      [F.DATE]: date,
    };
  };

  // console.log(state, 'state');
  const value = {
    filter: state.filter,
    chips: state.chips,
    filterText: state.filterText,
    filterRange: state.filterRange,
    priceRange: state.priceRange,
    step: state.step,
    isLoaded: state.isLoaded,
    dateInitial: state.dateInitial,
    error: state.error,
    setFilter,
    setFilterInitial,
    setFilterDefault,
    updateChips,
    deleteChip,
    addFilterText,
    removeFilterText,
    addFilterRange,
    removeFilterRange,
    clearFilterRange,
    setError,
    isFilterApplied: isFilterGlobalApplied,
    isFilterTextApplied,
    isFilterRangeApplied,
    setPriceRange,
    filterInitial,
    changeFilterType,
    setSort,
    setDate,
    filterInitialMainOuter: getFilterOuterMainDefault(),
    ...currentCommon,
    ...currentExceptional,
    fullFilterState: state,
  };

  return <FilterReducerContext.Provider value={value} {...props} />;
};

const useFilterReducer = () => {
  return React.useContext(FilterReducerContext);
};

export { FilterReducerProvider, useFilterReducer };
