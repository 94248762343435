import Table from 'components/Table';
import { TABLE_TYPE } from 'constants';
import { useSeoProductContext } from 'context/seo.product.context';

const SeoProductSearchTable = () => {
  const { queryResults, isLoading } = useSeoProductContext();

  return (
    <Table
      data={queryResults?.phrases}
      type={TABLE_TYPE.SEO_PRODUCT_CHECK_RESULTS}
      noRowsText={`По вашему запросу нет данных.`}
      isLoading={isLoading}
    />
  );
};

export default SeoProductSearchTable;
