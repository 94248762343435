import cn from 'classnames';

const BlockInner = (props) => {
  const { label, extra, children, className } = props || {};
  return (
    <div className={cn('border border-solid border-gray-200 rounded-xl bg-gray-100', className)}>
      <div className="flex w-full justify-between text-left px-4 mt-4 mb-3 group">
        <span className="block font-medium text-lg">{label}</span>
        {extra && <div className="flex items-center min-w-max">{extra}</div>}
      </div>
      <div className="px-4 pb-4">{children}</div>
    </div>
  );
};

export default BlockInner;
