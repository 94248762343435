const InfoSimple = (props) => {
  const { label, value } = props || {};
  return (
    <div className="flex flex-nowrap items-center justify-between leading-none relative z-0">
      <span className="text-gray-400 flex items-center leading-4 whitespace-nowrap">
        <span>{label}</span>
      </span>
      <span className="block border-dashed border-gray-250 border-b flex-1 mx-2 self-end mb-[3px]"></span>
      <div className="bg-gray-250 animate-pulse min-w-4 w-full h-3 rounded w-full h-3 hidden"></div>
      <div className="flex">
        <div className="relative">
          <span className="text-sm leading-none relative whitespace-nowrap">{value}</span>
        </div>
      </div>
    </div>
  );
};

export default InfoSimple;
