import PropTypes from 'prop-types';
import { NavLink } from 'react-router';

import { ReactComponent as Logo } from 'assets/images/logoWithoutText.svg';

const ActivationLayout = ({ children }) => {
  return (
    <main className="wrapper flex-auto flex flex-col items-center justify-center">
      <div className="flex flex-col items-center max-w-[300px] w-100">
        <Logo />
        <div className="mt-8 mb-10 w-full flex flex-col items-center">{children}</div>

        <NavLink className="button-primary button-primary--sm" to={'/'}>
          Войти на платформу
        </NavLink>
      </div>
    </main>
  );
};

ActivationLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default ActivationLayout;
