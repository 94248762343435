import { createContext, useContext, useRef, useState } from 'react';
import apiClient from 'services/baseClient';

// Create Context
const SeoProductContext = createContext();

// Provider Component
export const SeoProductProvider = (props) => {
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resultsInited, setResultsInited] = useState(false);
  const [totalPhrases, setTotalPhrases] = useState(0);
  const [search, setSearch] = useState(null);
  const [error, setError] = useState(null);
  const [initState, setInitState] = useState(null);
  const [state, setState] = useState(null);
  const [basicInfo, setBasicInfo] = useState(null);
  const [queryPhrase, setQueryPhrase] = useState('');
  const [queryResults, setQueryResults] = useState(null);
  const scrollRef = useRef();
  const setValueByKey = (key, value) => {
    setState((prevState) => {
      if (!prevState) return null;

      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  function changeState(key, value) {
    if (!state) return;

    setValueByKey(key, value);
  }

  function cardToStateTransformer(data) {
    const { brand, color, seller, name, description, characteristics } = data || {};
    const { name: sellerName } = seller || {};
    const { name: brandName } = brand || {};
    return {
      phrases: '',
      name,
      characteristics,
      brand: brandName,
      color,
      seller: sellerName,
      description,
    };
  }

  async function doSearch() {
    setSearchIsLoading(true);
    try {
      const { data } = await apiClient.seoCheckCard({ search });
      setSearchIsLoading(false);
      setBasicInfo(data);

      const state = cardToStateTransformer(data);
      setInitState(state);
      setState(state);
    } catch (e) {
      setSearchIsLoading(false);
      const statusCode = e?.response?.status;
      if (statusCode === 404) {
        setError('Такой товар не был найден');
      } else {
        setError('Упс, что-то пошло не так, уже разбираемся');
      }
    }
  }

  async function doSearchReview() {
    setIsLoading(true);

    const value = state.phrases ?? '';
    setTotalPhrases(value ? String(value).split('\n').length : 0);

    const results = await apiClient.seoCheckCardReview(prepareStateInput(state));
    setResultsInited(true);
    setIsLoading(false);
    setQueryResults(prepareResults(results));

    scrollRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  }

  function resetState() {
    setState((state) => ({ id: state.id }));
  }

  function clearSearch() {
    setState(null);
    setSearch(null);
    setQueryResults(null);
    setResultsInited(false);
  }

  function clearPhrases() {
    setState((oldState) => ({ ...oldState, phrases: '' }));
    setQueryResults(null);
    setResultsInited(false);
  }

  return (
    <SeoProductContext.Provider
      value={{
        initState,
        setInitState,
        state,
        setState,
        changeState,
        search,
        setSearch,
        doSearch,
        doSearchReview,
        resetState,
        clearSearch,
        queryPhrase,
        setQueryPhrase,
        queryResults,
        basicInfo,
        searchIsLoading,
        isLoading,
        error,
        setError,
        resultsInited,
        scrollRef,
        totalPhrases,
        clearPhrases,
      }}
      {...props}
    />
  );
};

// Custom Hook
export const useSeoProductContext = () => useContext(SeoProductContext);

const prepareStateInput = (state) => {
  const { characteristics = {}, phrases = '', ...otherState } = state || {};
  return {
    ...otherState,
    phrases: phrases.split('\n'),
    characteristics,
  };
};

const prepareResults = (data) => {
  const { phrases, ...stats } = data || {};
  const phrasesNew = phrases.map((item) => {
    const { check, ...otherData } = item;
    const convertedCheck = {};
    Object.keys(check).forEach((key) => {
      const newKey = `check_${key}`;
      convertedCheck[newKey] = check[key];
    });

    return { ...otherData, ...convertedCheck };
  });

  return { phrases: phrasesNew, stats };
};
