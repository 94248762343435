import { useInfiniteQuery, useQuery } from 'react-query';

import { F, F_INNER } from 'constants/filter';
import { STALE_TIME } from 'constants/query';
import {
  COLUMNS,
  TABLE_COLUMNS_TEXT_DATES_FILTERED_ON_BACK,
  TABLE_INNER_TYPE,
} from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useTable } from 'context/table.context';
import { useParams } from 'react-router';
import apiClient from 'services/baseInnerClient';
import { dateConverter } from '../helpers/generateGridColumnsHelpers';
import { isInnerPricesTabPage, isInnerProductSettings } from '../utils/pages';

const action = {
  [TABLE_INNER_TYPE.PRODUCTS]: (props) => apiClient.getInnerProducts(props),
  [TABLE_INNER_TYPE.IN_DAYS]: (props) => apiClient.getInnerInDays(props),
  [TABLE_INNER_TYPE.SALES]: (props) => apiClient.getInnerSales(props),
  [TABLE_INNER_TYPE.WEEKS]: (props) => apiClient.getInnerWeeks(props),
  [TABLE_INNER_TYPE.PRODUCT]: (props) => apiClient.getInnerProduct(props),
  [TABLE_INNER_TYPE.IN_DAYS_2]: (props) => apiClient.getInnerInDays2(props),
  [TABLE_INNER_TYPE.IN_DAYS_2_CHART]: (props) => apiClient.getInnerInDays2(props),
  [TABLE_INNER_TYPE.RATINGS]: (props) => apiClient.getInnerProductRatings(props),
  [TABLE_INNER_TYPE.RATINGS_CHART]: (props) => apiClient.getInnerProductRatings(props),
  [TABLE_INNER_TYPE.PRICES_TAB]: (props) => apiClient.getInnerPricesTab(props),
  [TABLE_INNER_TYPE.ORDERS_SALES]: (props) => apiClient.getInnerProductOrdersSales(props),
  [TABLE_INNER_TYPE.WAREHOUSES]: (props) => apiClient.getInnerWarehouses(props),
  [TABLE_INNER_TYPE.PRODUCT_WAREHOUSES]: (props) => apiClient.getInnerProductWarehouses(props),
  [TABLE_INNER_TYPE.DELIVERY]: (props) => apiClient.deliveryCalculating(props),
  [TABLE_INNER_TYPE.CARD_LIST]: (props) => apiClient.getCardListInfo(props),
  [TABLE_INNER_TYPE.PRODUCT_SIZES]: (props) => apiClient.getInnerProductSizes(props),
  [TABLE_INNER_TYPE.PROBLEMS_SUM]: (props) => apiClient.problemsProductSum(props),
  [TABLE_INNER_TYPE.PRODUCTS_SETTINGS]: (props) => apiClient.productsSettings(props),
};

const TYPES_WITH_SINGLE_API_KEY = [TABLE_INNER_TYPE.PRICES_TAB];

const useRestrictsFilter = (type) => {
  const { isTableRestrictedOnBack } = useTable();
  const { filterRange, filterText } = useFilterReducer();
  if ((!filterRange && !filterText) || !isTableRestrictedOnBack(type)) return [];

  const result = [];

  const rangeKeys = Object.keys(filterRange);
  for (let i = 0; i < rangeKeys.length; i++) {
    const field = rangeKeys[i];
    const min = filterRange[field][0];
    const max = filterRange[field][1];

    const filter = {
      field,
      values: {},
      type: 'restricts',
    };

    if (min !== undefined && min !== '') {
      filter.values.min = min;
    }

    if (max !== undefined && max !== '') {
      filter.values.max = max;
    }

    result.push(filter);
  }

  const textKeys = Object.keys(filterText);
  for (let i = 0; i < textKeys.length; i++) {
    const field = textKeys[i];

    const filter = {
      field,
      values: {
        search: TABLE_COLUMNS_TEXT_DATES_FILTERED_ON_BACK.includes(field)
          ? dateConverter(filterText[field])
          : filterText[field],
      },
      type: 'restricts',
    };

    result.push(filter);
  }

  return result;
};
const useFilterForBackend = (type) => {
  const { filter } = useFilterReducer();

  const restrictsFilter = useRestrictsFilter(type);

  const filterForBack = [...restrictsFilter];
  if (!(isInnerPricesTabPage() || isInnerProductSettings())) {
    // добавить период
    filterForBack.push({
      field: 'period',
      values: {
        min: `${filter[F.DATE][0]}`,
        max: `${filter[F.DATE][1]} 23:59:59`,
      },
    });
  } else if (!isInnerProductSettings()) {
    filterForBack.push({
      field: 'quantity',
      values: {
        equals: filter[F_INNER.QUANTITY],
      },
    });
  }

  if (type === TABLE_INNER_TYPE.SALES) {
    filterForBack.push({
      field: 'status',
      type: 'restricts',
      values: {
        in: filter[F_INNER.STATUS],
      },
    });
  }

  // if (
  //   type === TABLE_INNER_TYPE.DELIVERY &&
  //   filter[F_INNER.USE_WAREHOUSE] &&
  //   filter[F_INNER.WAREHOUSE]?.length !== 0
  // ) {
  //   filterForBack.push({
  //     field: 'internal_office_id',
  //     values: {
  //       in: filter[F_INNER.WAREHOUSE],
  //     },
  //   });
  // }
  const isUseOneKey = TYPES_WITH_SINGLE_API_KEY.includes(type);
  //добавать апи ключи
  if (filter[F_INNER.API_KEYS] && filter[F_INNER.API_KEYS].length) {
    filterForBack.push({
      field: 'user_api_key_id',
      values: {
        [isUseOneKey ? 'equals' : 'in']: isUseOneKey
          ? filter[F_INNER.API_KEYS][0]
          : filter[F_INNER.API_KEYS],
      },
    });
  }

  //добавать категории
  if (filter[F_INNER.CATEGORY] && filter[F_INNER.CATEGORY].length) {
    filterForBack.push({
      field: 'internal_category_id',
      values: {
        in: filter[F_INNER.CATEGORY],
      },
    });
  }

  //добавать предметы
  if (filter[F_INNER.SUBJECT] && filter[F_INNER.SUBJECT].length) {
    filterForBack.push({
      field: 'internal_subject_id',
      values: {
        in: filter[F_INNER.SUBJECT],
      },
    });
  }

  //добавать бренды
  if (filter[F_INNER.BRAND] && filter[F_INNER.BRAND].length) {
    filterForBack.push({
      field: 'internal_brand_id',
      values: {
        in: filter[F_INNER.BRAND],
      },
    });
  }

  return filterForBack;
};

const useSortOption = (type) => {
  const { isTableSortedOnBack } = useTable();
  const {
    filter: { sort, order },
  } = useFilterReducer();

  // This is for default sorting for charts
  if (type === TABLE_INNER_TYPE.RATINGS_CHART || type === TABLE_INNER_TYPE.IN_DAYS_2_CHART) {
    return [
      {
        field: COLUMNS.DATE,
        direction: 'asc',
      },
    ];
  }
  const TYPES_WITHOUT_SORT = [TABLE_INNER_TYPE.CARD_LIST];
  if (!sort || !isTableSortedOnBack(type) || !order || TYPES_WITHOUT_SORT.includes(type))
    return null;

  return [
    {
      field: sort,
      direction: order,
    },
  ];
};

const buildResultFilter = (filter, ext) => {
  const result = [...filter];
  const index = result.findIndex((el) => el.field === 'period');
  if (index !== -1) {
    result.splice(index, 1);
  }
  return [...result, ...ext];
};

const getDate = (date) => {
  if (!date) return '';
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
    '0' + date.getDate()
  ).slice(-2)}`;
};

const useFetchFilteredDataOptions = ({ type, enabled, data, extFilter }) => {
  const params = useParams();
  const { isLoaded } = useFilterReducer();

  const options = {};
  const { filter: filterReducer } = useFilterReducer();
  const filter = useFilterForBackend(type);
  if (filter) {
    options.filter = extFilter ? buildResultFilter(filter, extFilter) : filter;
  }

  const sort = useSortOption(type);
  if (sort) {
    options.sort = sort;
  }

  if (type === TABLE_INNER_TYPE.DELIVERY) {
    options.date_delivery = getDate(filterReducer[F_INNER.DELIVERY_DATE]);
    options.date_stock_out = getDate(filterReducer[F_INNER.DELIVERY_STOCK_DATE]);
  }
  const queryKey = [type, options];

  return {
    actionOption: { ...options, ...data, ...params },
    queryOptions: {
      queryKey,
      enabled: enabled && isLoaded,
      refetchOnmount: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME,
      cacheTime: STALE_TIME,
      retry: 3,
      keepPreviousData: true,
    },
  };
};

export const useFetchFilteredInnerData = ({ type, enabled = true, onSuccess, data }) => {
  const { actionOption, queryOptions } = useFetchFilteredDataOptions({
    type,
    enabled,
    data,
  });

  return useQuery({
    ...queryOptions,
    queryFn: action[type] ? () => action[type](actionOption) : null,
    onSuccess,
  });
};

export const useFetchFilteredInnerDataPaginated = ({
  type,
  enabled = true,
  onSuccess,
  data,
  extFilter,
}) => {
  const { actionOption, queryOptions } = useFetchFilteredDataOptions({
    type,
    enabled,
    data,
    extFilter,
  });

  const queryFn = ({ pageParam = 1 }) => {
    return action[type] && action[type]({ ...actionOption, page: pageParam });
  };

  return useInfiniteQuery({
    ...queryOptions,
    queryFn,
    getNextPageParam: (lastPage, pages) => pages.length + 1,
    onSuccess,
  });
};

export const actionTypesInner = Object.keys(action);
