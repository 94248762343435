import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import HelpBlock from 'components/HelpBlock';
import Table from 'components/Table';
import { TABLE_TYPE } from 'constants/table';
import { useAnalyticType } from 'context/analytic.type.context';
import { useProduct } from 'context/product.context';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';

const Similar = ({ fetchEnabled }) => {
  const { id } = useParams();
  const { isInner } = useAnalyticType();
  const { isOzonPlatform } = useProduct();
  const { isLoading: productLoading } = useProduct();

  const { data, isLoading, isFetching } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_SIMILAR,
    id,
    isInner,
    enabled: fetchEnabled,
  });

  return (
    <>
      {!isInner && <HelpBlock type="product_similar" />}
      {fetchEnabled && (
        <Table
          data={data?.all_data || []}
          type={TABLE_TYPE.PRODUCT_SIMILAR}
          hideSoldColumn={isOzonPlatform}
          isLoading={isLoading || isFetching || productLoading}
          noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
        />
      )}
    </>
  );
};
Similar.propTypes = {
  fetchEnabled: PropTypes.bool,
};
export default Similar;
