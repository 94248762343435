import ButtonBack from 'components/ButtonBack';
import NavList from 'components/NavList';
import Table from 'components/Table';
import { BY_PHRASES, BY_PRODUCTS, /*NESTED,*/ TAB_FIRST /*TAB_SECOND*/ } from 'constants/seo';
import { COLUMNS, TABLE_TYPE } from 'constants/table';
import { useSEOSearch } from 'context/seo.search.context';
import { useQuerySeoData } from 'hooks/useFetchSeo';
import { useState } from 'react';
import { Link } from 'react-router';
// import { declOfNum } from 'utils/client';
// import { PER_PAGE } from 'helpers/generateGridConfig';

const Search = () => {
  const { queryParams } = useSEOSearch();
  const [page, setPage] = useState(1);
  const { data, isFetching, isLoading } = useQuerySeoData({
    ...queryParams,
    externalFilter: {
      per_page: 50,
      page: page,
    },
  });

  const { by, tab } = useSEOSearch();

  const getTabs = () => {
    const tabs = [
      {
        link: `/seo/${by}/${TAB_FIRST}`,
        title: 'Запросы',
        search: location.search,
      },
    ];
    // if (by !== BY_PHRASES) {
    //   tabs.push({
    //   link: `/seo/${by}/${TAB_SECOND}`,
    //   title: 'Слова',
    //   search: location.search,
    //   })
    // }
    return tabs;
  };
  // const tabFirstCount = data?.table_1.length || 0;
  // const tabSecondCount = data?.table_2.length || 0;
  //
  // const FilterExtra = () => {
  //   if (tab === TAB_FIRST && tabFirstCount) {
  //     return (
  //       <span className="text-gray-400">
  //         Всего {tabFirstCount} {declOfNum(tabFirstCount, ['фраза', 'фразы', 'фраз'])}
  //       </span>
  //     );
  //   }
  //   if (tab === TAB_SECOND && tabSecondCount) {
  //     return (
  //       <span className="text-gray-400">
  //         Всего {tabSecondCount} {declOfNum(tabSecondCount, ['фраза', 'фразы', 'фраз'])}
  //       </span>
  //     );
  //   }
  //
  //   return null;
  // };

  return (
    <>
      <div className="wrapper-wide mt-1">
        <ButtonBack
          label="Вернуться назад"
          backTo={by === BY_PRODUCTS ? `/seo/${BY_PRODUCTS}` : `/seo/${BY_PHRASES}`}
        />

        <h1 className="mt-4">
          Расширение запросов
          <span className="text-gray-250 px-3 font-normal">/</span>
          {by === BY_PRODUCTS && <Link to={`/seo/${BY_PRODUCTS}`}>По товарам</Link>}
          {by === BY_PHRASES && <Link to={`/seo/${BY_PHRASES}`}>По поисковым фразам</Link>}
        </h1>

        <div className="text-gray-400 mt-1.5 mb-8 max-w-[800px]">
          {by === BY_PRODUCTS && (
            <>
              Все запросы, по которым встретили переданные SKU в поисковой выдачи за последние 30
              дней.
            </>
          )}

          {by === BY_PHRASES && (
            <>
              Все SKU, которые ранжировались по переданным запросам за последние 7 дней с позицией в
              выдаче не ниже 30, затем мы найдем запросы, по которым встретили найденные SKU в
              поисковой выдаче за последние 30 дней.
            </>
          )}
        </div>

        <NavList classList="mb-4" items={getTabs()} />
      </div>

      {tab === TAB_FIRST && (
        <Table
          data={data?.table_data || data?.table_1 || []}
          // pagination={true}
          totalPages={data?.pagination?.total_pages}
          isLoading={isLoading || isFetching}
          type={TABLE_TYPE[TAB_FIRST]}
          autoSizeColumns={[COLUMNS.PHRASE_NAME]}
          hideFilterPanel={true}
          noRowsText={'Данные отсутствуют'}
          paginationData={{
            page,
            setPage,
          }}
          // footerExtra={<FilterExtra />}
        />
      )}

      {/*{tab === TAB_SECOND && (*/}
      {/*  <Table*/}
      {/*    data={data?.table_2 || []}*/}
      {/*    pagination={true}*/}
      {/*    totalPages={Math.ceil(data?.table_2.length / PER_PAGE)}*/}
      {/*    isLoading={isLoading || isFetching}*/}
      {/*    type={TABLE_TYPE[TAB_SECOND]}*/}
      {/*    typeNested={TABLE_TYPE[`${TAB_SECOND}${NESTED}`]}*/}
      {/*    nested={COLUMNS.SIMILAR_PHRASES}*/}
      {/*    autoSizeColumns={[COLUMNS.WORD]}*/}
      {/*    hideFilterPanel={true}*/}
      {/*    autoSizeNestedColumns={[COLUMNS.NAME]}*/}
      {/*    noRowsText={'Данные отсутствуют'}*/}
      {/*    nestedCheckbox={true}*/}
      {/*    nestedParentColumn={COLUMNS.WORD}*/}
      {/*    footerExtra={<FilterExtra />}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

export default Search;
