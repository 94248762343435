import TableHeaderDateControls from 'components/TableHeaderDateControls';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useEffect } from 'react';
import SearchContent from './SearchContent';

const SearchPhrases = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const { resetFilter } = useFilterReducer();
  const filter = searchParams.get('filter');
  useEffect(() => {
    if (!filter) {
      resetFilter();
    }
  }, [filter, resetFilter]);
  return (
    <div className="pt-4">
      <div className="px-8 flex justify-between">
        <div>
          <h1>Поисковые запросы</h1>
          <div className="text-gray-400 mt-1 mb-4">
            <span className="block">
              Инструмент позволяет посмотреть топ поисковых фраз по разным параметрам с учётом
              периода.
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-2 h-fit">
          <div className="w-[1px] h-6 bg-[#E7EDF0]" />
          <TableHeaderDateControls usePeriods={false} />
        </div>
      </div>
      <SearchContent />
    </div>
  );
};

export default SearchPhrases;
