import ButtonPrimary from 'components/ButtonPrimary';
import { useAuth } from 'context/auth.context';
import { setToken } from 'context/auth.methods';

const SignUpCompleted = () => {
  const { tmpToken } = useAuth();

  const handleOpenMailApp = () => {
    window.location.href = 'mailto:';
  };

  const skipEmailValidation = () => {
    setToken({ access_token: tmpToken });
    window.location.pathname = '/';
  };

  return (
    <div>
      <center>
        Для завершения регистрации, пожалуйста, перейдите по ссылке, которую мы отправили вам на
        e-mail.
      </center>
      <center className="space-y-4 mt-6">
        <ButtonPrimary onClick={handleOpenMailApp} label="Перейти в почту" />
        <button className="text-green hover:underline" onClick={skipEmailValidation}>
          Пропустить шаг
        </button>
      </center>
    </div>
  );
};

export default SignUpCompleted;
